import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import {
  Delete,
  Sync,
  ToggleOffOutlined,
  ToggleOnOutlined,
  UpdateOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";
import singleAccountUtils from "./utils/singleAccountUtils";
import sessionStorage from "Utils/sessionStorage";
import { useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";

const SingleAccountMenu = ({
  setWarnDelete,
  account,
  active,
  setActive,
  onUpdate,
  apiKeyOnly,
  subAccount,
}) => {
  const entityId = useSelector((state) => state.entity?.selectedEntity?.id);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleOAuthRefresh = async () => {
    try {
      let url;
      let redirectUrl;
      switch (account.platform) {
        case "amazon":
          redirectUrl = "";
          url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${process.env.REACT_APP_AMAZON_CLIENT_ID}&version=beta`;
          break;
        case "shopify":
          url = "https://apps.shopify.com/vendanza";
          break;
        case "facebook":
          url = "/api/third-party/facebook/refresh";
          break;
        default:
          throw new Error("Platform not supported");
      }
      sessionStorage.setOAuthRefreshData({
        platform: account.platform,
        entityId,
        accountId: account.accountId,
        tokenId: account.tokenId,
      });
      window.location.href = url;
    } catch (err) {
      dispatch(setMessage(err.message));
    }
  };
  const removeNoteFromThirdPartyAccount = async () => {
    try {
      await thirdPartyAccountService.removeNoteFromThirdPartyAccount({
        accountId: account.accountId,
        entityId,
        platform: account.platform,
        customField: account.custom,
      });
    } catch (err) {
      dispatch(setMessage(err.message));
    }
  };

  const triggerHistoricalSync = async () => {
    try {
      await removeNoteFromThirdPartyAccount();
      await thirdPartyAccountService.triggerHistoricalSync({
        accountId: account.accountId,
        entityId,
        platform: account.platform,
        tokenId: account?.tokenId,
      });
      dispatch(setMessage("Historical data synchronization started."));
    } catch (err) {
      dispatch(setMessage(err.message));
    }
  };
  const menuItems = [
    {
      text: "Synchronize Historical Data",
      icon: <Sync />,
      onClick: () =>
        triggerHistoricalSync({
          accountId: account.accountId,
          entityId,
          platform: account.platform,
        }),
    },
    {
      text: active ? "Disable Account" : "Enable Account",
      icon: active ? <ToggleOnOutlined /> : <ToggleOffOutlined />,
      onClick: () =>
        singleAccountUtils.updateAccountActive({
          e: { target: { checked: !active } },
          setLoading,
          setActive,
          setMessage,
          dispatch,
          account,
          entityId,
          onUpdate,
        }),
    },
    {
      text: "Refresh Credentials",
      icon: <UpdateOutlined />,
      onClick: () => {
        handleOAuthRefresh();
      },
      hidden: apiKeyOnly || subAccount,
    },
    {
      text: "Delete Account",
      icon: <Delete />,
      onClick: () => setWarnDelete(true),
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map(
          (item, index) =>
            !item.hidden && (
              <MenuItem key={index} onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};

export default SingleAccountMenu;
