import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getChatsByEntityId = async (entityId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/ai/conversations?entityId=${entityId}`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const sendChat = async ({ message, chatId, entityId }: any) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/ai/chat/${chatId}`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      message,
      entityId,
      chatId,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const getMessagesByChatId = async (chatId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/ai/messages?chatId=${chatId}`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const renameChat = async (chatId: number, name: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/ai/rename`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      chatId,
      name,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const deleteChatById = async (chatId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/ai/chat/${chatId}`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.delete(uri, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const createChat = async (entityId: number, name: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/ai/chat`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      entityId,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

export default {
  getChatsByEntityId,
  getMessagesByChatId,
  sendChat,
  renameChat,
  deleteChatById,
  createChat,
};
