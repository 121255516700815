import { Close } from "@mui/icons-material";
import { Alert, Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import errorService from "services/errorService.ts";
import { getErrorsByEntityId } from "store/actions/errorActions.ts";
import { setCurrentTab } from "store/actions/navigationActions.ts";
import errorUtils from "views/Integrations/components/googleAnalytics/utils/errorUtils";

const Errors = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const backendErrors = useSelector((state) => state?.errors?.errors);
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);

  const toErrorResolution = (route) => {
    dispatch(setCurrentTab(route));
    history.push(`/entity${route}&entityId=${entityId}`);
  };

  const mapErrorToResolution = (errCode) => {
    return errorUtils.mapErrorCode(errCode);
  };

  const deleteErrorById = async (id) => {
    try {
      await errorService.deleteErrorById(id);
      
    } catch (err) {
      console.error(err);
    } finally {
        dispatch(getErrorsByEntityId(entityId))
    }
  };

  return (
    <>
      {backendErrors?.map((err) => {
        return (
          <Alert
            severity="error"
            sx={{ display: "flex", alignItems: "center", mb: 3 }}
            action={
              <IconButton onClick={() => deleteErrorById(err.id)}>
                <Close />
              </IconButton>
            }
          >
            <Box display="flex" alignItems="center">
              <Box>
                <Typography>{err.description}</Typography>
                <Typography variant="subtitle2">
                  {mapErrorToResolution(err.code)}
                </Typography>
              </Box>
              {err.link && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => toErrorResolution(err.link)}
                >
                  Go to issue
                </Button>
              )}
            </Box>
          </Alert>
        );
      })}
    </>
  );
};

export default Errors;
