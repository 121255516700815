import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import subscriptionUtils from "Utils/subscriptionUtils";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const DowngradeModal = ({ open, setOpen, plan }) => {
  const [confirm, setConfirm] = useState("");
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          Downgrade to a {plan.planName} Plan?
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ListItem variant="body1">
          Your project will be limited to {plan.orderLimit} orders per month,
          which may not fit the current rate of orders / month for your
          business.
        </ListItem>
        {plan.id === "free" && (
          <ListItem variant="body1">
            Because of the Starter Tier integration limitations, existing
            integrations will be removed and current project data will be
            deleted. You will need to re-add integrations and allow data to
            synchronize again.
          </ListItem>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <TextField
          onChange={(e) => setConfirm(e.target.value)}
          fullWidth
          size="small"
          placeholder="type 'confirm' to confirm"
          sx={{ mr: 2 }}
        />
        <Button
          disabled={confirm !== "confirm"}
          variant="outlined"
          onClick={() =>
            subscriptionUtils.handleDowngrade({
              dispatch,
              entity,
              user,
              setOpen,
              plan,
            })
          }
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DowngradeModal;
