import { Toolbar, Box, IconButton, AppBar, Drawer, Alert } from "@mui/material";
import { VerticalSplitOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import DrawerContents from "../Navigation/DrawerContents";
import { useDispatch, useSelector } from "react-redux";
import { getErrorsByEntityId } from "store/actions/errorActions.ts";
import DrawerContentsNew from "Components/Navigation/DrawerContentsNew";

const Header = (props) => {
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { window, drawerWidth } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (entityId) {
      dispatch(getErrorsByEntityId(entityId));
    }
  }, [entityId]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          background: { xs: "primary", md: "none" },
        }}
        elevation={0}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ mr: 2, display: { xs: "none", md: "flex" } }}></Box>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <VerticalSplitOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              transition: "width 0.3s ease-in-out",
            },
          }}
        >
          <DrawerContentsNew />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              transition: "width 0.09s ease-in-out",
            },
          }}
          open
        >
          <DrawerContentsNew />
        </Drawer>
      </Box>
    </>
  );
};

export default Header;
