import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import sessionStorage from "Utils/sessionStorage";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { setSelectedEntity } from "store/actions/entityActions.ts";
import {
  setDisableClose,
  setShowUpgradeModal,
} from "store/actions/marketingActions.ts";
import { setCurrentTab } from "store/actions/navigationActions.ts";

const UpgradeModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const marketingController = useSelector((state) => state?.marketing);
  const handleViewPlans = () => {
    history.push(`/entity/analytics/project-settings`);
    dispatch(setCurrentTab("project-settings"));
    dispatch(setShowUpgradeModal(false));
    setTimeout(() => {
      dispatch(setDisableClose(false));
    }, 200);
  };
  const handleClose = () => {
    dispatch(setShowUpgradeModal(false));
  };

  const handleGoToProjects = () => {
    dispatch(setCurrentTab("dashboard"));
    history.push(`/dashboard`);
    sessionStorage.setSelectedEntity(-1);
    dispatch(setSelectedEntity(-1));
    dispatch(setShowUpgradeModal(false));
    setTimeout(() => {
      dispatch(setDisableClose(false));
    }, 200);
  
  }
  useEffect(() => {}, [marketingController]);
  return (
    <Dialog fullWidth open={marketingController?.showUpgradeModal}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{marketingController?.modalTitle}</Typography>
        {!marketingController.disableClose && (
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {marketingController?.modalSubtitle}
        </Typography>
        <Typography variant="body2">
          Upgrade this project to get access to more features.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleViewPlans} variant="contained">
          Go to Project Settings
        </Button>
        <Button onClick={handleGoToProjects} variant="outlined">
          Go to Projects
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeModal;
