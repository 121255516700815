import {
  Box,
  Container,
} from "@mui/material";
import HeroSection from "./components/HeroSection/HeroSection";
import { useEffect } from "react";
import LandingHeader from "../components/LandingHeader/LandingHeader";
import Walkthrough from "./components/WalkThroughSteps/Walkthrough";
import MissionStatement from "./components/MissionStatement";
import FinalCTA from "./components/FinalCTA/FinalCTA";
import Pricing from "../Pricing/Pricing";

const Landing = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <Container>
      <LandingHeader/>
        <HeroSection />
        <Walkthrough/>
        <Box my={6}>

        <MissionStatement showMore={true}/>
        </Box>
        <Box sx={{my:20}}>
        <Pricing frontPage={true}/>
        </Box>
        <FinalCTA/>
    </Container>
  );
};

export default Landing;
