import ApiKeyAccount from "Components/Integrations/APIKeyAccount/APIKeyAccount";
import squarespaceLogo from "assets/SquareSpace.png";

const Action = ({ accounts, setAccounts, onUpdate }) => {
  return (
    <ApiKeyAccount
      accounts={accounts}
      setAccounts={setAccounts}
      onUpdate={onUpdate}
      logo={squarespaceLogo}
      title={"Squarespace"}
      platform={"squarespace"}
    />
  );
};

export default Action;
