import { Box } from "@mui/material";
import React from "react";
import TopText from "./TopText";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";

const Walkthrough = () => {
  return (
    <Box sx={{mb:20}}>
    <TopText/>
    <StepOne/>
    <StepTwo/>
    <StepThree/>
    {/* <StepFour/> */}
    </Box>
  );
};

export default Walkthrough;
