import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  List,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AccountGroupName from "./AccountGroupName";
import oAuthService from "services/oAuthService.ts";
import DeleteAccount from "Components/Integrations/DeleteAccount/DeleteAccount";
import SingleAccount from "Components/Integrations/SingleAccount/SingleAccount";
import facebook from "Api/integration/facebook.ts";
import { useDispatch } from "react-redux";
import GroupedAccountMenu from "./components/GroupedAccountMenu";
import AccountGroupItem from "../IntegrationAccount/AccountGroupItem";
import AvailableAccount from "./components/AvailableAccount";

const AccountGroup = ({ integration, idx, onUpdate, onDelete }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableAccounts, setAvailableAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountGroupMessage, setAccountGroupMessage] = useState("");
  const handleDeleteIntegration = async () => {
    try {
      setShowDelete(false);
      setDeleting(true);
      const result = await oAuthService.deleteMultipleAccountsAndData(
        integration.tokenId
      );
      if (result.data?.success) {
        await onDelete();
      }
    } catch (err) {}
  };

  const filterAvailableAgainstStoredAccounts = (integrations) => {
    const filteredAgainstStored = integrations?.data?.accounts?.filter(
      (acct) => {
        return !selectedAccounts.find(
          (selectedAcct) => selectedAcct?.dataValues?.accountId === acct.id
        );
      }
    );
    return filteredAgainstStored ?? [];
  };

  const getAvailableFacebookAccounts = async () => {
    try {
      setLoading(true);
      const integrations = await facebook.getAdAccounts(integration?.tokenId);
      const filteredAgainstStored = filterAvailableAgainstStoredAccounts(integrations);
      setAvailableAccounts(filteredAgainstStored ?? []);
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.code === "OAUTH_01") {
        setAccountGroupMessage(
          "Unable to retrieve available Ad accounts becauses credentials for this account have expired. Please refresh your credentials."
        );
      } else if (err?.response?.data?.code === "FACEBOOK_02") {
        setAccountGroupMessage(
          "Unable to retrieve available Ad accounts because this Facebook account is receiving too many requests at once and is being throttled. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const getStoredAccounts = async () => {
    try {
      setLoading(true);
      const storedAccounts = await facebook.getStoredAdAccountsByTokenId(
        integration?.tokenId
      );
      setSelectedAccounts(storedAccounts ?? []);
      setLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAvailableFacebookAccounts();
    getStoredAccounts();
  }, [integration]);

  useEffect(() => {
    getAvailableFacebookAccounts();
  }, [selectedAccounts]);
  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <AccountGroupName
            disabled={deleting}
            integration={integration}
            idx={idx}
          />
          <GroupedAccountMenu
            setWarnDelete={setShowDelete}
            account={integration}
            onUpdate={onUpdate}
          />
        </Box>
        {deleting && (
          <Box display="flex" alignItems="center">
            <CircularProgress
              variant="indeterminate"
              size={12}
              sx={{ mr: 1 }}
            />
            <Typography variant="subtitle2">
              Disconnecting Facebook Ad Integration...
            </Typography>
          </Box>
        )}
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Selected Ad Accounts
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <List disablePadding sx={{ mt: 2 }}>
          {selectedAccounts?.map((acct) => {
            return (
              <SingleAccount
                subAccount
                primaryText={acct?.dataValues?.accountId}
                account={acct?.dataValues}
                onDeleteSuccess={getStoredAccounts}
                onDeleteFail={getStoredAccounts}
              />
            );
          })}
        </List>

        <Typography variant="subtitle2" sx={{ mt: 6 }}>
          Available Ad Accounts
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <List disablePadding sx={{ mt: 2 }}>
          {availableAccounts?.map((acct) => {
            return (
              <AvailableAccount
                accountId={acct.id}
                onUpdate={getStoredAccounts}
                tokenId={integration?.tokenId}
                platform={integration?.platform}
              />
            );
          })}
        </List>
        {accountGroupMessage && (
          <Alert severity="error">
            <Typography variant="subtitle2">{accountGroupMessage}</Typography>
          </Alert>
        )}
      </CardContent>
      <DeleteAccount
        plural
        warnDelete={showDelete}
        setWarnDelete={setShowDelete}
        loading={deleting}
        onDelete={handleDeleteIntegration}
      />
    </Card>
  );
};

export default AccountGroup;
