import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SingleAccountMenu from "./components/SingleAccountMenu";
import DeleteDialog from "./components/DeleteDialog";
import singleAccountUtils from "./components/utils/singleAccountUtils";
import { useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";

const SingleAccount = ({
  account,
  onDeleteSuccess,
  onDeleteFail,
  primaryText,
  isAPIKeyOnly,
  onUpdate,
  subAccount,
}) => {
  const entityId = useSelector((state) => state.entity?.selectedEntity?.id);
  const [warnDelete, setWarnDelete] = useState(false);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAccountId, setShowAccountId] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setActive(account.active);
  }, [account]);

  const parseNote = () => {
    const custom = account.custom ? JSON.parse(account.custom) : {};
    if (custom?.note) {
      return custom.note;
    }
    return null;
  };
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 1,
        mb: 1,
        width: "100%",
      }}
      key={account.id}
      elevation={subAccount? 0 : 1}
    >
      <Box
        sx={{
          display: "flex",
          p: 1,
          mb: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="left">
          <Typography variant="p">{primaryText}</Typography>
          <Box display="flex" alignItems="center">
            {showAccountId && (
              <Typography variant="caption" color="textSecondary">
                {account.accountId}
              </Typography>
            )}
            <Button
              sx={{ p: 0, m: 0 }}
              onClick={() => setShowAccountId(!showAccountId)}
            >
              <Typography fontSize={12}>
                {!showAccountId ? `View account ID` : `Hide`}
              </Typography>
            </Button>
          </Box>
          <Typography variant="caption" color="error">{parseNote()}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <FormGroup sx={{ display: { xs: "none", md: "block" } }}>
            <FormControlLabel
              control={
                <Switch disabled={loading} size="small" checked={active} />
              }
              label={active ? "Connected" : "Disabled"}
              onChange={(e) =>
                singleAccountUtils.updateAccountActive({
                  e,
                  setLoading,
                  dispatch,
                  setActive,
                  setMessage,
                  account,
                  entityId,
                  onUpdate,
                })
              }
            />
          </FormGroup>
          <SingleAccountMenu
            subAccount={subAccount}
            setWarnDelete={setWarnDelete}
            account={account}
            setMessage={setMessage}
            setActive={setActive}
            active={active}
            onUpdate={onUpdate}
            apiKeyOnly={isAPIKeyOnly}
          />
        </Box>
      </Box>
      <DeleteDialog
        setWarnDelete={setWarnDelete}
        warnDelete={warnDelete}
        account={account}
        onDeleteSuccess={onDeleteSuccess}
        onDeleteFail={onDeleteFail}
        isAPIKeyOnly={isAPIKeyOnly}
      />
    </Paper>
  );
};

export default SingleAccount;
