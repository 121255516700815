const initialState = {
  adCampaignData: [],
  adSetData: [],
  adsData: [],
  performanceThresholds: {},
  notifications: [],
};

const advertisingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_AD_CAMPAIGN_DATA":
      return {
        ...state,
        adCampaignData: action.payload,
      };
    case "SET_ADSET_DATA":
      return {
        ...state,
        adSetData: action.payload,
      };

    case "SET_ADS_DATA":
      return {
        ...state,
        adsData: action.payload,
      };
    case "SET_PERFORMANCE_THRESHOLDS":
      return {
        ...state,
        performanceThresholds: action.payload,
      };
    default:
      return state;
  }
};

export default advertisingReducer;
