import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import oAuthUtils from "../utils/oAuthUtils";

const InactiveToken = ({ tokenId }) => {
    const [error, setError] = useState(null);
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
    >
      <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
        Our bad, we're having trouble with this account. Please refresh the
        credentials to resolve this issue
      </Typography>
      <Button
        sx={{ mb: 1 }}
        variant="outlined"
        size="small"
        onClick={() => oAuthUtils.handleTokenRefresh({ tokenId, setError })}
      >
        Refresh Credentials
      </Button>
    </Box>
  );
};

export default InactiveToken;
