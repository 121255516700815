import { MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import chatService from "services/chatService.ts";
import { getChatHistoryByEntityId, setCurrentChat, setMessages } from "store/actions/chatActions.ts";
import { setMessage } from "store/actions/messageActions.ts";
import ChatHistoryItemMenu from "./ChatHistoryItemMenu/ChatHistoryItemMenu";

const ChatHistoryItem = ({ details }) => {
  const currentChat = useSelector((state) => state?.chat?.currentChat);
  const dispatch = useDispatch();

  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(details?.name || "");

  const handleSelect = async () => {
    try {
      dispatch(setCurrentChat(details));
      const result = await chatService.getMessagesByChatId(
        details.conversationId
      );
      dispatch(setMessages(result.data));
    } catch (err) {
      dispatch(setMessage(err.message));
    }
  };

  const handleRename = async (name) => {
    try {
      await chatService.renameChat(details.conversationId, name);
      dispatch(getChatHistoryByEntityId(details.entityId));
    } catch (err) {
      dispatch(setMessage(`Failed to rename chat: ${err.message}`));
    }
  };

  const handleRenameSubmit = (e) => {
    if (e.key === "Enter") {
      handleRename(newName);
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };
  if (isEditing) {
    return (
      <TextField
      placeholder="Name"
        sx={{ mb: 0.5 }}
        size="small"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        onKeyDown={handleRenameSubmit}
        onBlur={handleBlur}
        autoFocus
        variant="outlined"
      />
    );
  }
  return (
    <MenuItem
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 2,
        borderRadius: 2,
        mb: 1,
      }}
      disableGutters
      selected={currentChat.conversationId === details.conversationId}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleSelect}
    >
      {details?.name}
      {(isHovered || currentChat.conversationId === details.conversationId) && (
        <ChatHistoryItemMenu
          details={details}
          onEdit={() => handleEditClick()}
        />
      )}
    </MenuItem>
  );
};

export default ChatHistoryItem;
