import { Grid, Typography } from "@mui/material";
import React from "react";
import AddMetricBtn from "./BoardMenu/AddMetricBtn";

const NoMetrics = ({ details, currentMetrics, onAddMetric }) => {
  return (
    <Grid
      height="50vh"
      container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item mb={2}>
        <Typography textAlign="center" variant="h6" color="primary">
          No metrics assigned.
        </Typography>
        <Typography textAlign="center" variant="subtitle2">
          Pick a few metrics to start visualizing your data
        </Typography>
      </Grid>
      <Grid item>
        <AddMetricBtn
            button
          details={details}
          currentMetrics={currentMetrics}
          onUpdate={onAddMetric}
        />
      </Grid>
    </Grid>
  );
};

export default NoMetrics;
