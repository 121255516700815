import { Card, Slide } from "@mui/material";
import "../../App.css";
import NavFooter from "./Components/NavFooter/NavFooter";
import EntityNavigation from "./Components/EntityNavigation/EntityNavigation";
import AccountNavigation from "./Components/AccountNavigation/AccountNavigation";
import { useSelector } from "react-redux";
import MetricsNavigation from "./Components/MetricsNavigation/MetricsNavigation";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkMonthOrderCount } from "store/actions/marketingActions.ts";
import ChatNavigation from "./Components/ChatNavigation/ChatNavigation";

const DrawerContentsNew = () => {
    const currentEntity = useSelector((state) => state.entity?.selectedEntity);
    const currentTab = useSelector((state) => state?.navigation?.currentTab);
    const dispatch = useDispatch();
    const getNavigationContents = () => {
        if (!currentEntity?.id) {
            return <AccountNavigation />;
        }
        else if (currentEntity?.id && currentTab === "graph") {
            return <MetricsNavigation />;
        }
        else if (currentEntity?.id && currentTab === "chat") {
            return <ChatNavigation />;
        }
        return <EntityNavigation />;
    }

    const getFooterNav = () => {
        if (currentTab === 'graph' || currentTab === 'chat') {
            return null;
        }
        return <NavFooter />;
    }

    useEffect(() => {
      if (!window.location.href.includes('pricing') && !window.location.href.includes('project-settings') && currentEntity) {
      dispatch(checkMonthOrderCount(currentEntity))
      }

    }, [window.location.href, currentEntity])
  return (
    <Card
      className="drawer-menu"
      sx={{
        p: 1,
        pt: 0,
        backgroundColor: "lightGrayBG",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "scroll",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        height: "100vh",
      }}
    >
      {getNavigationContents()}
      {getFooterNav()}
    </Card>
  );
};

export default DrawerContentsNew;
