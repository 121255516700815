import { Divider, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Subscription from "./components/Subscription";
import DeleteBusiness from "Components/BusinessCard/components/BusinessMenu/components/DeleteBusiness";
import { useSelector } from "react-redux";

const ProjectSettings = () => {
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  
  return (
    <Container disableGutters>
      <Typography variant="h4" sx={{mt:5}}>Project Settings</Typography>
      <Divider fullWidth sx={{ mt: 2 }} />
      <Subscription />
      <Divider fullWidth sx={{ mt: 2, mb: 4 }} />
      <DeleteBusiness btn details={entity} />
    </Container>
  );
};

export default ProjectSettings;
