import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import PricingOption from "views/Pricing/Components/PricingOption";
import pricingOptions from "views/Pricing/Components/pricing";

const Pricing = ({frontPage}) => {

    useEffect(()=> {
        window.scrollTo(0,0)
    }, [])
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 16 }}
    >
      <Typography
        fontWeight={700}
        sx={{ fontSize: { xs: "48px", sm: "56px" }, lineHeight: 1.1 }}
        textAlign={{ xs: "left" }}
      >
        Pricing.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5 }}
        fontWeight={700}
        textAlign="center"
        
        mt={1}
        mb={3}
      >
       Whether you're a budding unicorn or an e-commerce giant, we have a plan that can fit your needs.
      </Typography>
      <Grid
        container
        columns={16}
        mt={6}
        display="flex"
        justifyContent="center"
      >
        {pricingOptions.map((option) => {
          return (
            <Grid m={1} mb={3} item xs={16} sm={8} lg={5}>
              <PricingOption
                planDetails={option}
                key={option.planName}
                planName={option.planName}
                planDescription={option.planDescription}
                price={option.price}
                inclusions={option.inclusions}
                link={frontPage ? "/register" : option.link}
                frontPage={frontPage}
              />
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="subtitle2"  textAlign="center">
      Vendanza partners with Stripe and Shopify Billing to handle payments and subscriptions. We do not store
          any payment information. When converting to a paid plan, depending on your project, you will be redirected to Stripe or Shopify to
          setup the subscription.
      </Typography>
    </Box>
  );
};

export default Pricing;
