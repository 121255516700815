import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Card, ListItemIcon, Slide } from "@mui/material";
import { useHistory } from "react-router-dom";
import sessionStorage from "Utils/sessionStorage";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEntity } from "store/actions/entityActions.ts";
import { useEffect, useState } from "react";
import {
  DashboardCustomize,
  DashboardCustomizeOutlined,
  Settings,
  SettingsOutlined,
  Support,
  SupportOutlined,
} from "@mui/icons-material";
import {
  setAccountNavigationVisiblity,
  setCurrentTab,
} from "store/actions/navigationActions.ts";

import logo from "assets/vendanza/brand/logo-symbol-light.svg";
const AccountNavigation = ({ onClose }) => {
  const dispatch = useDispatch();
  const isVisible = useSelector(
    (state) => state?.navigation?.accountNavIsVisible
  );
  const currentTab = useSelector((state) => state?.navigation?.currentTab);

  const macroTools = [
    {
      route: "dashboard",
      name: "Projects",
      moduleId: 0,
      unfilledIcon: <DashboardCustomizeOutlined />,
      filledIcon: <DashboardCustomize color="primary" />,
    },
    {
      route:
        "https://join.slack.com/t/vendanza-workspace/shared_invite/zt-2muqfk0nq-jMMxMrbE9NhlNfmysti9Yg",
      name: `Community & Support`,
      moduleId: 2,
      unfilledIcon: <SupportOutlined />,
      filledIcon: <Support color="primary" />,
    },
    {
      route: "settings",
      name: "Settings",
      moduleId: 1,
      unfilledIcon: <SettingsOutlined />,
      filledIcon: <Settings color="primary" />,
    },
  ];

  const history = useHistory();

  const toMacroTool = (route) => {
    if (
      route ===
      "https://join.slack.com/t/vendanza-workspace/shared_invite/zt-2muqfk0nq-jMMxMrbE9NhlNfmysti9Yg"
    ) {
      window.location.href = route;
      return;
    }
    dispatch(setCurrentTab(route));
    history.push(route);
  };

  const loadCurrentTab = () => {
    if (window.location.href.includes("settings")) {
      dispatch(setCurrentTab("settings"));
    } else if (window.location.href.includes("dashboard")) {
      dispatch(setCurrentTab("dashboard"));
    }
  };

  useEffect(() => {
    dispatch(setAccountNavigationVisiblity(true));

    loadCurrentTab();
  }, []);

  return (
    <Slide in={isVisible} direction="right">
      <Box>
        <List
          sx={{
            overflow: "scroll",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box width="100%" display="flex" justifyContent="center" p={3}>
            <Box
              component="img"
              display="flex"
              alignItems="center"
              justifyContent="center"
              src={logo}
              height="50px"
              width="50px"
            />
          </Box>
          <List>
            {macroTools?.map((tool) => {
              return (
                <ListItemButton
                  sx={{ borderRadius: "10px", mb: 1 }}
                  key={tool.moduleId}
                  selected={currentTab === tool.route}
                  onClick={() => toMacroTool(tool.route)}
                >
                  <ListItemIcon>
                    {currentTab === tool.route
                      ? tool.filledIcon
                      : tool.unfilledIcon}
                  </ListItemIcon>
                  <ListItemText primary={tool.name} />
                </ListItemButton>
              );
            })}
          </List>
        </List>
      </Box>
    </Slide>
  );
};

export default AccountNavigation;
