import { EastRounded } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { styled } from "@mui/system";
import facebookIntegrationImg from "assets/vendanza/walkthrough/facebookIntegration.svg";
import googleAnalyticsIntegrationImg from "assets/vendanza/walkthrough/GA4Integration.svg";
import squarespaceIntegration from "assets/vendanza/walkthrough/squarespaceIntegration.svg";
import HoverBox from "Components/HoverBox/HoverBox";

const StepOne = () => {
  const history = useHistory();

  const ImageContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(10), // Default margin
    [theme.breakpoints.up("lg")]: {
      marginTop: 0, // Margin for large screens and up
    },
  }));

  const ImageBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    transition: "transform 0.3s ease-in-out", // Optional: adds a smooth transition effect
    "&:nth-of-type(1)": {
      left: -250,
      top: 0,
    },
    "&:nth-of-type(2)": {
      left: "-60px", // Adjust for desired overlap
      top: "-50px", // Adjust for desired staggering
    },
    "&:nth-of-type(3)": {
      left: "30px", // Adjust for desired overlap
      top: "20px", // Adjust for desired staggering
    },
  }));

  return (
    <Grid container display="flex" justifyContent="center" sx={{ mt: 5 }}>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              
              mb={0}
              p={0}
            >
              Step One:
            </Typography>
            <Typography
              sx={{
                fontSize: "48px",
                fontWeight: 600,
                mt: 0,
                pt: 0,
                lineHeight: 1,
              }}
            >
              Integrate.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ mb: 2, fontSize: "20px", fontWeight: 600 }}
              
            >
              No more manually compiling your data in a spreadsheet.
            </Typography>
            <Typography
              sx={{ mb: 2, fontSize: "20px", fontWeight: 600 }}
              
            >
              We’ve built integrations with major e-commerce service providers
              like Shopify, Facebook, Amazon, Google Analytics, and many, many
              more to automatically pull your e-commerce data into one amazing
              place.
            </Typography>
            <HoverBox
              onClick={() => history.push("/integrations")}
              display="flex"
              alignItems="center"
            >
              <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                Platforms we integrate with
              </Typography>
              <EastRounded sx={{ ml: 1 }} />
            </HoverBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        justifyContent="center"
        alignItems="center"
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <ImageContainer>
          <ImageBox component="img" src={facebookIntegrationImg} sx={{ width: "200px" }} />
          <ImageBox component="img" src={googleAnalyticsIntegrationImg} sx={{ width: "200px" }} />
          <ImageBox component="img" src={squarespaceIntegration} sx={{ width: { xs: "140px", sm: "200px" } }} />
        </ImageContainer>
      </Grid>
    </Grid>
  );
};

export default StepOne;
