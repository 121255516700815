import ShopConfigure from "./ShopConfigure";
import ShopSelect from "./ShopSelect";

const Action = ({ accounts }) => {
  if (accounts.length) {
    return <ShopConfigure />;
  }
  return <ShopSelect />;
};

export default Action;
