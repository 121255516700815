import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const createBoard = async (
  entityId: string,
  name: string,
  description: string,
  boardType: string,
) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      entityId,
      name,
      description,
      boardType,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getBoardsForEntity = async (entityId: number, view: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board?entityId=${entityId}&view=${view}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteBoard = async (boardId: number) => {
    try {
      const accessToken = getAuthToken();
      const uri = `${process.env.REACT_APP_VENDANZA_API}/board?boardId=${boardId}`;
      const config = {
        headers: {
          role: "agent",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      const result = await axios.delete(uri, config);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateBoardMetrics = async ( boardId: number, metrics: any[]) => {
    try {
        const accessToken = getAuthToken();
        const uri = `${process.env.REACT_APP_VENDANZA_API}/board/metrics`;
        const config = {
          headers: {
            role: "agent",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const body = {
            boardId, 
            metrics,
        }
    
        const result = await axios.post(uri, body, config);
        return result;
      } catch (error) {
        console.error(error);
        throw error;
      }
  }

  const getMetricsByBoardId = async (boardId: number) => {
    try {
      const accessToken = getAuthToken();
      const uri = `${process.env.REACT_APP_VENDANZA_API}/board/metrics?boardId=${boardId}`;
      const config = {
        headers: {
          role: "agent",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      const result = await axios.get(uri, config);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateBoard = async (boardId: number, name: string, description: string) => {
    try {
      const accessToken = getAuthToken();
      const uri = `${process.env.REACT_APP_VENDANZA_API}/board?boardId=${boardId}`;
      const config = {
        headers: {
          role: "agent",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      const body = {
        name,
        description,
      };
      const result = await axios.patch(uri, body, config);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const sendChat = async (chat: string, data: any, boardId: number) => {
    try {
      const accessToken = getAuthToken();
      const uri = `${process.env.REACT_APP_VENDANZA_API}/ai/chat?boardId=${boardId}`;
      const config = {
        headers: {
          role: "agent",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      const body = {
        chat,
        data
      };
      const result = await axios.post(uri, body, config);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const getChatHistoryByBoardId = async (boardId: number) => {
    try {
      const accessToken = getAuthToken();
      const uri = `${process.env.REACT_APP_VENDANZA_API}/ai/chat?boardId=${boardId}`;
      const config = {
        headers: {
          role: "agent",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  

      const result = await axios.get(uri, config);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

export default {
  createBoard,
  getBoardsForEntity,
  deleteBoard,
  updateBoardMetrics,
  getMetricsByBoardId,
  updateBoard,
  getChatHistoryByBoardId,
  sendChat,
};
