import datesService from "Api/dates/datesService.ts";
import metricsService from "Api/metrics/metricsService.ts";
import { setLoadingData } from "./loadingActions.ts";
import { setMessage } from "store/actions/messageActions.ts";

export const setBaseMetrics = (metrics: any) => ({
  type: "SET_BASE_METRICS",
  payload: metrics,
});

export const setPrimaryDates = ({ startDate, endDate }) => ({
  type: "SET_PRIMARY_DATES",
  payload: { startDate, endDate },
});

export const setSecondaryDates = ({ startDate, endDate }) => ({
  type: "SET_SECONDARY_DATES",
  payload: { startDate, endDate },
});

export const setMostRecentPullDate = (date: string) => ({
  type: "SET_MOST_RECENT_PULL_DATE",
  payload: date,
});

export const setShowComparison = (showComparison: boolean) => ({
  type: "SET_SHOW_COMPARISON",
  payload: showComparison,
});

export const setMetricGoals = (goals: any) => ({
  type: "SET_METRIC_GOALS",
  payload: goals,
});

export const getBaseMetrics = (entityId: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingData(true));
    const metricsResult = await metricsService.getBaseMetrics(entityId);
    dispatch(setBaseMetrics(metricsResult.data));
    dispatch(setMostRecentPullDate(new Date().toLocaleString()));
  } catch (error) {
    console.error("error getting base metrics: ", error);
    throw error;
  } finally {
    dispatch(setLoadingData(false));
  }
};

const createDateFromString = (dateString: any) => {
  // Split the string by the dash character
  const [year, month, day] = dateString.split("-").map(Number);

  // Create a new Date object
  // Month is zero-based, so we subtract 1 from the month
  const date = new Date(year, month - 1, day);

  return date;
};

export const getDates = (entityId: number) => async (dispatch: any) => {
  try {
    const datesResult = await datesService.getDates(entityId);
    if (datesResult && datesResult.data && datesResult.data.dataValues) {
      const values = datesResult.data.dataValues;
      const date = new Date(2021, 7, 12); // Month is zero-based, so 7 is August
      const primaryDates = {
        startDate: createDateFromString(values.primaryStartDate),
        endDate: new Date(values.primaryEndDate),
      };
      const secondaryDates = {
        startDate: createDateFromString(values.secondaryStartDate),
        endDate: new Date(values.secondaryEndDate),
      };
      dispatch(setPrimaryDates(primaryDates));
      dispatch(setSecondaryDates(secondaryDates));
    }
  } catch (error) {
    console.error("error getting dates: ", error);
    throw error;
  }
};

export const getMetricGoalsByEntityId =
  ({ entityId }: any) =>
  async (dispatch: any) => {
    try {
      const result = await metricsService.getMetricGoalsByEntityId(entityId);
      dispatch(setMetricGoals(result.data));
    } catch (err) {
      setMessage("There was a problem retrieving metric goals");
    }
  };

export const setDates =
  (entityId: number, dates: any) => async (dispatch: any) => {
    try {
      const updatedDates = await datesService.updateDates(entityId, dates);
      if (updatedDates) {
        await dispatch(getDates(entityId));
      }
    } catch (error) {
      console.error("error getting dates: ", error);
      throw error;
    }
  };
