import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getErrorsByEntityId = async ({ entityId }) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/errors?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    // Make the API call and await the response
    const response = await axios.get(uri, config);

    // Check if the response is successful
    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const deleteErrorById = async (errorId: number) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/errors?errorId=${errorId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    // Make the API call and await the response
    const response = await axios.delete(uri, config);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export default {
  getErrorsByEntityId,
  deleteErrorById,
};
