import {
  Add,
  CollectionsOutlined,
  MoreHoriz,
  Remove,
  SchoolOutlined,
  TrendingDownRounded,
  TrendingFlat,
  TrendingUpRounded,
} from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import shopifyGlyph from "assets/ShopifyBag/png/shopify_glyph.png";
import facebookGlyph from "assets/Facebook Brand Asset Pack 2019/f-Logos-2019-1/f_Logo_Online_04_2019/Color/PNG/f_logo_RGB-Blue_100.png";
import ga4Glyph from "assets/google-analytics-logo.png";
import amazonGlyph from "assets/amazonLogo.jpg";
import addCommasToFloat from "Utils/addCommasToFloat";
import { useTheme } from "@emotion/react";
import { object } from "prop-types";
import ChartDisplay from "./components/ChartDisplay";

const MetricCard = ({
  metric,
  comparisonData,
  showTotal,
  chartType,
}) => {
  const [sourceImages, setSourceImages] = useState([]);
  const [primaryDataPt, setPrimaryDataPt] = useState(metric?.value ?? 0);
  const [secondaryDataPt, setSecondaryDataPt] = useState(
    comparisonData?.value ?? 0
  );
  const [changeIcon, setChangeIcon] = useState(null);
  const [changeColor, setChangeColor] = useState("warning");
  const [change, setChange] = useState(0);

  const mapSourceImages = () => {
    const images = [];
    metric.sources?.forEach((source) => {
      switch (source) {
        case "shopify":
          images.push({ title: "Shopify", img: shopifyGlyph });
          break;
        case "facebook":
          images.push({ title: "Facebook Ads", img: facebookGlyph });
          break;
        case "GA4":
          images.push({ title: "GA4", img: ga4Glyph });
          break;
        case "amazon":
          images.push({ title: "Amazon", img: amazonGlyph });
          break;
        // Add more cases for other source types as integration list grows
        default:
          break;
      }
    });
    setSourceImages(images);
  };

  const calculateChange = () => {
    if (secondaryDataPt !== 0 && secondaryDataPt !== null) {
      const percentChange =
        ((primaryDataPt - secondaryDataPt) / secondaryDataPt) * 100;

      setChange(percentChange.toFixed(0));
      if (
        (metric.metric.positive === "up" && percentChange > 0) ||
        (metric.metric.positive === "down" && percentChange < 0)
      ) {
        setChangeIcon(<TrendingUpRounded />);
        setChangeColor("success");
      } else {
        setChangeIcon(<TrendingDownRounded />);
        setChangeColor("error");
      }
    } else {
      setChange(0);
      setChangeIcon(<TrendingFlat />);
      setChangeColor("warning");
    }
  };

  const getNormalizedData = () => {
    const byDate = comparisonData?.byDate;
    const normalizedData = [];
    byDate.forEach((entry, idx) => {
      normalizedData.push({ key: metric?.byDate[idx]?.key, data: entry?.data });
    });

    return normalizedData;
  };

  const populateTotal = () => {
    if (!primaryDataPt) {
      return 0;
    }
    const formattedResult = addCommasToFloat(Number(primaryDataPt));
    return formattedResult;
  };


  useEffect(() => {
    calculateChange();
  }, [primaryDataPt, secondaryDataPt]);

  useEffect(() => {
    mapSourceImages();
  }, [metric]);

  return (
    <>
      <Box
        display="flex"
        sx={{ mb: 1, minHeight: "25px" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <AvatarGroup max={3}>
          {sourceImages?.map((source, index) => (
            <Tooltip title={source.title}>
              <Avatar
                key={index}
                src={source.img}
                alt={`Source ${index}`} // Provide alt text for accessibility
                sx={{ width: "20px", height: "20px" }}
              />
            </Tooltip>
          ))}
        </AvatarGroup>
        <Box>
          {showTotal && (
            <Tooltip title="Total for period">
              <Chip
                size="small"
                variant="contained"
                color="success"
                label={`${!metric.metric?.suffix ? metric.metric?.value : ""}
                ${populateTotal()} 
                 ${metric.metric?.suffix ? metric.metric?.value : ""}`}
              />
            </Tooltip>
          )}
          {(change || change === 0) && (
            <Tooltip title="Compared to Vs. period">
              <Chip
                size="small"
                sx={{ ml: 1 }}
                icon={changeIcon}
                variant="contained"
                color={changeColor}
                label={`${change}%`}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Typography variant="subtitle2" fontWeight={700} color="gray">
          Was:{" "}
          {`${
            !metric.metric?.suffix ? metric.metric?.value : ""
          } ${addCommasToFloat(Number(secondaryDataPt ?? 0))} ${
            metric.metric?.suffix ? metric.metric?.value : ""
          }`}
        </Typography>
      </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ChartDisplay
            type={chartType ?? "area"}
            data={metric.byDate}
            comparisonData={getNormalizedData()}
            setPrimaryDataPt={setPrimaryDataPt}
            setSecondaryDataPt={setSecondaryDataPt}
            defaultValue={metric.value}
            defaultComparisonValue={comparisonData.value}
          />
        </Box>
    </>
  );
};
MetricCard.propTypes = {
  metric: object.isRequired,
};
MetricCard.defaultProps = {
  showTotal: true,
  showChangeSize: true,
};
export default MetricCard;
