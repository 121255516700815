export const getAuthToken = () => {
    const storage = localStorage.getItem("auth");
    if (storage) {
        const parsedStorage = JSON.parse(storage);
        return parsedStorage.token ?? null
    }

    throw new Error("Access token not found!");
}

// authUtils.js
export const isAuthTokenValid = (authToken) => {
    if (!authToken) {
      return false; // No token, not valid
    }
    
    const currentTimestamp = new Date().getTime();
    const tokenExpiration = new Date(authToken.expiresAt).getTime();
    
    return currentTimestamp < tokenExpiration;
  };
  