import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import HeroImg from "assets/hero-img.png";
import CTA from "Components/CTA/CTA";

const HeroSection = () => {
  return (
    <Grid
      container      
       sx={{       height:{xs: '70vh', md: "100vh"}}}
      columns={12}
      display="flex"
    >
      <Grid
        xs={12}
        md={6}
        flexDirection="column"
        item
        display="flex"
        justifyContent="center"
      >
        <Typography
          fontWeight={700}
          sx={{ fontSize: { xs: "48px", sm: "56px" }, lineHeight: 1.1 }}
          textAlign={{ xs: "left" }}
        >
          Take action with your e-commerce data.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5 }}
          fontWeight={700}
          textAlign="left"
          
          mt={1}
          mb={3}
        >
          Leverage AI and drive results with Vendanza's powerful e-commerce
          analytics solutions.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width={{ xs: "90%", sm: "80%", md: "50%" }}
        >
          <CTA />
        </Box>
      </Grid>
      <Grid
        item
        xs={0}
        sm={6}
        p={3}
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="left"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Box>
          <img
            src={HeroImg}
            alt="Your Text"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeroSection;
