import { Button } from "@mui/material";
import { isAuthTokenValid } from "Utils/data/authToken";
import sessionStorageUtils from "Utils/sessionStorage";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import userService from "services/userService.ts";
import { setUser } from "store/actions/userActions.ts";

const LoginBtn = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    const auth = sessionStorageUtils.getAuthStorage();
    if (auth) {
      const selectedEntity = sessionStorageUtils.getSelectedEntity();
      const isValidToken = isAuthTokenValid(auth);
      const sessionUser = await userService.getUserByUserId(auth?.userId);
      if (sessionUser) {
        sessionStorageUtils.setUserSessionStorage(
          sessionUser.data?.dataValues ?? null
        );
        dispatch(setUser(sessionUser));
      }
      if (isValidToken) {
        if (selectedEntity) {
          history.push(
            `entity/analytics/dashboard?entityId=${selectedEntity.id}`
          );
        } else {
          history.push(`dashboard`);
        }
      } else {
        history.push(`login`);
      }
    } else {
      history.push(`login`);
    }
  };

  return <Button size="large" onClick={handleLogin}>Login</Button>;
};

export default LoginBtn;
