import { Box, Button, Card, ListItem, Typography } from "@mui/material";
import ButtonLoader from "Components/ButtonLoader";
import { capitalize } from "Utils/capitalizeString";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";
import { setMessage } from "store/actions/messageActions.ts";

const AvailableAccount = ({ accountId, platform, tokenId, onUpdate }) => {
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleSelect = async () => {
    // should add the account to the selected accounts
    try {
      dispatch(setMessage(`Connecting ${accountId} from ${platform}...`));
      const result = await thirdPartyAccountService.addThirdPartyAccount({
        accountId,
        tokenId,
        entityId,
        platform,
      });
      if (result?.action === "created") {
        dispatch(
          setMessage(`Connected ${accountId} from ${capitalize(platform)}`)
        );
        setTimeout(() => {
          dispatch(setMessage("Triggering historical data sync..."));
        }, 2000);
        try {
          await thirdPartyAccountService.triggerHistoricalSync({
            accountId,
            tokenId,
            entityId,
            platform,
          });
        } catch (err) {
          dispatch(
            setMessage(
              `Failed to trigger historical sync for ${accountId} from ${capitalize(
                platform
              )}. Please manually trigger historical sync.`
            )
          );
        }
        await onUpdate();
      } else if (result.action === "updated") {
        dispatch(
          setMessage(
            `${accountId} from ${capitalize(platform)} is already connected.`
          )
        );
        await onUpdate();
      } else {
        dispatch(
          setMessage(
            `Failed to connect ${accountId} from ${capitalize(platform)}`
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Card elevation={0} sx={{ mb: 2 }}>
      <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="subtitle2">{accountId}</Typography>
        </Box>
        <Button disabled={loading} onClick={handleSelect}>
          <ButtonLoader text={"Connect"} size={20} onClick={handleSelect} />
        </Button>
      </ListItem>
    </Card>
  );
};

export default AvailableAccount;
