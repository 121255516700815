import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { capitalize } from "Utils/capitalizeString";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import CancelPlanModal from "./CancelPlanModal";

const Subscription = () => {
  const history = useHistory();
  const entity = useSelector((state) => state?.entity?.selectedEntity);

  return (
    <Grid
      container
      columns={12}
      disableGutters
      sx={{
        p: 1,
        my: 3,
      }}
      display="flex"
      flexDirection="column"
    >
      {/* <Grid item sx={{ mb: 2 }}>
        <Typography variant="h6">Project Details</Typography>
      </Grid>
      <Grid item>
        <TextField size="small" id="project-name" value={entity?.name} />
      </Grid>
      <Divider sx={{ mt: 3 }} /> */}

      <Grid item sx={{ mb: 2, mt: 2 }}>
        <Typography variant="h6">Subscription</Typography>
      </Grid>
      <Grid container display="flex">
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            {entity.subscriptionStatus && (
              <Chip
                sx={{ px: 2, mr: 3 }}
                size="small"
                color="primary"
                label={capitalize(entity?.subscriptionStatus)}
              />
            )}
            <Button
              onClick={() =>
                history.push(`/entity/analytics/pricing?entityId=${entity?.id}`)
              }
            >
              Change Plan
            </Button>
          </Box>
          {entity.subscriptionStatus !== "free" && <CancelPlanModal />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Subscription;
