import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";
// const populateToken = async (entityId: any, authCode: any) => {
//   try {
//     const accessToken = getAuthToken();
//     const uri = `${process.env.REACT_APP_VENDANZA_API}/integration/facebook/access-token`;
//     const body = {
//       entityId,
//       authCode,
//     };
//     const config = {
//       headers: {
//         role: "agent",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };
//     const result = axios.post(uri, body, config);
//     return result;
//   } catch (error) {
//     return "unable to populate accessToken";
//   }
// };

const getAdsData = async (entityId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/integration/facebook/ads-insights?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const checkFacebookToken = async (entityId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/integration/facebook/token-status?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const getAdAccounts = async (tokenId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/facebook/accounts?tokenId=${tokenId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const getStoredAdAccountsByTokenId = async (tokenId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/facebook/stored-accounts?tokenId=${tokenId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

const updateAdAccount = async (entityId: string, account: any, remove: boolean) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/facebook/accounts`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      account,
      entityId,
      remove,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const getStoredCredentials = async (entityId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/facebook/stored-credentials?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    console.error(error);
  }
}

export default {
  // populateToken,
  getAdsData,
  checkFacebookToken,
  getAdAccounts,
  getStoredAdAccountsByTokenId,
  updateAdAccount,
  getStoredCredentials,
};
