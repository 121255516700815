import {
  TextField,
  Button,
  InputAdornment,
  Typography,
  Grid,
  IconButton,
  Fade,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AccountCircle,
  PasswordRounded,
  VisibilityOutlined,
  VisibilityOffOutlined,
  Business,
} from "@mui/icons-material";
import ButtonLoader from "Components/ButtonLoader";
import { useDispatch } from "react-redux";
import { fetchEntities } from "store/actions/entityActions.ts";
import { setUser } from "store/actions/userActions.ts";
import { setIsLoggedIn } from "store/actions/authActions.ts";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import authService from "Api/auth/authService.ts";
import sessionStorage from "Utils/sessionStorage";
import { Link } from "react-router-dom";

const RegisterInput = ({ email, setEmail }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const dispatch = useDispatch();

  const handleSuccessfulRegistration = (result) => {
    sessionStorage.setUserSessionStorage(result.data?.user?.dataValues ?? null);
    sessionStorage.setAuthStorage(result.data.authToken.dataValues ?? null);
    dispatch(setUser(result.data?.user?.dataValues));
    dispatch(setIsLoggedIn(true));
    dispatch(fetchEntities(result?.data?.user?.dataValues?.id));
    history.push("/dashboard");
  };

  const verifyEmail = async () => {
    try {
      setError(null);
      const result = await authService.verifyEmail(email);
      console.log(result);
      if (!result?.data?.valid) {
        setError("Email is already in use");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }

      const result = await authService.registerAccount(
        email.toLowerCase(),
        password,
        companyName
      );

      if (result.status === 200) {
        handleSuccessfulRegistration(result);
      } else {
        throw new Error("Invalid username or password");
      }
    } catch (err) {
      setError(err.message);
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (email) {
  //     verifyEmail();
  //   }
  // }, [email]);

  return (
    <Grid
      container
      columns={16}
      sx={{ height: "92vh" }}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      display="flex"
    >
      <Fade in>
        <Box>
          <Grid item sx={{ mb: 6 }}>
            <Typography
              fontWeight={700}
              sx={{ fontSize: { xs: "48px", sm: "56px" }, lineHeight: 1.1 }}
              textAlign={{ xs: "center" }}
            >
              Join us.
            </Typography>
          </Grid>
          <Box component="form">
            <Grid item xs={16}>
              <TextField
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle sx={{ fontSize: "2rem" }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Email"
                value={email}
                onBlur={verifyEmail}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ textUnderlineOffset: "20px", pb: 1, fontSize: "24px" }}
                error={Boolean(error)}
                helperText={error}
              />
            </Grid>

            <Grid item xs={16} sx={{ mt: 3 }}>
              <TextField
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business sx={{ fontSize: "2rem" }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
                
            <Grid xs={16} item sx={{ mt: 3 }}>
              <TextField
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordRounded sx={{ fontSize: "2rem" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOutlined sx={{ fontSize: "1.5rem" }} />
                        ) : (
                          <VisibilityOffOutlined sx={{ fontSize: "1.5rem" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>

            <Grid xs={16} item sx={{ mt: 3 }}>
              <TextField
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordRounded sx={{ fontSize: "2rem" }} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Confirm Password"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={password !== confirmPassword && confirmPassword !== ""}
                helperText={
                  password !== confirmPassword && confirmPassword !== ""
                    ? "Passwords do not match"
                    : ""
                }
              />
            </Grid>

            <Grid
              xs={16}
              marginTop={6}
              item
              justifyContent="center"
              display="flex"
            >
              <Button
                disabled={
                  loading ||
                  !email ||
                  !password ||
                  !confirmPassword ||
                  !companyName ||
                  password !== confirmPassword ||
                  error
                }
                onClick={handleSubmit}
                type="submit"
                sx={{ pl: 8, pr: 8, textTransform: "none" }}
                variant="contained"
                fullWidth
              >
                <ButtonLoader text={"Register"} loading={loading} size={20} />
              </Button>
            </Grid>
          </Box>

          <Grid
            marginTop={6}
            xs={16}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              By continuing, you agree to the terms of use and
              <Link
                style={{ textDecoration: "none", color: "gray" }}
                to="/privacy"
              >
                {" "}
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
        </Box>
      </Fade>
    </Grid>
  );
};

export default RegisterInput;
