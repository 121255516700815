import thirdPartyAccountService from "services/thirdPartyAccountService.ts";

export const setThirdPartyAccountsCount = (accounts: any) => ({
  type: "SET_THIRD_PARTY_ACCOUNT_COUNT",
  payload: accounts.length,
});

export const setNumIntegrations = (numIntegrations: number) => ({
  type: "SET_THIRD_PARTY_ACCOUNT_NUM_INTEGRATIONS",
  payload: numIntegrations,
});

export const setMaxIntegrations = (maxIntegrations: number) => ({
  type: "SET_MAX_INTEGRATIONS",
  payload: maxIntegrations,
});

export const getThirdPartyAccountsByEntityId =
  (entity: any) => async (dispatch: any) => {
    try {
      const response =
        await thirdPartyAccountService.getThirdPartyAccountsByEntityId(
          entity?.id
        );
      const platforms = response.map(
        (account: any) => account.dataValues.platform
      );
      const uniquePlatforms = new Set(platforms);
      const numUniquePlatforms = uniquePlatforms.size;
      dispatch(setNumIntegrations(numUniquePlatforms));
      if (entity?.subscriptionStatus === "free") {
        dispatch(setMaxIntegrations(2));
      } else {
        dispatch(setMaxIntegrations(null));
      }
      dispatch(setThirdPartyAccountsCount(response));
    } catch (error) {
      console.error("Error fetching entities:", error);
    }
  };
