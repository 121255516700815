import { Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RegisterBtn = () => {
  const history = useHistory();

  return (
    <Button
    variant="contained"
    onClick={()=>history.push(`/register`)}
    sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        
      }}
      >
        Sign Up
      </Button>
  )
}

export default RegisterBtn;