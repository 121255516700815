import React from "react";
import GAConfig from "./GAConfig";
import oAuthUtils from "./utils/oAuthUtils";
import ConnectActionBtn from "Components/Integrations/ConnectActionBtn/ConnectActionBtn";

const Action = ({ accounts, onUpdate, credsPresent }) => {
  if (accounts.length || credsPresent) {
    return <GAConfig/>;
  }
  return (
    <ConnectActionBtn onContinue={() => oAuthUtils.handleLogin()}>
      Connect
    </ConnectActionBtn>
  );
};

export default Action;
