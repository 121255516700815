import { CircularProgress, Fade } from "@mui/material";

const ButtonLoader = ({loading, text, size}) => {
    if (loading) {
        return <Fade in appear>
            <CircularProgress size={size}/>
            </Fade>
    }
    return text;
}

export default ButtonLoader;