import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// Mock Data for demonstration (replace this with actual API data)
const mockData = {
  campaign: [
    { name: "Campaign A", data: [10, 15, 12, 8, 7], dates: ["01 Sep", "02 Sep", "03 Sep", "04 Sep", "05 Sep"] },
    { name: "Campaign B", data: [5, 12, 6, 4, 3], dates: ["01 Sep", "02 Sep", "03 Sep", "04 Sep", "05 Sep"] },
  ],
  adSet: [
    { name: "AdSet A", data: [20, 25, 15, 18, 27], dates: ["01 Sep", "02 Sep", "03 Sep", "04 Sep", "05 Sep"] },
    { name: "AdSet B", data: [15, 22, 14, 13, 17], dates: ["01 Sep", "02 Sep", "03 Sep", "04 Sep", "05 Sep"] },
  ],
};

const AdDataGraph = ({ adsData, level }) => {
  // State for metric, time span, and level selection
  const [selectedMetric, setSelectedMetric] = useState("CPC");
  const [selectedTimeSpan, setSelectedTimeSpan] = useState("7d");

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "ad-performance-chart",
        toolbar: {
            show: false, // Hide the menu options
          },
      },
      xaxis: {
        categories: mockData.campaign[0].dates, // Replace with real dates
      },
    },
    series: mockData.campaign.map((campaign) => ({
      name: campaign.name,
      data: campaign.data, // Replace with real data
    })),
  });

  // Handler for level selection (Campaign, AdSet, Ad)
  const handleLevelChange = (event) => {
    const data = mockData[level]; // Update to actual data
    setChartData({
      ...chartData,
      series: data.map((item) => ({ name: item.name, data: item.data })),
      options: { ...chartData.options, xaxis: { categories: data[0].dates } },
    });
  };

  // Handler for metric selection (CPC, CPM, etc.)
  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
    // Fetch and update the chart data based on metric here
  };

  // Handler for time span selection (7 days, 30 days, etc.)
  const handleTimeSpanChange = (event) => {
    setSelectedTimeSpan(event.target.value);
    // Fetch and update the chart data based on time span here
  };

  return (
    <div>

      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default AdDataGraph;
