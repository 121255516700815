export const setGraphMetricName = (metricName: string) => ({
        type: "SET_METRIC_NAME",
        payload: metricName,
})

export const setGraphMetrics = (metrics: any[]) => ({
        type: "SET_SELECTED_METRICS",
        payload: metrics,
})

export const setGraphFilters = (filters: any[]) => ({
        type: "SET_FILTERS",
        payload: filters,
})

export const setGraphType = (graphType: string) => ({
        type: "SET_GRAPH_TYPE",
        payload: graphType,
})
export const setGraphOperation = (operation: any[]) => ({
        type: "SET_OPERATION",
        payload: operation,
})