import { Grid, Typography } from '@mui/material'
import CreateBoard from 'Components/CreateBoard/CreateBoard'
import React from 'react'

const NoBoardsMsg = () => {
  return (
    <Grid height="50vh" container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Grid item mb={2}>
            <Typography textAlign="center" variant="h6">
                It's looking pretty empty in here...
            </Typography>
            <Typography textAlign="center" variant="subtitle2" color="white">
                Create a board to give your data a home.
            </Typography>
        </Grid>
        <Grid item>
            <CreateBoard/>
        </Grid>
    </Grid>
  )
}

export default NoBoardsMsg