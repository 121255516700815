import { Add, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ButtonLoader from "Components/ButtonLoader";
import sessionStorage from "Utils/sessionStorage";
import { bool, string } from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import entityService from "services/entityService.ts";
import {
  fetchEntities,
  setSelectedEntity,
} from "store/actions/entityActions.ts";
import { setMessage } from "store/actions/messageActions.ts";

const AddEntity = ({ buttonVariant, showIcon }) => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state?.entity?.entities);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setName("");
    setLoading(false);
  };

  const handleEntitySelect = (details) => {
    sessionStorage.setSelectedEntity(details);
    dispatch(setSelectedEntity(details));
    history.push(`/entity/analytics/dashboard?entityId=${details.id}`);
  };

  const createEntity = async () => {
    try {
      setLoading(true);
      const newEntity = await entityService.createEntity(name);
      if (newEntity) {
        handleEntitySelect(newEntity.data?.newEntity?.dataValues);
        setShow(false);
        setName("");
      } else {
        throw new Error("Failed to create new entity");
      }
    } catch (error) {
      dispatch(setMessage(error.message));
    } finally {
      const sessionUser = sessionStorage.getUserSessionStorage();
      dispatch(fetchEntities(sessionUser.id));
      setLoading(false);
    }
  };

  const handleOpen = () => {
    const maxFreeProjects = 2;
    const currentFreeProjectsCount = projects?.filter(
      (project) => project.subscriptionStatus === "free"
    ).length;
    if (currentFreeProjectsCount >= maxFreeProjects) {
      dispatch(
        setMessage(
          "You're out of free projects. Convert one of your existing projects to a paid plan to create a new project."
        )
      );
    } else {
      setShow(true);
    }
  };
  return (
    <>
      <Button
      sx={{width: {xs: '90%', sm: 'auto'}}}
        variant={buttonVariant}
        onClick={handleOpen}
        startIcon={showIcon ? <Add /> : ""}
      >
        New Project
      </Button>
      <Dialog fullWidth open={show}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography>New Project</Typography>
            <Typography variant="subtitle2">
              Create a project to start compiling data.
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Project Name"
            label="Project Name"
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2, mt: 1 }}
            fullWidth
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading || !name} onClick={createEntity}>
            <ButtonLoader text="Create" size={20} loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddEntity.propTypes = {
  buttonVariant: string,
  showIcon: bool,
};

AddEntity.defaultProps = {
  buttonVariant: "contained",
  showIcon: false,
};

export default AddEntity;
