import {MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import DeleteBusiness from './components/DeleteBusiness';
import ProjectDetails from './components/ProjectDetails';

const BusinessMenu = ({details}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    }
  return (
    <>
    <IconButton onClick={handleClick}>
    <MoreVert />
  </IconButton>
  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
    {/* <ProjectDetails details={details}/> */}
    <DeleteBusiness  details={details}/>
  </Menu>
    </>
  )
}

export default BusinessMenu;