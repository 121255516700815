import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import shopifyService from "services/shopifyService.ts";
import shopifyLogo from "assets/ShopifyBag/png/shopify_glyph.png";
import { useQueryParams } from "hooks/useQueryParams";
import IntegrationTitle from "Components/Integrations/IntegrationAccount/IntegrationTitle";
import ShopOAuthBtn from "./ShopOAuthBtn";
import SingleAccount from "Components/Integrations/SingleAccount/SingleAccount";
import { useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";

const ShopConfigure = () => {
  const [open, setOpen] = useState(false);
  const [shopifyAccounts, setShopifyAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const queryParams = useQueryParams();
  const entityId = queryParams.get("entityId");
  const dispatch = useDispatch();
  const getShopifyAccounts = async () => {
    try {
      setLoading(true);
      const result = await shopifyService.getShopifyAccountsByEntityId(
        entityId
      );
      setShopifyAccounts(result.data);
    } catch (error) {
      console.error(error);
      dispatch(setMessage("Error fetching Shopify accounts: ", error.message));
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (open) {
      getShopifyAccounts();
    }
  }, [open]);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Configure</Button>
      <Dialog fullWidth open={open}>
        <IntegrationTitle
          title={"Shopify Stores"}
          handleClose={() => setOpen(false)}
          logo={shopifyLogo}
        />
        <DialogContent>
          {shopifyAccounts.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <Typography>You don't have any connected shops.</Typography>
            </Box>
          )}
          <List>
            {shopifyAccounts?.map((account) => {
              return (
                <SingleAccount
                  key={account.id}
                  primaryText={account.accountId}
                  account={account}
                  onDeleteSuccess={getShopifyAccounts}
                  onDeleteFail={getShopifyAccounts}
                />
              );
            })}
          </List>
        </DialogContent>
        <DialogActions
          sx={{ p: 3, display: "flex", justifyContent: "space-between" }}
        >
          <ShopOAuthBtn text={"Add Shopify Store"} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShopConfigure;
