import { ChevronLeft } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { setCurrentTab } from "store/actions/navigationActions.ts";
import ChatHistory from "views/Chat/components/ChatHistory";

const MetricsNavigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentEntity = useSelector((state) => state.entity?.selectedEntity);
  const back = () => {
    dispatch(setCurrentTab("dashboard"));
    history.push(`/entity/analytics/dashboard?entityId=${currentEntity?.id}`);
  };

  return (
    <Box mt={2} mx={1}>
      <Button sx={{ pr: 3, mb: 1 }} onClick={back} startIcon={<ChevronLeft />}>
        Back
      </Button>
      <ChatHistory/>
    </Box>
  );
};

export default MetricsNavigation;
