import paymentsService from "services/paymentsService.ts";
import { setMessage } from "store/actions/messageActions.ts";
import { fetchEntities, fetchEntitybyId } from "store/actions/entityActions.ts";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";

const determineProvider = async (entityId) => {
  try {
    const integrations =
      await thirdPartyAccountService.getStoredAccountsByEntityIdPlatform(
        entityId,
        "shopify"
      );
    if (
      integrations?.data?.some(
        (integration) => integration?.platform === "shopify"
      )
    ) {
      return { provider: "shopify", account: integrations?.data[0] };
    }
    return { provider: "stripe", account: null };
  } catch (err) {
    throw new Error(`Failed to determine provider. Reason: ${err.message}`);
  }
};
const handleUpgrade = async ({ dispatch, entity, user, plan }) => {
  try {
    dispatch(setMessage("Upgrading Project..."));
    const { provider } = await determineProvider(entity?.id);
    const result = await paymentsService.changePlan({
      entityId: entity?.id,
      newPlan: plan,
      provider,
    });
    if (provider === "shopify") {
      //redirect to shopify to trigger subscription updates
      window.location.href = result?.data?.confirmation_url;
    } else if (result.status === 200) {
      await paymentsService.updateEntitySubscriptionStatusById({
        entityId: entity?.id,
        status: plan.id,
      });

      dispatch(
        setMessage("Plan upgraded successfully! Please refresh your browser.")
      );
      dispatch(fetchEntities(user?.id));
      dispatch(fetchEntitybyId(entity?.id));
    } else {
      dispatch(setMessage("Failed to upgrade project"));
    }
  } catch (err) {
    dispatch(
      setMessage(`Failed to upgrade subscription. Reason: ${err.message}`)
    );
  }
};

const handleDowngrade = async ({ dispatch, entity, user, setOpen, plan }) => {
  try {
    dispatch(setMessage("Downgrading Project..."));

    const { provider } = await determineProvider(entity?.id);
    const entityId = entity?.id;
    let result;

    if (plan.id === "free") {
      result = await paymentsService.cancelPlan(entityId);
    } else {
      result = await paymentsService.changePlan({
        entityId,
        newPlan: plan,
        provider,
      });

      if (provider === "shopify") {
        window.location.href = result?.data?.confirmation_url;
        return; // Redirecting to Shopify, no further action needed
      }
    }

    if (result.status === 200) {
      if (provider !== "shopify") {
        await paymentsService.updateEntitySubscriptionStatusById({
          entityId,
          status: plan.id,
        });
      }
      setOpen(false);
      dispatch(
        setMessage("Plan downgraded successfully! Please refresh your browser.")
      );
    } else {
      dispatch(setMessage("Failed to downgrade project"));
    }

    // Refresh entity data
    dispatch(fetchEntities(user?.id));
    dispatch(fetchEntitybyId(entityId));
  } catch (err) {
    dispatch(
      setMessage(`Failed to cancel subscription. Reason: ${err.message}`)
    );
  }
};

export default { handleDowngrade, handleUpgrade, determineProvider };
