import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import Calendar from "react-calendar";
import { Button, Card, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getBaseMetrics, setDates } from "store/actions/metricActions.ts";
import ButtonLoader from "Components/ButtonLoader";
import "./DateRangeSelector.css";

const DateRangeSelector = () => {
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const loading = useSelector((state) => state.loading.loadingData);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSecondaryEl, setAnchorSecondaryEl] = useState(null);

  const primaryDates = [
    useSelector((state) => state.metrics?.primaryStartDate),
    useSelector((state) => state.metrics?.primaryEndDate),
  ];
  const secondaryDates = [
    useSelector((state) => state.metrics?.secondaryStartDate),
    useSelector((state) => state.metrics?.secondaryEndDate),
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSecondaryClick = (event) => {
    setAnchorSecondaryEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSecondaryClose = () => {
    setAnchorSecondaryEl(null);
  };

  const calculateDateDifference = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    const start = startDate.getTime();
    const end = endDate.getTime();
    const differenceInMilliseconds = end - start;
    const differenceInDays = Math.round(differenceInMilliseconds / oneDay);
    return differenceInDays;
  };

  const handleDatesChange = (type, dates) => {
    let formattedDates;
    let dateDifference = calculateDateDifference(
      primaryDates[0],
      primaryDates[1]
    );
    if (type === "primary") {
      dateDifference = calculateDateDifference(dates[0], dates[1]);
      const newSecondaryEndDate = new Date(
        secondaryDates[0].getTime() + dateDifference * 24 * 60 * 60 * 1000
      );

      formattedDates = {
        primaryStartDate: dates[0],
        primaryEndDate: dates[1],
        secondaryStartDate: secondaryDates[0],
        secondaryEndDate: newSecondaryEndDate,
      };
    } else {
      const newSecondaryEndDate = new Date(
        dates[0].getTime() + dateDifference * 24 * 60 * 60 * 1000
      );

      formattedDates = {
        primaryStartDate: primaryDates[0],
        primaryEndDate: primaryDates[1],
        secondaryStartDate: dates[0],
        secondaryEndDate: newSecondaryEndDate,
      };
    }
    dispatch(setDates(entityId, formattedDates));
    handleClose();
    handleSecondaryClose();
  };

  
  const handleRefreshData = () => {
    dispatch(getBaseMetrics(entityId));
  };


  const open = Boolean(anchorEl);
  const secondaryOpen = Boolean(anchorSecondaryEl);
  const id = open ? "simple-popover" : undefined;
  const secondaryId = secondaryOpen ? "simple-popover" : undefined;

  return (
    <Grid
      container
      columns={16}
      display="flex"
      justifyContent={{ xs: "center", sm: "flex-end" }}
      sx={{ mt: { xs: 3, sm: 0 } }}
    >
      <Grid
        item
        xs={16}
        sm={5.5}
        md={5}
        lg={4.5}
        sx={{ mr: { xs: 0, sm: 1 }, mb: { xs: 2, sm: 0 } }}
      >
        <TextField
          fullWidth
          size="small"
          id="date-picker"
          label="Period"
          onClick={handleClick}
          value={`${primaryDates[0]?.toLocaleDateString(
            "en-US"
          )} - ${primaryDates[1]?.toLocaleDateString("en-US")}`}
        />
      </Grid>
      <Grid
        item
        xs={16}
        sm={5.5}
        md={5}
        lg={4.5}
        sx={{ mr: { xs: 0, sm: 1 }, mb: { xs: 2, sm: 0 } }}
      >
        <TextField
          fullWidth
          size="small"
          id="date-picker"
          label="Vs. Period"
          onClick={handleSecondaryClick}
          value={`${secondaryDates[0]?.toLocaleDateString(
            "en-US"
          )} - ${secondaryDates[1]?.toLocaleDateString("en-US")}`}
        />
      </Grid>
      <Grid
        item
        xs={16}
        sm={4}
        md={5}
        lg={3}
        alignItems="center"
        display="flex"
        sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 1, sm: 0 } }}
      >
        <Button
          disabled={loading}
          fullWidth
          variant="contained"
          onClick={handleRefreshData}
        >
          <ButtonLoader loading={loading} text="Refresh Data" size={20} />
        </Button>
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card>
          <Calendar
            onChange={(e) => handleDatesChange("primary", e)}
            selectRange
            value={primaryDates}
          />
        </Card>
      </Popover>
      <Popover
        id={secondaryId}
        open={secondaryOpen}
        anchorEl={anchorSecondaryEl}
        onClose={handleSecondaryClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card sx={{ p: 1 }}>
          <Calendar
            onChange={(e) => handleDatesChange("secondary", e)}
            selectRange
            value={secondaryDates}
          />
        </Card>
      </Popover>
    </Grid>
  );
};

export default DateRangeSelector;
