import React from "react";
import { Box, useMediaQuery, AppBar, Paper, Button } from "@mui/material";
import LogoBrand from "assets/vendanza/brand/logo-full-light.svg";
import MobileMenu from "./components/MobileMenu";
import LoginBtn from "./components/LoginBtn";
import RegisterBtn from "./components/RegisterBtn";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FatMenu from "../../../../Components/FatMenu";
import {
  AnalyticsOutlined,
  BusinessOutlined,
  CableOutlined,
  CampaignOutlined,
  DashboardCustomizeOutlined,
  DynamicFeedOutlined,
  ForumOutlined,
  GraphicEqOutlined,
  ReportOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";

const LandingHeader = () => {
  const isScreenMedium = useMediaQuery("(min-width: 960px)");
  const history = useHistory();
  const resourcesMenu = [
    {
      title: "Community",
      description:
        "Collaborate with peers and get support from Vendanza on your projects.",
      icon: ForumOutlined,
      link: "/community",
    },
    {
      title: "About Us",
      description: "Learn more about our company and mission.",
      icon: BusinessOutlined,
      link: "/company",
    },
    {
      title: "Integrations",
      description:
        "See the full list of platforms and services we currently integrate with.",
      icon: CableOutlined,
      link: "integrations",
    },
  ];

  const featuresMenu = [
    {
      title: "1-click Integrations",
      description:
        "Seamless no-code integrations with your platforms of choice.",
      icon: CableOutlined,
      link: "/integrations",
    },
    {
      title: "Advanced Analytics",
      description:
        "Automatically determine AOV, LTV, Revenue, Spend, and so much more.",
      icon: AnalyticsOutlined,
      link: "/hello",
    },
    {
      title: "Insights",
      description:
        "Automatically collect insights from your metrics, compared to previous time periods.",
      icon: TrendingUpOutlined,
      link: "/hello",
    },
    {
      title: "Dashboards",
      description:
        "Fully customizable project dashboards to display your metrics, goals, targets, etc.",
      icon: DashboardCustomizeOutlined,
      link: "/hello",
    },
    {
      title: "Cohort Analysis",
      description: "Coming soon!",
      icon: GraphicEqOutlined,
      link: "/hello",
    },
    {
      title: "Multiple Projects",
      description:
        "Manage unlimited projects from the same account. Great for agencies and departmental organizations.",
      icon: DynamicFeedOutlined,
      link: "/hello",
    },
    {
      title: "Custom Metric Analysis",
      description:
        "Create customizable graphs to compare different metrics over time.",
      icon: AnalyticsOutlined,
      link: "/hello",
    },
    // {
    //   title: "Automations",
    //   description:
    //     "Access our automations library or leverage AI to build your own.",
    //   icon: AutoModeOutlined,
    //   link: "/hello",
    // },
    {
      title: "Alerts",
      description:
        "Never miss an issue or opportunity. Customize alerts to fit your needs.",
      icon: CampaignOutlined,
      link: "/hello",
    },
    {
      title: "Reporting",
      description: "Coming soon!",
      icon: ReportOutlined,
      link: "/hello",
    },
  ];
  return (
    <AppBar>
      <Paper
        sx={{
          display: "flex",

          justifyContent: "space-between",
          position: "fixed",
          pl: 3,
          pt: 2,
          pr: 2,
          pb: 2,
          width: "100vw",
          alignItems: "center",
        }}
        display="flex"
        elevation={0}
      >
        <Box
          alignItems="center"
          onClick={() => history.push("/hello")}
          component="img"
          sx={{ height: { xs: "40px", sm: "60px" }, cursor: "pointer" }}
          src={LogoBrand}
        />
        {isScreenMedium ? (
          <Box sx={{ display: "flex", gap: 2, mr: 3 }}>
            <FatMenu items={resourcesMenu} title="Resources" />
            <Button size="large" onClick={() => history.push("/pricing")}>
              Pricing
            </Button>
            <FatMenu readOnly items={featuresMenu} title="Features" />
            <LoginBtn />
            <RegisterBtn />
          </Box>
        ) : (
          <Box sx={{ pr: 3 }} justifyContent="center">
            {/* Display NoLoginMenu for smaller screens */}
            <MobileMenu />
          </Box>
        )}
      </Paper>
    </AppBar>
  );
};

export default LandingHeader;
