const handleLogin = () => {
  // Replace YOUR_APP_ID with your actual Facebook app ID
  const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
  // Define the required Facebook permissions
  const scope = "ads_management";
  // Define the desired redirect URL after successful login
  const redirectUriWithParams = `${process.env.REACT_APP_BASE_URL}/redirect?func=oAuthRedirect&integrationId=FACEBOOK`;

  // Encode the redirect URI to include in the login URL
  const encodedRedirectUri = encodeURIComponent(redirectUriWithParams);

  // Construct the Facebook login URL
  const loginUrl = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${appId}&redirect_uri=${encodedRedirectUri}&scope=${scope}`;
  // Redirect the user to the Facebook login URL
  window.location.href = loginUrl;
};

export default {
  handleLogin,
};
