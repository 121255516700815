const initialState = {
  entities: [],
  selectedEntity: {},
};

const entityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_ENTITIES":
      return {
        ...state,
        entities: action.payload,
      };
    case "SET_SELECTED_ENTITY":
      return {
        ...state,
        selectedEntity: action.payload,
      };
    default:
      return state;
  }
};

export default entityReducer;
