const initialState = {
    count: 0,
    numIntegrations: 0,
    maxIntegrations: 2,
}

const thirdPartyAccountsReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "SET_THIRD_PARTY_ACCOUNT_COUNT":
        return {
          ...state,
          count: action.payload,
        };
      case "SET_THIRD_PARTY_ACCOUNT_NUM_INTEGRATIONS":
        return {
          ...state,
          numIntegrations: action.payload,
        };
      case "SET_MAX_INTEGRATIONS":
        return {
          ...state,
          maxIntegrations: action.payload,
        };
      default:
        return state;
    }
  };

  export default thirdPartyAccountsReducer;