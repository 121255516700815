const initialState = {
    boards: [],
    loadingBoards: true,
}

const boardReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case 'SET_BOARDS':
            return {
                ...state,
                boards: action.payload
            }
        case 'SET_LOADING_BOARDS':
            return {
                ...state,
                loadingBoards: action.payload
            }
        default: 
        return state;
    }
}

export default boardReducer;