const getUserSessionStorage = () => {
  return JSON.parse(window.localStorage.getItem("user")) || {};
};
const setUserSessionStorage = (user) => {
  window.localStorage.setItem("user", JSON.stringify(user));
};


const setSelectedEntity = (entity) => {
  window.localStorage.setItem("selectedEntity", JSON.stringify(entity));
};

const setAuthStorage = (authToken) => {
  window.localStorage.setItem("auth", JSON.stringify(authToken));
};

const getSelectedEntity = () => {
  const selectedEntity = window.localStorage.getItem("selectedEntity");
  return selectedEntity ? JSON.parse(selectedEntity) : null;
};

const getAuthStorage = () => {
  const authData = window.localStorage.getItem("auth");
  return authData ? JSON.parse(authData) : null;
};


const getEntitySessionStorage = () => {
  return window.localStorage.getItem("currentEntity") || null;
};

const deleteAuthStorage = () => {
  window.localStorage.removeItem("auth");
};

const deleteEntityStorage = () => {
  window.localStorage.removeItem("selectedEntity"); 
}

const getCachedTokenId = () => {
  return window.sessionStorage.getItem("cachedTokenId") || null;
}

const deleteUserStorage = () => {
  window.localStorage.removeItem("user");
};

const handleLogout = () => {
  deleteAuthStorage();
  deleteEntityStorage();
  deleteUserStorage();
  return true;
}

const setOAuthRefreshData = (data) => {
  window.sessionStorage.setItem("oauthRefreshData", JSON.stringify(data));
};

const getOAuthRefreshData = () => {
  return JSON.parse(window.sessionStorage.getItem("oauthRefreshData")) || null;
};

const clearOAuthRefreshData = () => {
  window.sessionStorage.removeItem("oauthRefreshData");
}

export default {
  getUserSessionStorage,
  setUserSessionStorage,
  deleteUserStorage,
  setAuthStorage,
  deleteAuthStorage,
  getEntitySessionStorage,
  getAuthStorage,
  setSelectedEntity,
  getSelectedEntity,
  deleteEntityStorage,
  handleLogout,
  getCachedTokenId,
  setOAuthRefreshData,
  getOAuthRefreshData,
  clearOAuthRefreshData,
};
