import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const updateShopifyUri = async (entityId: string, shopUrl: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/integration/shopify?entityId=${entityId}&uri=${shopUrl}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.post(uri, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const getShopifyUri = async (entityId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/integration/shopify?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const getShopData = async (
  entityId: string,
  source: any,
  startDate: string,
  endDate: string
) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/integration/shopify/data?entityId=${entityId}&accountUri=${source.accountUri}&startDate=${startDate}&endDate=${endDate}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

export default {
  updateShopifyUri,
  getShopifyUri,
  getShopData,
};
