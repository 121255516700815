import errorService from "services/errorService.ts";
export const setErrors = (errors: any[]) => ({
    type: 'SET_ERRORS',
    payload: errors
});

export const getErrorsByEntityId = (entityId: number) => async (dispatch: any) => {
    try {
      const response = await errorService.getErrorsByEntityId({entityId});
      dispatch(setErrors(response)); // Dispatch the action with the fetched data
    } catch (error) {
      console.error("Error fetching backend errors:", error);
    }
  };