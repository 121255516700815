import sessionStorage from "Utils/sessionStorage";

export const setIsLoggedIn = (isLoggedIn: boolean) => ({
  type: "SET_IS_LOGGED_IN",
  payload: isLoggedIn,
});
export const resetStore = () => ({
  type: "RESET_STORE",
});

export const checkAuthToken = (dispatch: any) => {
  const authToken = sessionStorage.getAuthStorage();

  if (authToken) {
    const currentDateTime = new Date().getTime();
    const expiresAtTime = new Date(authToken.expiresAt).getTime();
    dispatch(setIsLoggedIn(currentDateTime < expiresAtTime));
  } else {
    dispatch(setIsLoggedIn(false));
  }
};

export const logoutUser = (dispatch: any) => {
    sessionStorage.deleteAuthStorage();
    dispatch(resetStore())
}
