import { Close, DeleteOutlineRounded } from "@mui/icons-material";
import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

const DeleteChatDialog = ({ details, open, setOpen, onDelete }) => {

  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Delete <strong>{details.name}</strong>?</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete <strong>{details.name}</strong>? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button startIcon={<DeleteOutlineRounded/>} onClick={onDelete} color="error" variant="outlined">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteChatDialog;
