import { Container } from "@mui/material";
import Board from "Components/Board/Board";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBaseMetrics,
  getDates,
  getMetricGoalsByEntityId,
} from "store/actions/metricActions.ts";
import CreateBoard from "Components/CreateBoard/CreateBoard";
import { getBoards } from "store/actions/boardActions.ts";
import NoBoardsMsg from "./Components/Displays/Widgets/NoBoardsMsg";
import NoConnectorsMsg from "./Components/Displays/Widgets/NoConnectorsMsg";
import { getThirdPartyAccountsByEntityId } from "store/actions/thirdPartyAcctActions.ts";
import AnalyticsHeader from "./Components/Displays/AnalyticsHeader";
import Errors from "Components/Errors/Errors";
import { setMessage } from "store/actions/messageActions.ts";
import BoardMenu from "Components/Board/components/BoardMenu/BoardMenu";
const AnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const boards = useSelector((state) => state?.boards?.boards);
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  const accountsCount = useSelector(
    (state) => state?.thirdPartyAccounts?.count
  );
  const populateDashboard = async () => {
    try {
      dispatch(getBoards(entity?.id, "dashboard"));
      dispatch(getDates(entity?.id));
      dispatch(getBaseMetrics(entity?.id));
      dispatch(getMetricGoalsByEntityId({ entityId: entity?.id }));
      dispatch(getThirdPartyAccountsByEntityId(entity));
    } catch (error) {
      setMessage(error.message);
    }
  };

  useEffect(() => {
    if (entity?.id) {
      populateDashboard();
    }
  }, [entity?.id]);
  return (
    <Container
      sx={{ mt: { xs: 6, md: 0 }, pt: 0, pb: 12, minHeight: "90vh" }}
      display="flex"
    >
      <Errors />
      <AnalyticsHeader />
      {accountsCount === 0 && <NoConnectorsMsg />}
      {boards?.map((board) => {
        return (
          <Board key={board?.dataValues?.boardId} details={board.dataValues} />
        );
      })}

      {boards.length > 0 && <BoardMenu variant="contained" create />}
      {boards.length === 0 && <NoBoardsMsg />}
    </Container>
  );
};

export default AnalyticsDashboard;
