import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import ButtonLoader from "Components/ButtonLoader";
import { bool } from "prop-types";
import React from "react";

const DeleteAccount = ({
  warnDelete,
  setWarnDelete,
  loading,
  onDelete,
  plural,
}) => {
  return (
    <Dialog fullWidth={false} open={warnDelete}>
      <DialogTitle display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h5">Are you sure?</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => setWarnDelete(false)}>
            {" "}
            <Close />{" "}
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {plural
          ? `All data associated with these accounts will be removed from this project.
           If you want to keep historical data, you can instead disable streaming 
           for these accounts.`
          : `All data associated with this account will be removed from this project.
        If you want to keep historical data, you can instead disable streaming
        for this account.`}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={() => setWarnDelete(false)}>Cancel</Button>
        <Button onClick={onDelete}>
          <ButtonLoader
            text={plural ? `Remove Accounts` : `Remove Account`}
            loading={loading}
            size={20}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteAccount.propTypes = {
  plural: bool,
};

DeleteAccount.defaultProps = {
  plural: false,
};

export default DeleteAccount;
