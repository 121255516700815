import adsService from "services/adsService.ts";

export const setAdsData = (data: any) => ({
  type: "SET_ADS_DATA",
  payload: data,
});

export const setAdSetData = (data: any) => ({
  type: "SET_AD_SET_DATA",
  payload: data,
});

export const setAdCampaignData = (data: any) => ({
  type: "SET_AD_CAMPAIGN_DATA",
  payload: data,
});


export const getAdvertisingData =
  (entityId: string, platform?: string) => async (dispatch: any) => {
    const types = [{type: "ads", dispatch: 'SET_ADS_DATA'}, {type: "adset", dispatch: 'SET_ADSET_DATA'}, {type: "adcampaign", dispatch: 'SET_AD_CAMPAIGN_DATA'}];
    try {
      for (const type of types) {
        const data = await adsService.getAdsDataByTypeEntityIdOptPlatform(
          entityId,
          type.type,
          platform,
        );
        dispatch({
          type: type.dispatch,
          payload: data.data?.map((value: any) => value.dataValues ?? {}),
        });
      }
    } catch (error) {
      throw error;
    }
  };
