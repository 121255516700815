import { Card, CardActionArea, Checkbox, ListItemIcon, MenuItem, Typography } from "@mui/material";
import React from "react";

const Metric = ({ details, onClick, checked }) => {
  if (details) {
    return (
      <MenuItem selected={checked} onClick={() => onClick(details)}>
        <ListItemIcon>
            <Checkbox checked={checked}/>
        </ListItemIcon>
        <Typography>{details?.name?.en}</Typography>
      </MenuItem>
    );
  } else {
    return null;
  }
};

export default Metric;
