import entities from "Api/entity/entities.ts";
import parseQueryParams from "Utils/parseQueryParams";
import sessionStorage from "Utils/sessionStorage";

const extractEntityIdFromUrl = async () => {
  const uri = window.location.href;
  const queryParams = parseQueryParams(uri);
  if (queryParams["entityId"]) {
    const entityDetails = await entities.getEntityById(queryParams["entityId"]);
    if (entityDetails) {
      sessionStorage.setSelectedEntity(entityDetails.dataValues);
    }
    return entityDetails;
  }
};

export default {
  extractEntityIdFromUrl,
};
