import { Typography } from '@mui/material'
import React from 'react'

const TopText = () => {
  return (
    <>
    <Typography
      fontWeight={700}
      sx={{ fontSize: { xs: "32px", sm: "48px" }, lineHeight: 1.1 }}
      textAlign={{ xs: "center" }}
    >
      How does it work?
    </Typography>
    <Typography
      sx={{ fontSize: { xs: "20px", sm: "28px" }, mt: 0.5, lineHeight: 1.1 }}
      fontWeight={600}
      
      textAlign={{ xs: "center" }}
    >
      We're glad you asked.
    </Typography>
  </>
  )
}

export default TopText