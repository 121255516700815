import React, { useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Card,
  Button,
  Grid,
} from "@mui/material";
import Calendar from "react-calendar"; // Calendar being used in the DateRangeSelector

const Filters = ({
  selectedMetric,
  handleMetricChange,
  handleTimeSpanChange,
  timespan,
  filters = [],
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());

  // Handler for opening the Popover
  const handleCustomClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handler for closing the Popover
  const handleCloseCustom = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "custom-popover" : undefined;

  // Handle custom date change
  const handleCustomDatesChange = (dates) => {
    setCustomStartDate(dates[0]);
    setCustomEndDate(dates[1]);
    handleTimeSpanChange({
        target: { value: { startDate: customStartDate, endDate: customEndDate } },
      });
      handleCloseCustom();
  };

  return (
    <>
      {filters.includes("metric") && (
        <FormControl variant="outlined" sx={{ mx: 3 }}>
          <Select
            size="small"
            value={selectedMetric}
            onChange={handleMetricChange}
          >
            <MenuItem value="CPC">CPC ($)</MenuItem>
            <MenuItem value="CPM">CPM ($)</MenuItem>
            <MenuItem value="CTR">CTR (%)</MenuItem>
            <MenuItem value="Impressions">Impressions</MenuItem>
            <MenuItem value="Reach">Reach</MenuItem>
            <MenuItem value="Conversions">Conversions</MenuItem>
          </Select>
        </FormControl>
      )}

      {filters.includes("timespan") && (
        <ToggleButtonGroup
          value={timespan}
          exclusive
          onChange={handleTimeSpanChange}
          aria-label="level-selector"
          size="small"
        >
          <ToggleButton value="1D">1D</ToggleButton>
          <ToggleButton value="3D">3D</ToggleButton>
          <ToggleButton value="7D">1W</ToggleButton>
          <ToggleButton value="1M">Month</ToggleButton>
          <ToggleButton value="3M">3M</ToggleButton>
            <ToggleButton value="6M">6M</ToggleButton>
          <ToggleButton value="1Y">Year</ToggleButton>
          <ToggleButton value="3Y">3Y</ToggleButton>
          <ToggleButton value="All">All</ToggleButton>
          <ToggleButton value="Custom" onClick={handleCustomClick}>
            Custom
          </ToggleButton>
        </ToggleButtonGroup>
      )}

      {/* Popover for custom date range */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseCustom}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card sx={{ p: 2, display: 'flex' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Calendar
                selectRange
                onChange={handleCustomDatesChange}
                value={[customStartDate, customEndDate]}
              />
            </Grid>
          </Grid>
        </Card>
      </Popover>
    </>
  );
};

export default Filters;
