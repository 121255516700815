import { Button } from "@mui/material";
import React from "react";

const ShopOAuthBtn = ({ text }) => {
  const handleLogin = () => {
    window.location.href = `https://apps.shopify.com/vendanza`;
  };
  return <Button onClick={handleLogin}>{text}</Button>;
};

export default ShopOAuthBtn;
