import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const formatAccounts = (accounts: any) => {
  return accounts.map((acct: any) => {
    return {
      ...acct,
      platform: "GA4",
    };
  });
};
const getProperties = async (entityId: number) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/GA4/properties?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    // Make the API call and await the response
    const response = await axios.get(uri, config);

    // Check if the response is successful
    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};
export default {
  getProperties,
};
