import {
  Alert,
  Box,
  Button,
  Card,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GoalEditor from "./GoalEditor";
import { useSelector, useDispatch } from "react-redux";
import { EmojiEventsOutlined, InfoOutlined, WarningAmberRounded } from "@mui/icons-material";
import { setMessage } from "store/actions/messageActions.ts";
import metricsService from "Api/metrics/metricsService.ts";
import Chart from "react-apexcharts";
import format from "date-fns/format";
import addCommasToFloat from "Utils/addCommasToFloat";
import { useTheme } from "@mui/material/styles";

const GoalDisplay = ({ metric }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const currentGoals = useSelector(
    (state) => state?.metrics?.metricGoals ?? []
  );
  const baseMetrics = useSelector((state) => state?.metrics?.baseMetrics);
  const [goal, setGoal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [goalProgress, setGoalProgress] = useState({});
  const [warningMessage, setWarningMessage] = useState("");
  const mapGoalToMetric = () => {
    const mappedGoal = currentGoals.find(
      (goal) => goal.dataValues?.metricSlug === metric.slug
    );
    setGoal(mappedGoal ?? null);
    if (!mappedGoal) {
      setLoading(false);
    }
  };

  useEffect(() => {
    mapGoalToMetric();
  }, [currentGoals]);

  const populateGoalProgress = async () => {
    if (goal) {
      setLoading(true);
      setError(false);
      try {
        const result =
          await metricsService.getMetricGoalProgressByEntityIdMetricSlug({
            entityId,
            metricSlug: metric.slug,
          });
        setGoalProgress(result?.data?.progress);
      } catch (err) {
        dispatch(setMessage("Something went wrong, please try again"));
        setError(true);
      }
      setLoading(false);
    }
  };

  const determineGoalCompletion = () => {
    const percentage = (goalProgress?.percentage ?? 0) * 100;
    const direction = goal?.dataValues?.direction;
    const currentDate = new Date();
    const endDate = new Date(goal?.dataValues?.endDate);

    if (percentage > 100 && direction === "GTE") return true;
    if (percentage < 100 && direction === "GTE") return false;
    if (percentage > 100 && direction === "LTE") return false;
    if (percentage < 100 && direction === "LTE" && currentDate > endDate)
      return true;
    if (percentage < 100 && direction === "LTE" && currentDate < endDate)
      return false;

    return false;
  };

  const populateWarningMessage = () => {
    const endDate = new Date(goal?.dataValues?.endDate);
    const currentDate = new Date();
    const goalCompletion = determineGoalCompletion();
    if (endDate < currentDate && goalCompletion === false) {
      setWarningMessage("This goal has expired.");
    } else {
      setWarningMessage("");
    }
  };

  const getLabel = () => {
    const goalCompletion = determineGoalCompletion();
    if (goalCompletion) {
        return "Goal Achieved!"
        }
    else {
        return `${((goalProgress?.percentage ?? 0) * 100).toFixed(2)}%`;
    }
}

  const determineColor = () => {
    const percentage = (goalProgress?.percentage ?? 0) * 100;
    const direction = goal?.dataValues?.direction;
    const endDate = new Date(goal?.dataValues?.endDate);
    const currentDate = new Date();

    if (percentage > 100 && direction === "GTE")
      return theme.palette.success.main; // Green
    if (percentage > 100 && direction === "LTE")
      return theme.palette.error.main; // Red
    if (percentage < 100 && direction === "LTE" && endDate < currentDate)
      return theme.palette.success.light; // Blue
    if (percentage < 100 && direction === "LTE" && endDate > currentDate)
      return theme.palette.success.light; // Green
    if (percentage < 100 && direction === "GTE" && endDate < currentDate) {
      return theme.palette.error.main; // Red
    }
    if (percentage < 100 && direction === "GTE")
      return theme.palette.info.light; // Blue
    return theme.palette.primary.main; // Default Primary
  };

  const options = {
    colors: [determineColor()],
    chart: {
      type: "radialBar",
    },
    series: [((goalProgress?.percentage ?? 0) * 100).toFixed(2)],
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        dataLabels: {
          value: {
            show: false,
          },
        },
      },
    },
    labels: [getLabel()],
  };

  const formatDate = (date) => {
    return format(new Date(date), "MMM d, yyyy");
  };

  useEffect(() => {
    populateGoalProgress();
  }, [goal, baseMetrics]);

  useEffect(() => {
    populateWarningMessage();
  }, [goal]);

  return (
    <Box minHeight="250px" display="flex" justifyContent='center' flexDirection="column">
      {loading && (
        <Typography textAlign="center">Loading goal data...</Typography>
      )}
      {error && (
        <Box textAlign="center">
          <Typography color="error">Error loading goal data.</Typography>
          <Button
            onClick={populateGoalProgress}
            variant="contained"
            color="primary"
          >
            Retry
          </Button>
        </Box>
      )}
      {!loading && !goal && !error && (
        <Typography textAlign="center" variant="subtitle2" fontWeight={600}>No goal set for this metric.</Typography>
      )}
      {!loading && goal && !error && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography textAlign="left" fontWeight={700}>
              {goal.dataValues?.title}
            </Typography>
            <Box display="flex" alignItems="center">
              <Tooltip title={goal.dataValues?.description}>
                <InfoOutlined />
              </Tooltip>
              {goal && (
                <Box ml={1}>
                  <GoalEditor goal={goal} metric={metric} />
                </Box>
              )}
            </Box>
          </Box>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            elevation={1}
          >
            <Box>
              <Typography variant="subtitle2" fontWeight={600}>
                {formatDate(goal.dataValues?.startDate)} -{" "}
                {formatDate(goal.dataValues?.endDate)}
              </Typography>
              {goal?.dataValues?.type === "%" && (
                <Typography variant="subtitle2" fontWeight={600}>
                  Vs. {formatDate(goal.dataValues?.compareStartDate)} -{" "}
                  {formatDate(goal.dataValues?.compareEndDate)}
                </Typography>
              )}
            </Box>
            <Box mr={3}>
              {warningMessage && (
                <Tooltip
                  variant="subtitle2"
                  fontWeight={600}
                  title={warningMessage}
                >
                  <WarningAmberRounded color="warning" />
                </Tooltip>
              )}
            </Box>
          </Paper>
          {goalProgress?.percentage && (
            <Chart options={options} series={options.series} type="radialBar" />
          )}
        </>
      )}
      {goalProgress?.currentValue && goal && (
        <Typography textAlign="center" fontWeight={600}>
          {addCommasToFloat(goalProgress.currentValue)} /{" "}
          {addCommasToFloat(goalProgress.targetValue)}
        </Typography>
      )}
      {!goal && <GoalEditor metric={metric} goal={goal} />}
    </Box>
  );
};

export default GoalDisplay;
