const handleLogin = () => {
  const appId = process.env.REACT_APP_GOOGLE_CLOUD_CONSOLE_ID;
  const redirectUriWithParams = `${process.env.REACT_APP_BASE_URL}/redirect?func=oAuthRedirect&integrationId=GA4`;
  const encodedRedirectUri = encodeURIComponent(redirectUriWithParams);
  const scope = "https://www.googleapis.com/auth/analytics.readonly";
  const encodedScope = encodeURIComponent(scope);
  const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${appId}&redirect_uri=${encodedRedirectUri}&response_type=code&scope=${encodedScope}&prompt=consent&access_type=offline`;
  window.location.href = authUrl;
};

const cacheTokenId = ({ tokenId }) => {
  try {
    sessionStorage.setItem("cachedTokenId", tokenId);
    return true;
  } catch (err) {
    throw err;
  }
};

const handleTokenRefresh = async ({ tokenId, setError }) => {
  try {
    const result = cacheTokenId({ tokenId });
    if (result) {
      const appId = process.env.REACT_APP_GOOGLE_CLOUD_CONSOLE_ID;
      const redirectUriWithParams = `${process.env.REACT_APP_BASE_URL}/redirect?func=oAuthRefresh&integrationId=GA4`;
      const encodedRedirectUri = encodeURIComponent(redirectUriWithParams);
      const scope = "https://www.googleapis.com/auth/analytics.readonly";
      const encodedScope = encodeURIComponent(scope);
      const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${appId}&redirect_uri=${encodedRedirectUri}&response_type=code&scope=${encodedScope}&prompt=consent&access_type=offline`;
      window.location.href = authUrl;
    }
  } catch (err) {
    setError(err.message);
  }
};

export default {
  handleLogin,
  handleTokenRefresh,
};
