import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ShopOAuthBtn from "./ShopOAuthBtn";
import IntegrationTitle from "Components/Integrations/IntegrationAccount/IntegrationTitle";
import shopifyLogo from "assets/ShopifyBag/png/shopify_glyph.png";
import { useSelector } from "react-redux";
const ShopSelect = () => {
  const [open, setOpen] = useState(false);
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>Connect</Button>

      <Dialog fullWidth open={open}>
        <IntegrationTitle
          title="Connect to Shopify"
          logo={shopifyLogo}
          handleClose={handleCancel}
        />
        <DialogContent>
          <Typography sx={{ mb: 1 }}>
            To continue, you will be redirected to Shopify to connect the app to
            your desired store to complete the integration.
          </Typography>
          <Typography sx={{ mb: 1 }}>
            If you've done this step previously with your desired store, you`ll
            simply need to click 'open' on the Vendanza app store page.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Once you've accepted, you'll be redirected back to this project.
          </Typography>

          {entity?.subscriptionStatus !== "free" && (
            <>
              <Alert severity="info">
                <Typography variant="body2">
                  Shopify requires that all billing for connected apps run
                  through the Shopify Billing API. Because this project is on a
                  paid plan, upon connecting your Shopify store, the current
                  recurring subscription through Stripe will be cancelled for
                  this project. Don't worry, your project data won't be
                  affected! You will then need to re-subscribe to the desired
                  paid plan through Shopify.
                </Typography>
              </Alert>
              <Alert severity="info" sx={{ mt: 2 }}>
                <Typography variant="body2">
                  You can re-subscribe by going to the 'Project Settings' tab
                  and clicking 'change plan' in the Subscriptions section.
                  Choose a plan, and Vendanza will detect the Shopify
                  integration and get you to the right place.
                </Typography>
              </Alert>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <ShopOAuthBtn text="Connect" />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShopSelect;
