import { Grid, Typography } from '@mui/material'
import CreateBoard from 'Components/CreateBoard/CreateBoard'
import AddEntity from './AddEntity/AddEntity'
import BoardMenu from 'Components/Board/components/BoardMenu/BoardMenu'

const NoEntitiesMsg = () => {
  return (
    <Grid height="50vh" container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Grid item mb={2}>
            <Typography textAlign="center" variant="h6" color="primary">
                Get started by creating a new project.
            </Typography>
            <Typography textAlign="center" variant="subtitle2" >
                You'll need to create at least one project to start.
            </Typography>
        </Grid>
        <Grid item>
            <AddEntity variant="contained" create />
        </Grid>
    </Grid>
  )
}

export default NoEntitiesMsg