import axios from "axios";
import { getAuthToken } from "Utils/data/authToken";
import sessionStorage from "Utils/sessionStorage";
const createEntity = async (entityDetails: any) => {
  try {
    const accessToken = getAuthToken();
    const currentUser = sessionStorage.getUserSessionStorage();
    const userId = currentUser.userId.S;
    const uri = `${process.env.REACT_APP_VENDANZA_API}/entity`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      entityDetails,
      userId,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const getEntityById = async (entityId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/entity/${entityId}`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result?.data;
  } catch (error) {
    throw error;
  }
};

const createUserEntityRelation = async (
  userId: string,
  entityId: string,
  role: string
) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/employee`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      entityId,
      userId,
      role,
    };
    const result = await axios.post(uri, body, config);
    return result?.data?.Item;
  } catch (error) {
    throw error;
  }
};

const getEntitiesByUser = async () => {
  try {
    const accessToken = getAuthToken();
    const currentUser = sessionStorage.getUserSessionStorage();
    if (currentUser) {
      const userId = currentUser?.userId?.S;

      const uri = `${process.env.REACT_APP_VENDANZA_API}/entity/user/${userId}`;
      const config = {
        headers: {
          role: "agent",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const result = await axios.get(uri, config);
      return result.data;
    }
  } catch (error) {
    throw error;
  }
};

const getEntityRoles = async (entityId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/entity/${entityId}/roles`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getUsersWithRole = async (roleId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/employee/role/${roleId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default {
  createEntity,
  createUserEntityRelation,
  getEntityById,
  getEntitiesByUser,
  getEntityRoles,
  getUsersWithRole,
};
