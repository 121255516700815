const initialState = {
    selectedMetrics: [],
    metricName: "",
    graphType: "line",
    operation: [],
    filters: {
        platforms: [],
        regions: [],
        customerTypes: [],
        orderTypes: [], 
        repurchase: null,
        newCustomer: null,
        orderStatus: [],
        shippingMethods: [] , // Filter for shipping methods (e.g., standard, expedited)
        productCategories: [],// Filter for product categories (e.g., electronics, clothing)
        orderStatuses: [],    // Filter for order statuses (e.g., pending, shipped, delivered)
        promotions: [],      // Filter for promotions used (e.g., coupon code, discount)
        salesChannels: [],   // Filter for sales channels (e.g., online store, offline store)
        discounts: [],       // Filter for discounts applied (e.g., 10% off, $5 off)
    },
};
  
  const graphReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_SELECTED_METRICS":
            return {
            ...state,
            selectedMetrics: action.payload,
            };
        case "SET_METRIC_NAME":
            return {
            ...state,
            metricName: action.payload,
            };
        case "SET_FILTERS":
            return {
            ...state,
            filters: action.payload,
            };
        case "SET_GRAPH_TYPE":
            return {
            ...state,
            graphType: action.payload,
            };
        case "SET_OPERATION":
            return {
            ...state,
            operation: action.payload,
            };
      default:
        return state;
    }
  };
  
  export default graphReducer;
  