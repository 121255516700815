import { Container, Divider, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import Billing from "./components/Billing/Billing";
import Account from "./components/Account/Account";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const getComponentByTab = () => {
    switch (selectedTab) {
      case 0:
        return <Account />;
      case 1:
        return <Billing />;
      default:
        return <></>;
    }
  };
  const tabs = [
    {
      index: 0,
      name: "Account",
    },
    {
      index: 1,
      name: "Payment History",
    },
  ];
  return (
    <Container
      disableGutters
      sx={{
        p: 1,
      }}
    >
      <Typography variant="h4" sx={{ mb: 1 }}>
        Account Settings
      </Typography>
      <Divider fullWidth />
      <Tabs value={selectedTab}>
        {tabs.map((tab) => {
          return (
            <Tab label={tab.name} onClick={() => setSelectedTab(tab.index)} />
          );
        })}
      </Tabs>
      {getComponentByTab()}
    </Container>
  );
};
export default Settings;
