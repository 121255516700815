const FREE_PLAN = 'free';
const CORE_PLAN = 'Core';
const PRO_PLAN = 'Pro';
const MAX_FREE_PROJECTS = 2;
const FREE_ORDER_LIMIT = 500;
const CORE_ORDER_LIMIT = 2500;
const PRO_ORDER_LIMIT = 15000;

export default {
    FREE_PLAN,
    CORE_PLAN,
    PRO_PLAN,
    MAX_FREE_PROJECTS,
    FREE_ORDER_LIMIT,
    CORE_ORDER_LIMIT,
    PRO_ORDER_LIMIT,
}