import { Close, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteBoardBtn from "./DeleteBoardBtn";
import { useDispatch } from "react-redux";
import { getBoards } from "store/actions/boardActions.ts";
import { useSelector } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";
import boardService from "services/boardService.ts";
import Metric from "./Metric";
import ButtonLoader from "Components/ButtonLoader";

const BoardMenu = ({ details, currentMetrics, create, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  const dispatch = useDispatch();
  const [boardName, setBoardName] = useState(details?.name);
  const [boardDescription, setBoardDescription] = useState(
    details?.description
  );
  const baseMetrics = useSelector(
    (state) => state.metrics.baseMetrics.primaryMetrics
  );
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);

  const createBoard = async () => {
    let view = "dashboard";
    if (window.location.href.includes("ads-management")) {
      view = "ads-management";
    }
    try {
      setLoading(true);
      console.log(view);
      const newBoard = await boardService.createBoard({
        entityId,
        name: boardName,
        description: boardDescription,
        view,
      });
      const metricNames = selectedMetrics.map((metric) => metric.name?.en);
      await boardService.updateBoardMetrics(
        newBoard?.data?.dataValues?.boardId,
        metricNames
      );
      setOpen(false);
    } catch (error) {
      dispatch(setMessage("Failed to create board - please try again"));
    } finally {
      dispatch(getBoards(entityId, view));
      setLoading(false);
      await onUpdate();
    }
  };

  const updateBoard = async () => {
    try {
      setLoading(true);
      const updatedBoard = await boardService.updateBoard(
        details.boardId,
        boardName,
        boardDescription
      );
      const metricNames = selectedMetrics.map((metric) => metric.name?.en);
      await boardService.updateBoardMetrics(details.boardId, metricNames);
      await onUpdate();
      setOpen(false);
    } catch (error) {
      dispatch(setMessage("Failed to save changes - please try again"));
    } finally {
      dispatch(getBoards(entityId));
      setLoading(false);
    }
  };

  const saveChanges = async () => {
    if (create) {
      await createBoard();
    } else {
      await updateBoard();
    }
  };

  const handleSelectMetric = (metric) => {
    // Check if the metric is already in the selectedMetrics array
    const foundMetric = selectedMetrics?.some(
      (selectedMetric) => selectedMetric.name?.en === metric?.name?.en
    );
    if (foundMetric) {
      // If it's already selected, remove it
      const updatedMetrics = selectedMetrics.filter(
        (m) => m?.name?.en !== metric?.name?.en
      );
      setSelectedMetrics(updatedMetrics);
    } else {
      // If it's not selected, add it
      setSelectedMetrics([...selectedMetrics, metric]);
    }
  };

  const populateCheckedMetric = (metric) => {
    // Check if the metric's name is in the selectedMetrics array
    return selectedMetrics?.some(
      (selectedMetric) => selectedMetric.name?.en === metric?.name?.en
    );
  };

  useEffect(() => {
    setSelectedMetrics(currentMetrics ?? []);
  }, [currentMetrics]);

  return (
    <>
      {create && (
        <Button
          fullWidth
          variant="contained"
          onClick={() => setOpen(true)}
          sx={{ mt: 2, mb: 2 }}
        >
          Add Board
        </Button>
      )}
      {!create && (
        <IconButton onClick={() => setOpen(true)}>
          <MoreHoriz />
        </IconButton>
      )}
      <Dialog open={open} fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">
            {create ? "New Board" : details.name}
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2, mt: 1 }}
            fullWidth
            placeholder="Name"
            label="Name"
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
          />
          <TextField
            fullWidth
            placeholder="Description"
            label="Description (optional)"
            onChange={(e) => setBoardDescription(e.target.value)}
            multiline
            minRows={2}
            value={boardDescription}
          />

          <Typography variant="subtitle2" sx={{ mt: 3, mb: 1 }}>
            Select which metric(s) to include
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              maxHeight: "200px",
            }}
          >
            {baseMetrics?.map((metric) => {
              return (
                <Metric
                  key={metric?.slug}
                  onClick={handleSelectMetric}
                  checked={populateCheckedMetric(metric)}
                  details={metric}
                />
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: create ? "flex-end" : "space-between",
            alignItems: "center",
            p: 3,
          }}
        >
          {!create && (
            <DeleteBoardBtn details={details} onDelete={() => setOpen(false)} />
          )}
          <Button
            variant="contained"
            disabled={!boardName || loading}
            onClick={saveChanges}
          >
            <ButtonLoader
              text={create ? "Create Board" : "Save Changes"}
              loading={loading}
              size={20}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BoardMenu;
