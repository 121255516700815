import { Close } from '@mui/icons-material'
import { Box, DialogTitle, IconButton, Typography } from '@mui/material'

const IntegrationTitle = ({handleClose, title, logo}) => {
  return (
    <DialogTitle>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2, mt:1 }}
    >
      <Box display="flex" alignItems="center">
        <Box
          component="img"
          mr={2}
          sx={{ width: "50px", height: "50px" }}
          src={logo}
        />
        <Typography variant="h5">{title}</Typography>
      </Box>
      <IconButton onClick={handleClose}>
        <Close />
      </IconButton>
    </Box>
  </DialogTitle>
  )
}

export default IntegrationTitle