import { Typography } from "@mui/material";

const ShortText = ({ text, maxLen, variant }) => {
  return (
    <Typography
        variant={variant}
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {text && text > maxLen ? `${text.slice(0, maxLen)}...` : text}
    </Typography>
  );
};

export default ShortText;
