import FacebookConfig from "./FacebookConfig";
import facebookUtils from "./facebookUtils";
import ConnectActionBtn from "Components/Integrations/ConnectActionBtn/ConnectActionBtn";

const Action = ({ accounts, onUpdate, credsPresent }) => {
  if (accounts?.length || credsPresent) {
    return <FacebookConfig onUpdate={onUpdate} />;
  }
  return (
    <ConnectActionBtn onContinue={() => facebookUtils.handleLogin()}>
      Connect
    </ConnectActionBtn>
  );
};

export default Action;
