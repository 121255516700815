import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const unprotectedPaths = ["/register"];

  return (
    <Route
      {...rest}
      render={(props) => {
          if (unprotectedPaths.includes(window.location.pathname)) {
            return (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return <Component {...rest} {...props} />;
          }
        }
      }
    />
  );
};

export default ProtectedRoute;
