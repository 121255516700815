const parseQueryParams = (url) => {
    const parameters = {};
    const queryString = url.split('?')[1];
  
    if (queryString) {
      const parameterPairs = queryString.split('&');
  
      for (let i = 0; i < parameterPairs.length; i++) {
        const pair = parameterPairs[i].split('=');
        const paramName = decodeURIComponent(pair[0]);
        const paramValue = decodeURIComponent(pair[1]);
  
        if (parameters.hasOwnProperty(paramName)) {
          if (Array.isArray(parameters[paramName])) {
            parameters[paramName].push(paramValue);
          } else {
            parameters[paramName] = [parameters[paramName], paramValue];
          }
        } else {
          parameters[paramName] = paramValue;
        }
      }
    }
  
    return parameters;
  };

  export default parseQueryParams;


  