import { Delete, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import oAuthService from "services/oAuthService.ts";
import DeleteAccount from "Components/Integrations/DeleteAccount/DeleteAccount";
import GA4Property from "./GA4Property";
import InactiveToken from "./InactiveToken";

const GA4AccountGroup = ({
  acctDetails,
  onDelete,
  idx,
  storedAccounts,
  onUpdate,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [activeToken, setActiveToken] = useState(false);
  const handleDeleteIntegration = async () => {
    try {
      setShowDelete(false);
      setDeleting(true);
      const result = await oAuthService.deleteMultipleAccountsAndData(
        acctDetails.tokenId
      );
      if (result.data?.success) {
        await onDelete();
      }
    } catch (err) {}
  };

  useEffect(() => {
    setActiveToken(acctDetails.activeToken);
  }, [acctDetails]);

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            {!activeToken && <Warning color="warning" sx={{ mr: 1 }} />}
            {acctDetails?.integrationName ?? `Account ${idx + 1}`}
          </Box>
          <IconButton disabled={deleting} onClick={() => setShowDelete(true)}>
            <Delete />
          </IconButton>
        </Box>
        {deleting && (
          <Box display="flex" alignItems="center">
            <CircularProgress
              variant="indeterminate"
              size={12}
              sx={{ mr: 1 }}
            />
            <Typography variant="subtitle2">
              Disconnecting Integration...
            </Typography>
          </Box>
        )}
        {!activeToken && <InactiveToken tokenId={acctDetails.tokenId} />}
        {acctDetails?.accounts?.length === 0 && (
          <Alert severity="warning" sx={{ alignItems: "center" }}>
            We couldn't find any GA4 accounts associated with this Google
            account. Check your Google Console for permissions, or reach out to
            your Google Console Administrator for help.
          </Alert>
        )}
        {acctDetails.accounts?.map((acct) => {
          return (
            < >
              <Box display="flex" alignItems="center" key={acct.id}>
                <Typography>{acct.acctName}</Typography>
              </Box>
              <List>
                {acct?.properties?.map((property) => {
                  return (
                    <GA4Property
                      property={property}
                      tokenId={acctDetails.tokenId}
                      storedAccounts={storedAccounts}
                      onUpdate={onUpdate}
                    />
                  );
                })}
              </List>
            </>
          );
        })}
      </CardContent>
      <DeleteAccount
        plural
        warnDelete={showDelete}
        setWarnDelete={setShowDelete}
        loading={deleting}
        onDelete={handleDeleteIntegration}
      />
    </Card>
  );
};

export default GA4AccountGroup;
