import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Button,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { UploadOutlined } from "@mui/icons-material";

import facebookLogo from "assets/Facebook Brand Asset Pack 2019/f-Logos-2019-1/f_Logo_Online_04_2019/Color/PNG/f_logo_RGB-Blue_512.png";

// Styling for hiding scrollbar and allowing horizontal scrolling
const ScrollableTableContainer = styled(TableContainer)`
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`;

const AdTable = ({ adsData, level, view }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Sorting handler
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const platformLogo = {
    facebook: facebookLogo,
  };

  // Sorting function
  const sortedAdsData = React.useMemo(() => {
    if (!sortConfig.key) return adsData;

    return [...adsData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [adsData, sortConfig]);

  const getHeaderData = (level) => {
    if (level === "campaign") {
      return [
        { key: "campaignName", label: "Campaign Name" },
        { key: "platform", label: "Platform" },
        { key: "clicks", label: "Clicks" },
        { key: "spend", label: "Spend($)" },
        { key: "impressions", label: "Impressions" },
        { key: "cpc", label: "CPC($)" },
        { key: "ctr", label: "CTR(%)" },
        { key: "reach", label: "Reach" },
        { key: "objective", label: "Objective" },
        { key: "accountId", label: "Account ID" },
      ];
    }
    if (level === "adSet") {
      return [
        { key: "adSetName", label: "Ad Set Name" },
        { key: "platform", label: "Platform" },
        { key: "campaignName", label: "Campaign Name" },
        { key: "clicks", label: "Clicks" },
        { key: "spend", label: "Spend($)" },
        { key: "impressions", label: "Impressions" },
        { key: "cpc", label: "CPC($)" },
        { key: "ctr", label: "CTR(%)" },
        { key: "reach", label: "Reach" },
        { key: "objective", label: "Objective" },
        { key: "accountId", label: "Account ID" },
      ];
    }
    return [
      { key: "adName", label: "Ad Name" },
      { key: "platform", label: "Platform" },
      { key: "campaignName", label: "Campaign Name" },
      { key: "adSetName", label: "Ad Set Name" },
      { key: "clicks", label: "Clicks" },
      { key: "spend", label: "Spend($)" },
      { key: "impressions", label: "Impressions" },
      { key: "cpc", label: "CPC($)" },
      { key: "ctr", label: "CTR(%)" },
      { key: "reach", label: "Reach" },
      { key: "objective", label: "Objective" },
      { key: "accountId", label: "Account ID" },
    ];
  };

  const getLevelName = (item) => {
    if (level === "campaign") return item.campaignName;
    if (level === "adSet") return item.adSetName;
    return item.adName;
  };
  const titles = {
    campaign: "Campaigns",
    adSet: "Ad Sets",
    ad: "Ads",
  };

  // Function to generate and download CSV
  const exportToCSV = () => {
    const headers = getHeaderData(level).map((header) => header.label);
    const rows = sortedAdsData.map((ad) => [
      getLevelName(ad),
      ad.platform,
      level === "ad" ? ad.campaignName : "",
      level === "ad" ? ad.adSetName : "",
      ad.clicks,
      ad.spend,
      ad.impressions,
      ad.cpc,
      ad.ctr,
      ad.reach,
      ad.objective,
      ad.accountId,
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ads_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!adsData) return null;

  return (
    <>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {view === "all" ? (
          <Typography variant="h6">{titles[level]}</Typography>
        ) : (
          <Box />
        )}
        <Tooltip title="Export CSV">
          <IconButton variant="contained" onClick={exportToCSV}>
            <UploadOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <ScrollableTableContainer component={Paper} sx={{ mb: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="ad performance table">
          <TableHead>
            <TableRow>
              {getHeaderData(level).map((header) => (
                <TableCell key={header.key} align="left">
                  <TableSortLabel
                    active={sortConfig.key === header.key}
                    direction={sortConfig.direction}
                    onClick={() => handleSort(header.key)}
                  >
                    <Typography fontWeight={600} variant="p">
                      {header.label}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAdsData?.map((ad) => (
              <TableRow
                key={ad.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {getLevelName(ad)}
                </TableCell>
                <TableCell align="left">
                  <Box
                    component="img"
                    src={platformLogo[ad.platform]}
                    sx={{ width: 20, height: 20 }}
                  />
                </TableCell>
                {level === "ad" && (
                  <>
                    <TableCell align="left">{ad.campaignName}</TableCell>
                    <TableCell align="left">{ad.adSetName}</TableCell>
                  </>
                )}
                {level === "ad" ||
                  (level === "adSet" && (
                    <TableCell align="left">{ad.campaignName}</TableCell>
                  ))}
                <TableCell align="left">{ad.clicks}</TableCell>
                <TableCell align="left">{ad.spend}</TableCell>
                <TableCell align="left">{ad.impressions}</TableCell>
                <TableCell align="left">{ad.cpc}</TableCell>
                <TableCell align="left">{ad.ctr}</TableCell>
                <TableCell align="left">{ad.reach}</TableCell>
                <TableCell align="left">{ad.objective}</TableCell>
                <TableCell align="left">{ad.accountId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollableTableContainer>
    </>
  );
};

export default AdTable;
