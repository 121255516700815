import marketingUtils from "Utils/marketingUtils";
import paymentsService from "services/paymentsService.ts";

export const setShowUpgradeModal = (showModal: any) => ({
  type: "SET_SHOW_UPGRADE_MODAL",
  payload: false,
});

export const setUpgradeModalTitle = (title: string) => ({
  type: "SET_MODAL_TITLE",
  payload: title,
});

export const setUpgradeModalSubtitle = (subtitle: string) => ({
  type: "SET_MODAL_SUBTITLE",
  payload: subtitle,
});
export const setUpgradeModalMessage = (message: string) => ({
  type: "SET_MODAL_MESSAGE",
  payload: message,
});

export const setDisableClose = (disable: boolean) => ({
  type: "SET_DISABLE_CLOSE",
  payload: disable,
});

export const checkMonthOrderCount = (entity: any) => async (dispatch: any) => {
  try {
    const monthOrderLimit = marketingUtils.getMonthlyOrderLimit(
      entity?.subscriptionStatus
    );
    const monthOrderCount = await paymentsService.checkOrderCount(entity?.id);
    const orderCount = monthOrderCount.data;
    if (orderCount >= monthOrderLimit) {
      dispatch(setDisableClose(true));
      dispatch(setUpgradeModalTitle("Monthly Order Limit Reached!"));
      dispatch(
        setUpgradeModalSubtitle(
          `Congratulations, you've reached the monthly order limit of ${monthOrderLimit} orders for the ${marketingUtils.mapSubscriptionStatusToName(
            entity.subscriptionStatus
          )} plan.`
        )
      );
      dispatch(
        setUpgradeModalMessage(
          `You'll need to upgrade to a plan with a higher order limit to continue.`
        )
      );
      dispatch(setShowUpgradeModal(true));
    }
  } catch (error) {
    throw error;
  }
};
