import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useEffect, useState } from "react";
import {
  Delete,
  EditAttributes,
  EditAttributesOutlined,
  Sync,
  ToggleOffOutlined,
  ToggleOnOutlined,
  UpdateOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";
import sessionStorage from "Utils/sessionStorage";
import { useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";

const GroupedAccountMenu = ({
  setWarnDelete,
  account,
  active,
  setActive,
  onUpdate,
  apiKeyOnly,
}) => {
  const entityId = useSelector((state) => state.entity?.selectedEntity?.id);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleOAuthRefresh = async () => {
    try {
      let url;
      let redirectUrl;
      switch (account.platform) {
        case "amazon":
          redirectUrl = "";
          url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${process.env.REACT_APP_AMAZON_CLIENT_ID}&version=beta`;
          break;
        case "shopify":
          url = "https://apps.shopify.com/vendanza";
          break;
        case "facebook":
          const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
          // Define the required Facebook permissions
          const scope = "ads_management";
          // Define the desired redirect URL after successful login
          const redirectUriWithParams = `${process.env.REACT_APP_BASE_URL}/redirect?func=oAuthRedirect&integrationId=FACEBOOK`;

          // Encode the redirect URI to include in the login URL
          const encodedRedirectUri = encodeURIComponent(redirectUriWithParams);

          // Construct the Facebook login URL
          url = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${appId}&redirect_uri=${encodedRedirectUri}&scope=${scope}`;
          break;
        default:
          throw new Error("Platform not supported");
      }
      sessionStorage.setOAuthRefreshData({
        platform: account.platform,
        entityId,
        accountId: account.accountId,
        tokenId: account.tokenId,
      });
      window.location.href = url;
    } catch (err) {
      dispatch(setMessage(err.message));
    }
  };

  const menuItems = [
    {
      text: "Refresh Credentials",
      icon: <UpdateOutlined />,
      onClick: () => {
        handleOAuthRefresh();
      },
    },
    {
      text: "Delete Account",
      icon: <Delete />,
      onClick: () => setWarnDelete(true),
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map(
          (item, index) =>
            !item.hidden && (
              <MenuItem key={index} onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};

export default GroupedAccountMenu;
