const addCommasToFloat = (number, currency = false) => {
  if (number === null || number === undefined || isNaN(number)) {
    return number;
  }
  const numberParts = number.toString().split(".");
  const integerPart = numberParts[0];
  const decimalPart =
    numberParts[1] ? `.${numberParts[1]}` : currency ? ".00" : "";
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${integerWithCommas}${decimalPart}`;
};

export default addCommasToFloat;
