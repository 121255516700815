import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import boardService from "Api/boards/boardService.ts";
import ButtonLoader from "Components/ButtonLoader";
import { bool } from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBoards } from "store/actions/boardActions.ts";
import { setMessage } from "store/actions/messageActions.ts";
const DeleteBoardBtn = ({ details, onDelete }) => {
  const dispatch = useDispatch();
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const deleteBoard = async () => {
    try {
      setLoading(true);
      await boardService.deleteBoard(details.boardId);
      dispatch(setMessage("Successfully deleted board"));
      onDelete();
    } catch (error) {
      dispatch(setMessage("Successfully deleted board"));
    } finally {
      dispatch(getBoards(entityId));
      setLoading(false);
      setShow(false);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Button color="secondary" onClick={() => setShow(true)}>
        Delete Board
      </Button>
      <Dialog fullWidth open={show}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography>Delete Board</Typography>
            <Typography variant="subtitle2">
              Are you sure you want to delete "{details.name}"?
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteBoard}>
            <ButtonLoader size={20} text="Delete" loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteBoardBtn.propTypes = {
  icon: bool,
};

DeleteBoardBtn.defaultProps = {
  icon: false,
};

export default DeleteBoardBtn;
