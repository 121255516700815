const initialState = {
  showMenu: true,
  currentTab: null,
  accountNavIsVisible: true,
  drawerWidth: 240,
};

const navigationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SHOW_MENU":
      return {
        ...state,
        showMenu: action.payload,
      };
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentTab: action.payload,
      };
    case "SET_ACCOUNT_NAVIGATION":
      return {
        ...state,
        accountNavIsVisible: action.payload,
      };
    case "SET_NAV_MENU_SIZE":
      return {
        ...state,
        drawerWidth: action.payload,
      };
    default:
      return state;
  }
};

export default navigationReducer;
