import { Button, Typography } from "@mui/material";
import { bool } from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const CTA = ({hideSubText}) => {
  const history = useHistory();
  return (
    <>
      <Button
        onClick={() => history.push("/register")}
        variant="contained"
        size="large"
        sx={{px:6}}
      >
        Sign up for free
      </Button>
      {!hideSubText && <Typography
        variant="subtitle2"
        fontSize={12}
        textAlign="center"
        mt={1}
        fontWeight={600}
      >
        No credit card required.
      </Typography>}
    </>
  );
};

CTA.propTypes = {
  hideSubText: bool
}

CTA.defaultProps = {
  hideSubText: false
}
export default CTA;
