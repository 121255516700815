const initialState = {
  showUpgradeModal: false,
  disableClose: false,
  modalTitle: "",
  modalSubtitle: "",
  modalMessage: "",
};

const marketingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_MODAL_TITLE":
      return {
        ...state,
        modalTitle: action.payload,
      };
    case "SET_MODAL_SUBTITLE":
      return {
        ...state,
        modalSubtitle: action.payload,
      };
    case "SET_MODAL_MESSAGE":
      return {
        ...state,
        modalMessage: action.payload,
      };
    case "SET_SHOW_UPGRADE_MODAL":
      return {
        ...state,
        showUpgradeModal: action.payload,
      };
    case "SET_DISABLE_CLOSE":
      return {
        ...state,
        disableClose: action.payload,
      };

    default:
      return state;
  }
};

export default marketingReducer;
