import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  TextField,
  Box,
} from "@mui/material";
import IntegrationTitle from "../IntegrationAccount/IntegrationTitle";
import { useSelector } from "react-redux";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";
import { capitalize } from "Utils/capitalizeString";
import ButtonLoader from "Components/ButtonLoader";
import SingleAccount from "../SingleAccount/SingleAccount";
import ConnectActionBtn from "../ConnectActionBtn/ConnectActionBtn";
import { useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";

const ApiKeyAccount = ({
  logo,
  title,
  platform,
  helpLink,
  accounts,
  setAccounts,
}) => {
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [APIKeyName, setAPIKeyName] = useState("");
  const [APIKey, setAPIKey] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const entityId = useSelector((state) => state.entity.selectedEntity.id);
  const fetchAccounts = async () => {
    try {
      setLoading(true);
      const result =
        await thirdPartyAccountService.getStoredAccountsByEntityIdPlatform(
          entityId,
          platform
        );
      setAccounts(result?.data);
    } catch (error) {
      dispatch(setMessage(`Error fetching accounts: ${error.message}`));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  }, [accounts]);

  const triggerHistoricalSync = async ({ entityId, platform, accountId }) => {
    try {
      await thirdPartyAccountService.triggerHistoricalSync({
        entityId,
        platform,
        accountId,
      });
      dispatch(
        setMessage(`${capitalize(platform)} data synchronization started.`)
      );
    } catch (err) {
      dispatch(setMessage(err.message));
    }
  };

  const handleCancel = () => {
    setShowNewAccount(false);
    setAPIKeyName("");
    setAPIKey("");
  };

  const createThirdPartyAccount = async () => {
    try {
      setLoading(true);
      const result = await thirdPartyAccountService.addThirdPartyAccount({
        entityId,
        platform,
        accountId: APIKey,
        additionalPayload: { name: APIKeyName },
      });
      if (result?.action === "created") {
        dispatch(
          setMessage("API key added! Data synchronization will begin shortly.")
        );
        triggerHistoricalSync({ entityId, platform, accountId: APIKey });
        setShowNewAccount(false);
        setAPIKeyName("");
        setAPIKey("");
      } else {
        dispatch(
          setMessage(
            "Duplicate API key found for this project and platform. No action taken."
          )
        );
      }
    } catch (error) {
      dispatch(
        setMessage(
          `Error creating account: ${
            error?.response?.data?.errors[0]?.message ?? error?.message
          }`
        )
      );
    } finally {
      setLoading(false);
      await fetchAccounts();
    }
  };

  const handleDeleteSuccess = async () => {
    try {
      await fetchAccounts();
    } catch (error) {
      dispatch(setMessage(error.message));
    }
  };

  const getAccountName = (account, idx) => {
    const name = account?.custom
      ? JSON.parse(account.custom)?.name
      : `${account?.platform} Account ${idx + 1}`;
    return name;
  };

  const [open, setOpen] = useState(false);
  return (
    <>
      <ConnectActionBtn
        accounts={accounts}
        onContinue={() => setOpen(true)}
        text={accounts.length ? "Configure" : "Connect"}
      ></ConnectActionBtn>
      <Dialog open={open} fullWidth>
        <IntegrationTitle
          title={title}
          handleClose={() => {
            handleCancel();
            setOpen(false);
          }}
          logo={logo}
        />
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            transition: "height 0.3s ease-in-out",
            flexDirection: "column",
          }}
        >
          {helpLink && (
            <Typography>
              need help? check out this article for tips on managing your{" "}
              {platform} api keys: {helpLink}
            </Typography>
          )}
          {!accounts?.length && !showNewAccount && (
            <Typography>
              You don't have any connected {capitalize(platform)} accounts.
            </Typography>
          )}
          {accounts?.map((account, idx) => {
            return (
              <SingleAccount
                isAPIKeyOnly
                setMessage={setMessage}
                account={account}
                primaryText={getAccountName(account, idx)}
                onDeleteSuccess={handleDeleteSuccess}
                onUpdate={() => fetchAccounts()}
              />
            );
          })}
          {showNewAccount && (
            <Box width="100%" display="flex" flexDirection="column" mt={3}>
              <TextField
                fullWidth
                label="API Key Name"
                sx={{ mb: 3 }}
                value={APIKeyName}
                onChange={(e) => setAPIKeyName(e.target.value)}
              />
              <TextField
                fullWidth
                label="API Key"
                onChange={(e) => setAPIKey(e.target.value)}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button sx={{ mr: 2 }} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  disabled={loading || !APIKey || !APIKeyName}
                  onClick={createThirdPartyAccount}
                >
                  <ButtonLoader loading={loading} size={20} text="Save" />
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "flex-start", p: 3 }}
        >
          <Button onClick={() => setShowNewAccount(true)}>
            Add {capitalize(platform)} Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApiKeyAccount;
