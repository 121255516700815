import thirdPartyAccountService from "services/thirdPartyAccountService.ts";

const updateAccountActive = async ({
  e,
  setLoading,
  setActive,
  setMessage,
  dispatch,
  account,
  entityId,
  onUpdate,
}) => {
  setLoading(true);
  const active = e.target.checked;
  try {
    const result = await thirdPartyAccountService.updateAccountActive({
      accountId: account.accountId,
      tokenId: account.tokenId,
      entityId,
      platform: account.platform,
      active,
    });
    if (result.data.success) {
      setActive(
        result.data?.updates?.activeStatusUpdate?.data?.dataValues?.active
      );
    }
    if (onUpdate) {
      await onUpdate();
    }
    dispatch(
      setMessage(
        active
          ? "Integration activated, resuming data synchronization."
          : "Integration deactivated."
      )
    );
  } catch (error) {
    console.error(error);
    dispatch(setMessage(error.message));
  } finally {
    setLoading(false);
  }
};

export default {
  updateAccountActive,
};
