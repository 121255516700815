import { Container, Typography } from '@mui/material'
import React from 'react'

const FAQ = () => {
  return (
    <Container sx={{mt:6, height: '75vh'}}>
      <Typography>Frequently Asked Questions</Typography>
    </Container>
  )
}

export default FAQ