import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBoards } from "store/actions/boardActions.ts";
import AnalyticsHeader from "views/Analytics/Dashboard/Components/Displays/AnalyticsHeader";
import Board from "Components/Board/Board";
import { getAdvertisingData } from "store/actions/advertisingActions.ts";
import AdTable from "./components/AdDataTable";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Container,
  Grid,
  Card,
  Paper,
} from "@mui/material";
import AdDataGraph from "./components/AdDataGraph";
import Filters from "./components/Filters";

const AdMgt = () => {
  const dispatch = useDispatch();
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const boards = useSelector((state) => state?.boards?.boards);
  const adsData = useSelector((state) => state?.ads?.adsData);
  const adSetData = useSelector((state) => state?.ads?.adSetData);
  const adCampaignData = useSelector((state) => state?.ads?.adCampaignData);

  const [view, setView] = useState("campaign"); // State to control selected view

  useEffect(() => {
    if (entityId) {
      dispatch(getBoards(entityId, "ads-management"));
      dispatch(getAdvertisingData(entityId));
    }
  }, [entityId, dispatch]);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView); // Update view when a new option is selected
    }
  };

  return (
    <Grid container disableGutters display="flex" flexDirection="column">
      <Typography variant="h6" sx={{ mb: 3 }}>
        Marketing
      </Typography>

      <Grid item xs={12} sm={3} mb={2}>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="ad-management-selector"
          sx={{ marginBottom: 2 }}
          size="small"
        >
          <ToggleButton value="all" aria-label="ads-view">
            All
          </ToggleButton>
          <ToggleButton value="campaign" aria-label="campaign-view">
            Campaigns
          </ToggleButton>
          <ToggleButton value="adSet" aria-label="adSet-view">
            Ad Sets
          </ToggleButton>
          <ToggleButton value="ad" aria-label="ads-view">
            Ads
          </ToggleButton>
        </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={9}>
        </Grid>
          <Grid item xs={12}>
        <Paper sx={{p:2}}>
        <Filters
          filters={["metric", "timespan"]}
          metricGroups={["marketing"]}
        />
        <AdDataGraph adsData={adsData} />
        </Paper>
        </Grid>

      <Box mb={2}>
        {/* Render the table based on the selected view */}
        {view === "campaign" && (
          <>
            {!adCampaignData.length ? (
              <Typography variant="h6">No Campaigns found</Typography>
            ) : (
              <AdTable level="campaign" adsData={adCampaignData} />
            )}
          </>
        )}
        {view === "adSet" && (
          <>
            {!adSetData.length ? (
              <Typography variant="h6">No Ad Sets found</Typography>
            ) : (
              <AdTable level="adSet" adsData={adSetData} />
            )}
          </>
        )}
        {view === "ad" && (
          <>
            {!adsData.length ? (
              <Typography variant="h6">No Ads found</Typography>
            ) : (
              <AdTable level="ad" adsData={adsData} />
            )}
          </>
        )}
        {view === "all" && (
          <>
            <AdTable level="campaign" adsData={adCampaignData} view={view} />
            <AdTable level="adSet" adsData={adSetData} view={view} />
            <AdTable level="ad" adsData={adsData} view={view} />
          </>
        )}
      </Box>
    </Grid>
  );
};

export default AdMgt;
