import { useState } from "react";
import RegisterInput from "./Components/RegisterInput/RegisterInput";

const Register = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  return (
        <RegisterInput
          email={email}
          setEmail={setEmail}
          setEmailSent={setEmailSent}
          setConfirmPassword={setPassword}
          setFirstName={setFirstName}
          setLastName={setLastName}
          firstName={firstName}
          lastName={lastName}
        />
  );
};

export default Register;
