import integrationService from "Api/integration/integration.ts";
import sessionStorage from "Utils/sessionStorage";
import facebookService from "services/facebookService.ts";
import paymentsService from "services/paymentsService.ts";
import shopifyService from "services/shopifyService.ts";
import subscriptionUtils from "Utils/subscriptionUtils";

const redirectToIntegration = async ({ history }) => {
  const selectedEntity = await sessionStorage.getSelectedEntity();
  const currentUser = await sessionStorage.getAuthStorage();

  const redirectPath = selectedEntity
    ? `entity/analytics/integrations?entityId=${selectedEntity.id}`
    : currentUser
    ? "dashboard"
    : "/login";

  history.push(redirectPath);
};

const redirectToDashboard = async ({ history }) => {
  const selectedEntity = await sessionStorage.getSelectedEntity();
  const currentUser = await sessionStorage.getAuthStorage();
  const redirectPath = selectedEntity
    ? `entity/analytics/dashboard?entityId=${selectedEntity.id}`
    : currentUser
    ? "dashboard"
    : "/login";
  history.push(redirectPath);
};

const handleOAuthRefresh = async ({ setError, params, history }) => {
  const integration = params.integrationId;
  const tokenId = sessionStorage.getCachedTokenId();
  let code;
  let result;
  try {
    switch (integration) {
      case "GA4":
        code = params.code;
        result = await integrationService.handleOAuthRefresh({
          code,
          tokenId,
          platform: integration,
        });
        break;
      default:
        throw new Error("Could not identify integration to refresh");
    }
    if (result?.data?.success) {
      redirectToIntegration({ history });
    }
  } catch (err) {
    setError(err?.response?.data ?? err.message);
  }
};

const handleOAuthRedirect = async ({ params, setMessage, dispatch }) => {
  const selectedEntity = await sessionStorage.getSelectedEntity();
  const integration = params.integrationId;
  let code;
  let result;
  try {
    switch (integration) {
      case "SHOPIFY":
        code = params.code;
        const shopUri = params.shop;
        result = await shopifyService.getOAuthToken(
          selectedEntity?.id,
          shopUri,
          code
        );
        if (
          result?.data?.success &&
          selectedEntity?.subscriptionStatus !== "free"
        ) {
          const { provider } = await subscriptionUtils.determineProvider(
            selectedEntity?.id
          );
          // cancel current subscription and prompt to set up through shopify
          if (provider === "stripe") {
            dispatch(setMessage("Cancelling current subscription..."));
            try {
              await paymentsService.cancelPlan(selectedEntity?.id);
              dispatch(
                setMessage("Current subscription successfully cancelled.")
              );
            } catch (err) {
              dispatch(
                setMessage(
                  "There was a problem automatically cancelling the current subscription. You may need to cancel it manually before setting up a new subscription through Shopify."
                )
              );
            }
          }
        }
        break;
      case "FACEBOOK":
        code = params.code;
        result = await facebookService.getOauthToken(selectedEntity.id, code);
        break;
      case "GA4":
        code = params.code;
        result = await integrationService.getOAuthToken({
          entityId: selectedEntity.id,
          platform: "GA4",
          code,
        });
        break;
      case "AMAZON":
        code = params.spapi_oauth_code;
        result = await integrationService.getOAuthToken({
          entityId: selectedEntity.id,
          platform: "amazon",
          extraPayload: {
            sellingPartnerId: params.selling_partner_id,
            redirectUri: `${process.env.REACT_APP_BASE_URL}/redirect?integrationId=AMAZON&func=oAuthRedirect`,
          },
          code,
        });

        break;
      default:
        console.error("error, could not find integration");
        break;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const handleShopifySubscription = async ({ setError, history, params }) => {
  try {
    const shop = params.shop;
    const chargeId = params.charge_id;
    const entityId = params.entityId;
    const planId = params.plan;
    await paymentsService.setupShopifySubscription({
      shop,
      entityId,
      chargeId,
      planId,
    });
    redirectToDashboard({ history });
  } catch (error) {
    setError(error.message);
  }
};

export default {
  handleOAuthRedirect,
  handleOAuthRefresh,
  redirectToIntegration,
  handleShopifySubscription,
};
