import { alpha, createTheme } from "@mui/material/styles";

const colors = {
  lightGrayBG: "#EEEEEE",
  background: "#181818",
  surface: "#282828",
  primary: "#EEB76B",
  primaryVariant: "#9C3D54",
  error: "#D32F2F",
  secondary: "#E2703A",
  onPrimary: "#000000",
  onSecondary: "#FFFFFF",
  onBackground: "#FFFFFF",
  onSurface: "#FFFFFF",
  onError: "#FFFFFF",
  white: "#FFFFFF",
  black: "#000000",
};

const themeOptions = {
  palette: {
    mode: "dark",
    background: {
      default: colors.background,
      paper: colors.surface,
    },
    surface: {
      main: colors.surface,
      contrastText: colors.onSurface,
    },
    primary: {
      main: colors.primary,
      contrastText: colors.onPrimary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.onSecondary,
    },
    error: {
      main: colors.error,
      contrastText: colors.onError,
    },
    text: {
      primary: colors.onBackground,
      secondary: alpha(colors.onBackground, 0.7),
    },
  },
  typography: {
    fontFamily: "Helvetica, sans-serif",
    fontSize: 14,
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
      fontSize: 18,
    },
    button: {
      fontWeight: 700,
      textTransform: "none",
    },
  },
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          // borderRadius: 50,
          textTransform: "none",
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
