import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import ButtonLoader from "Components/ButtonLoader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import paymentsService from "services/paymentsService.ts";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";
import { setMessage } from "store/actions/messageActions.ts";

const DeleteDialog = ({
  warnDelete,
  account,
  setOpenSnackbar,
  setWarnDelete,
  onDeleteSuccess,
  onDeleteFail,
  isAPIKeyOnly,
}) => {
  const entityId = useSelector((state) => state.entity?.selectedEntity?.id);
  const [showPaymentsWarning, setShowPaymentsWarning] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const dispatch = useDispatch();
  const handleDeleteConnectionResp = async (resp) => {
    if (resp.status === 200) {
      dispatch(setMessage("Account removed successfully."));
      await onDeleteSuccess();
      setWarnDelete(false);
    } else {
      await onDeleteFail();
    }
  };

  const deleteSubscription = async () => {
    // delete the subscription;
    try {
      dispatch(setMessage("Cancelling subscription..."));
      await paymentsService.cancelPlan(entityId);
      dispatch(setMessage("Subscription successfully cancelled."));
    } catch (error) {
      throw new Error(
        `Failed to cancel subscription: reason: ${error.message}`
      );
    }
  };

  const handleDeleteConnection = async () => {
    try {
      setDeleteLoading(true);
      if (await checkShopifyAccountIsPaymentAcccount()) {
        await deleteSubscription();
      }
      setTimeout(() => {
        dispatch(setMessage("Removing account..."));
      }, 1000);
      const result = await thirdPartyAccountService.deleteAccountAndData({
        accountId: account?.accountId,
        entityId,
        platform: account?.platform,
        isAPIKeyOnly,
        tokenId: account?.tokenId,
      });
      await handleDeleteConnectionResp(result);
    } catch (error) {
      dispatchEvent(setMessage(error.message));
      if (setOpenSnackbar) {
        setOpenSnackbar(true);
      }
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const checkShopifyAccountIsPaymentAcccount = async () => {
    try {
      setDeleteDisabled(true);
      const result = await paymentsService.getSubscriptionDetails({
        entityId,
      });
      if (result?.data?.dataValues?.provider === "shopify") {
        setShowPaymentsWarning(
          result?.data?.dataValues?.customerId === account?.accountId
        );
        return result?.data?.dataValues?.customerId === account?.accountId;
      }
      return false;
    } catch (error) {
      dispatch(setMessage(error.message));
    } finally {
      setDeleteDisabled(false);
    }
  };

  useEffect(() => {
    if (warnDelete) {
      checkShopifyAccountIsPaymentAcccount();
    }
  }, [warnDelete]);
  return (
    <Dialog fullWidth={false} open={warnDelete}>
      <DialogTitle display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h5">Are you sure?</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => setWarnDelete(false)}>
            {" "}
            <Close />{" "}
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 3 }}>
          All data associated with this connection will be removed from this
          project. If you want to keep historical data, you can instead
          temporarily disable this connection.
        </Typography>
        {showPaymentsWarning && (
          <Alert severity="warning">
            This account is currently being used for managing your subscription
            through Shopify Billing. Removing this account will cancel your
            current subscription and you'll need to set up a new subscription.
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleDeleteConnection} disabled={deleteDisabled}>
          <ButtonLoader
            text="Remove Account"
            loading={deleteLoading}
            size={20}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
