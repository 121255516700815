const mapErrorCode = (errCode) => {
  switch (errCode) {
    case "GA4_01":
      return "This may be a one-off miss, or you may need to refresh your credentials";
    default:
      return "Please check your credentials";
  }
};

export default {
  mapErrorCode,
};
