import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getDates = async (entityId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/dates?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateDates = async (entityId: number, dates: any) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/dates`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      entityId,
      primaryStartDate: dates.primaryStartDate,
      primaryEndDate: dates.primaryEndDate,
      secondaryStartDate: dates.secondaryStartDate,
      secondaryEndDate: dates.secondaryEndDate,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export default { getDates, updateDates };
