import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const login = async (email: string, password: string) => {
  try {
    const uri = `${process.env.REACT_APP_VENDANZA_API}/auth/login`;
    const body = {
      email,
      password,
    };
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${null}`,
      },
    };
    const credentials = axios.post(uri, body, config);
    return credentials;
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const updateUserDetails = async (userDetails: any) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/auth/user`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      userDetails,
    };
    const result = await axios.put(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const updatePassword = async ({
  userId,
  currentPassword,
  newPassword,
}: any) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/auth/password`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      userId,
      currentPassword,
      newPassword,
    };
    const result = await axios.put(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const registerAccount = async (
  email: string,
  firstName: string,
  lastName: string,
  password: string
) => {
  try {
    const uri = `${process.env.REACT_APP_VENDANZA_API}/auth/register`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      email,
      firstName,
      lastName,
      password,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export default {
  login,
  registerAccount,
  updatePassword,
  updateUserDetails,
};
