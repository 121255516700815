import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";
import { setMessage } from "store/actions/messageActions.ts";
import StatusIndicator from "./components/StatusIndicator";

const IntegrationCard = ({ Action, integration, desc, logo, name }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingAccts, setLoadingAccts] = useState(false);
  const [configWarning, setConfigWarning] = useState(false);
  const [numAccts, setNumAccts] = useState(0);
  const [credentialsPresent, setCredentialsPresent] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const entityId = useSelector((state) => state.entity?.selectedEntity?.id);

  const checkConnectedAccounts = async () => {
    try {
      if (!entityId) {
        return;
      }
      setLoadingAccts(true);
      const result =
        await thirdPartyAccountService.getStoredAccountsByEntityIdPlatform(
          entityId,
          integration
        );
      const credentialsPresent =
        await thirdPartyAccountService.checkForStoredCredentials({
          entityId,
          platform: integration,
        });
      setCredentialsPresent(credentialsPresent?.data?.credentialsPresent);
      if (result?.data?.length === 0) {
        setConfigWarning(true);
      } else {
        setConfigWarning(false);
        setAccounts(result?.data);
      }
    } catch (error) {
      console.error(error);
      dispatch(setMessage("error", "Error fetching accounts"));
    } finally {
      setLoadingAccts(false);
    }
  };

  useEffect(() => {
    checkConnectedAccounts();
  }, [entityId]);
  useEffect(() => {
    setNumAccts(accounts.length);
  }, [accounts]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <StatusIndicator accounts={accounts} />
      </CardContent>

      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          p: 1,
        }}
      >
        <Box display="flex" justifyContent="center" flexDirection="row">
          <Box
            component="img"
            mr={2}
            sx={{ width: "50px", height: "50px" }}
            src={logo}
          />
          <Box>
            <Typography variant="h6">{name}</Typography>
            <Typography sx={{ mb: 2 }} variant="subtitle2">
              {desc}
            </Typography>
          </Box>
        </Box>
        {loading ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            width="100%"
            height={40}
            sx={{ borderRadius: "20px" }}
          />
        ) : (
          <Action
            accounts={accounts}
            setAccounts={setAccounts}
            onUpdate={checkConnectedAccounts}
            credsPresent={credentialsPresent}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default IntegrationCard;
