import entityService from "services/entityService.ts";
import sessionStorageUtils from "Utils/sessionStorage";
export const setEntities = (entities: any) => ({
  type: "SET_ENTITIES",
  payload: entities,
});

export const setSelectedEntity = (selectedEntity: any) => ({
  type: "SET_SELECTED_ENTITY",
  payload: selectedEntity,
});

export const fetchEntities = (userId: string) => async (dispatch: any) => {
  try {
    const response = await entityService.getEntitiesByUserId(userId);
    dispatch(setEntities(response)); // Dispatch the action with the fetched data
  } catch (error) {
    console.error("Error fetching entities:", error);
  }
};

export const fetchEntitybyId = (entityId: string) => async (dispatch: any) => {
  try {
    const response = await entityService.getEntityById(entityId);
    dispatch(setSelectedEntity(response.dataValues)); // Dispatch the action with the fetched data
  } catch (error) {
    console.error("Error fetching entities:", error);
  }
}

export const loadSelectedEntity = (dispatch: any) => {
  try {
    const response = sessionStorageUtils.getSelectedEntity();
    dispatch(setSelectedEntity(response)); // Dispatch the action with the fetched data
  } catch (error) {
    console.error("Error fetching entities:", error);
  }
};
