import { Alert, AlertTitle, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCurrentTab } from "store/actions/navigationActions.ts";

const NoConnectorsMsg = () => {
  const dispatch = useDispatch();
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const history = useHistory();

  const toIntegrations = () => {
    dispatch(setCurrentTab("integrations"));
    history.push(`/entity/analytics/integrations?entityId=${entityId}`);
  };
  return (
    <Alert severity="warning" sx={{ mt: 3 }}>
      <AlertTitle sx={{fontWeight:700}}>No connections found</AlertTitle>
      Add some service integrations to pull in your data.
      <Button onClick={toIntegrations}>Get started</Button>
    </Alert>
  );
};

export default NoConnectorsMsg;
