import { Button } from "@mui/material";
import sessionStorage from "Utils/sessionStorage";
import { useDispatch } from "react-redux";
import { resetStore } from "store/actions/authActions.ts";

const LogoutButton = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(resetStore());
    const complete = sessionStorage.handleLogout();
    if (complete) {
      window.location.href = "/login";
    }
  };
  return (
    <Button onClick={handleLogout} variant="outlined">
      Sign Out
    </Button>
  );
};

export default LogoutButton;
