import { Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BoardMenu from "./components/BoardMenu/BoardMenu";
import boardService from "Api/boards/boardService.ts";
import { useSelector } from "react-redux";
import NoMetrics from "./components/NoMetrics";
import MetricGridItem from "./components/BoardItem/BoardItem";

const Board = ({ details }) => {
  const primaryMetrics = useSelector(
    (state) => state?.metrics?.baseMetrics?.primaryMetrics
  );
  const secondaryMetrics = useSelector(
    (state) => state.metrics.baseMetrics.secondaryMetrics
  );
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const [fetchedMetrics, setFetchedMetrics] = useState([]);
  const [metrics, setMetrics] = useState([]);

  const fetchMetrics = async () => {
    const result = await boardService.getMetricsByBoardId(details.boardId);
    setFetchedMetrics(result.data);
  };
  const getboardMetrics = async () => {
    try {
      if (primaryMetrics) {
        const boardMetrics = primaryMetrics?.filter((primaryMetric) => {
          return fetchedMetrics?.some(
            (fetchedMetric) =>
              fetchedMetric.dataValues?.name === primaryMetric?.name?.en
          );
        });
        const metricsWithId = boardMetrics.map((boardMetric) => {
          const matchingMetric = fetchedMetrics.find(
            (fetchedMetric) =>
              fetchedMetric.dataValues?.name === boardMetric.name?.en
          );
          return {
            ...boardMetric,
            chartType: matchingMetric?.dataValues?.chartType,
            chartView: matchingMetric?.dataValues?.chartView,
            id: matchingMetric.dataValues?.metricId,
          };
        });

        // Update the state with the filtered metrics
        setMetrics(metricsWithId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getComparisonData = (slug) => {
    return secondaryMetrics.find((metric) => metric.slug === slug);
  };

  useEffect(() => {
    getboardMetrics();
  }, [fetchedMetrics, primaryMetrics, entityId]);

  useEffect(() => {
    fetchMetrics();
  }, [primaryMetrics, entityId]);
  return (
    <Paper sx={{ p:{xs: 0, lg:1}, mt: 3, mb: 3 }}>
      <Grid
        sx={{ pr: 3 }}
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item sx={{p:1}}>
          <Typography variant="h6">{details?.name}</Typography>
          <Typography variant="subtitle2">{details?.description}</Typography>
        </Grid>
        <Grid item>
          <BoardMenu
            details={details}
            currentMetrics={metrics}
            onUpdate={fetchMetrics}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mt: 1 }}
        columns={12}
        display="flex"
        justifyContent="flex-start"
      >
        {metrics?.map((metric) => {
          return (
            <MetricGridItem
              key={metric.id}
              boardId={details.boardId}
              metric={metric}
              comparisonData={getComparisonData(metric.slug)}
              onUpdate={fetchMetrics}
            />
          );
        })}
        {metrics?.length === 0 && (
          <NoMetrics
            details={details}
            currentMetrics={metrics}
            onAddMetric={fetchMetrics}
          />
        )}
      </Grid>
    </Paper>
  );
};

export default Board;
