import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import action_1 from 'assets/vendanza/walkthrough/action_1.svg';
import action_2 from 'assets/vendanza/walkthrough/action_2.svg';
import action_3 from 'assets/vendanza/walkthrough/action_3.svg';

const StepThree = () => {

  const ImageContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(10), // Default margin
    [theme.breakpoints.up("lg")]: {
      marginTop: 0, // Margin for large screens and up
    },
  }));

  const ImageBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    transition: "transform 0.3s ease-in-out", // Optional: adds a smooth transition effect
    "&:nth-of-type(1)": {
      left: 0,
      top: -150,
    },
    "&:nth-of-type(2)": {
      left: -200, // Adjust for desired overlap
      top: 150, // Adjust for desired staggering
      zIndex: 100
    },
    "&:nth-of-type(3)": {
      left: -100, // Adjust for desired overlap
      top: -100, // Adjust for desired staggering
    },
  }));

  return (
    <Grid container display="flex" justifyContent="center" sx={{ mt: 20 }}>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              
              mb={0}
              p={0}
            >
              Step Three:
            </Typography>
            <Typography
              sx={{
                fontSize: "48px",
                fontWeight: 600,
                mt: 0,
                pt: 0,
                lineHeight: 1,
              }}
            >
              Execute.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ mb: 2, fontSize: "20px", fontWeight: 600 }}
              
            >
              Stop platform hopping to get stuff done.{" "}
            </Typography>
            <Typography
              sx={{ mb: 2, fontSize: "20px", fontWeight: 600 }}
              
            >
              Using your metrics, leverage the power of AI to create and execute
              calculated actions across all of your connected platforms, all
              without ever leaving Vendanza.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        justifyContent="center"
        alignItems="center"
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <ImageContainer>
          <ImageBox
            component="img"
            src={action_1}
            sx={{ width: "200px" }}
          />
          <ImageBox
            component="img"
            src={action_2}
            sx={{ width: "400px" }}
          />
          <ImageBox
            component="img"
            src={action_3}
            sx={{ width: { xs: "140px", sm: "200px" } }}
          />
        </ImageContainer>
      </Grid>
    </Grid>
  );
};

export default StepThree;
