import { Close, Edit, EmojiEventsOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import metricsService from "Api/metrics/metricsService.ts";
import ButtonLoader from "Components/ButtonLoader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";
import { getMetricGoalsByEntityId } from "store/actions/metricActions.ts";

const GoalEditor = ({ metric, goal }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [direction, setDirection] = useState("GTE");
  const [startDate, setStartDate] = useState(new Date());
  const [compareStartDate, setCompareStartDate] = useState(new Date());
  const [compareEndDate, setCompareEndDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [threshold, setThreshold] = useState(0);
  const [type, setType] = useState("%");
  const [showDeleteGoal, setShowDeleteGoal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);

  const deleteGoal = async () => {
    try {
      setDeleteLoading(true);
      await metricsService.deleteMetricGoal({
        metricSlug: metric.slug,
        entityId,
      });
      setOpen(false);
      dispatch(getMetricGoalsByEntityId({ entityId }));
    } catch {
      dispatch(setMessage("Something went wrong, please try again"));
    } finally {
      setDeleteLoading(false);
    }
  };
  const updateMetricGoal = async () => {
    try {
      setLoading(true);
      await metricsService.updateOrCreateMetricGoal({
        metricSlug: metric.slug,
        title,
        description: desc,
        direction,
        startDate,
        endDate,
        compareStartDate: compareStartDate ?? null,
        compareEndDate: compareEndDate ?? null,
        threshold,
        type,
        entityId,
      });
      setOpen(false);
      dispatch(getMetricGoalsByEntityId({ entityId }));
    } catch (err) {
      dispatch(setMessage("Something went wrong, please try again"));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (goal) {
      setTitle(goal.dataValues.title);
      setDesc(goal.dataValues.description);
      setDirection(goal.dataValues.direction);
      setStartDate(goal.dataValues.startDate);
      setEndDate(goal.dataValues.endDate);
      setThreshold(goal.dataValues.threshold);
      setType(goal.dataValues.type);
      setCompareStartDate(goal.dataValues.compareStartDate);
      setCompareEndDate(goal.dataValues.compareEndDate);
    }
  }, [open]);

  return (
    <>
      {goal && (
        <Tooltip title="Edit Goal">
          <IconButton onClick={() => setOpen(true)} fullWidth size="small">
            <Edit />
          </IconButton>
        </Tooltip>
      )}
      {!goal && (
        <Button
          sx={{ mt: 1 }}
          onClick={() => setOpen(true)}
          size="small"
          startIcon={<EmojiEventsOutlined />}
        >
          <Typography> Create Goal</Typography>
        </Button>
      )}

      <Dialog open={open} fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{metric?.name?.en} Goal</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 1, mt: 3 }}>Goal Title</Typography>
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Typography sx={{ mb: 1 }}>Description (optional)</Typography>
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            multiline
            minRows={3}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <Typography sx={{ mb: 1 }}>{metric?.name?.en} Goal</Typography>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={5}>
              <Select
                fullWidth
                sx={{ mb: 3 }}
                value={direction}
                onChange={(e) => setDirection(e.target.value)}
              >
                <MenuItem value="GTE">Greater than or equal to</MenuItem>
                <MenuItem value="LTE">Less than or equal to</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={8} sm={4}>
              <TextField
                sx={{ mb: 3 }}
                fullWidth
                type="number"
                value={threshold}
                onChange={(e) => setThreshold(e.target.value)}
              />
            </Grid>
            <Grid item xs={3} sm={2}>
              <Select
                fullWidth
                sx={{ mb: 3 }}
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value="%">%</MenuItem>
                <MenuItem value="$">$</MenuItem>
                <MenuItem value="#">#</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {type === "%" && (
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography sx={{ mb: 1 }}>Compared To</Typography>
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Start Date
                  </Typography>

                  <TextField
                    sx={{ mb: 3 }}
                    fullWidth
                    type="date"
                    value={compareStartDate}
                    error={compareStartDate > compareEndDate}
                    helperText={
                      compareStartDate > compareEndDate
                        ? "Start date must be before end date"
                        : ""
                    }
                    onChange={(e) => setCompareStartDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    End Date
                  </Typography>

                  <TextField
                    sx={{ mb: 3 }}
                    fullWidth
                    type="date"
                    value={compareEndDate}
                    error={compareStartDate > compareEndDate}
                    helperText={
                      compareStartDate > compareEndDate
                        ? "Start date must be before end date"
                        : ""
                    }
                    onChange={(e) => setCompareEndDate(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
          <Typography sx={{ mb: 1 }}>Starting & Ending Dates</Typography>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={5}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Start Date
              </Typography>

              <TextField
                sx={{ mb: 3 }}
                fullWidth
                type="date"
                value={startDate}
                error={startDate > endDate}
                helperText={
                  startDate > endDate
                    ? "Start date must be before end date"
                    : ""
                }
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                End Date
              </Typography>

              <TextField
                sx={{ mb: 3 }}
                fullWidth
                type="date"
                value={endDate}
                error={startDate > endDate || endDate < new Date()}
                helperText={
                  startDate > endDate
                    ? "Start date must be before end date"
                    : endDate < new Date()
                    ? "End date must be in the future"
                    : ""
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 3,
            display: "flex",
            justifyContent: goal ? "space-between" : "flex-end",
          }}
        >
          {goal && !showDeleteGoal && (
            <Button onClick={() => setShowDeleteGoal(true)}>Delete Goal</Button>
          )}
          {showDeleteGoal && (
            <Box display="flex" alignItems="center">
              <Typography fontWeight={600}>Are you sure?</Typography>
              <Button onClick={deleteGoal} disabled={deleteLoading}>
                <ButtonLoader text="Yes" loading={deleteLoading} />
              </Button>
              <Button onClick={() => setShowDeleteGoal(false)}>No</Button>
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={updateMetricGoal}
            disabled={
              loading ||
              !title ||
              !threshold ||
              !startDate ||
              !endDate ||
              (type === "%" && (!compareStartDate || !compareEndDate)) ||
              startDate > endDate ||
              (type === "%" && compareStartDate > compareEndDate)
            }
          >
            <ButtonLoader text="Save Changes" loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GoalEditor;
