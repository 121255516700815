import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemText,
  Snackbar,
} from "@mui/material";
import IntegrationTitle from "Components/Integrations/IntegrationAccount/IntegrationTitle";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";
import amazonLogo from "assets/amazon-icon.svg";
import SingleAccount from "Components/Integrations/SingleAccount/SingleAccount";
import amazonUtils from "./amazonUtils";
import { useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";
const AmazonConfig = ({onUpdate}) => {
  const entityId = useSelector((state) => state.entity.selectedEntity.id);
  const [open, setOpen] = useState(false);
  const [accounts, setAccounts] = useState([])
  const dispatch = useDispatch();
  const fetchAccounts = async () => {
    const result =
      await thirdPartyAccountService.getStoredAccountsByEntityIdPlatform(
        entityId,
        "amazon"
      );
    setAccounts(result.data);
  };
  const handleDeleteSuccess = async () => {
    dispatch(setMessage("Successfully disconnected account. Data removal may take a few minutes to reflect on your dashboard."));
    await fetchAccounts();
    await onUpdate();
  };

  useEffect(() => {
    if (open) {
      fetchAccounts();
    }
  }, [entityId, open]);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Configure</Button>
      <Dialog open={open} fullWidth>
        <IntegrationTitle
          title="Amazon Seller Accounts"
          handleClose={() => setOpen(false)}
          logo={amazonLogo}
        />
        <DialogContent>
          {accounts?.map((account, idx) => {
            return (
              <SingleAccount
                account={account}
                primaryText={`Amazon Seller Account ${idx + 1}`}
                onDeleteSuccess={handleDeleteSuccess}
              />
            );
          })}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "flex-start", p: 3 }}
        >
          <Button onClick={() => amazonUtils.handleLogin()}>Add Seller Central account</Button>

        </DialogActions>
      </Dialog>
    </>
  );
};

export default AmazonConfig;
