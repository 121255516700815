import authService from "services/authService.ts";

const validatePassword = (password, newPassword) => {
  // Check if password and new password match
  if (password !== newPassword) {
    return { valid: false, reason: "Passwords do not match." };
  }

  // Check if the new password contains at least 8 characters
  if (newPassword.length < 8) {
    return {
      valid: false,
      reason: "Password must be at least 8 characters long.",
    };
  }

  // Regular expressions to check for presence of a number, a capital letter, and a special character
  const numberRegex = /\d/;
  const capitalLetterRegex = /[A-Z]/;
  const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

  // Check if the new password contains a number, a capital letter, and a special character
  if (!numberRegex.test(newPassword)) {
    return {
      valid: false,
      reason: "Password must contain at least one number (0-9).",
    };
  }

  if (!capitalLetterRegex.test(newPassword)) {
    return {
      valid: false,
      reason: "Password must contain at least one capital letter (A-Z).",
    };
  }

  if (!specialCharacterRegex.test(newPassword)) {
    return {
      valid: false,
      reason: "Password must contain at least one special character.",
    };
  }

  // Password meets all requirements
  return { valid: true };
};

const updatePassword = async ({
  userId,
  currentPassword,
  newPassword,
  setMessage,
  setShowMessage,
  setLoading,
  setShowChangePassword,
}) => {
  try {
    setLoading(true);
    const result = await authService.updatePassword({
      userId,
      currentPassword,
      newPassword,
    });
    if (result?.data?.message) {
      setMessage(result.data.message);
      setShowChangePassword(false);
    }
  } catch (err) {
    setMessage(err?.response?.data?.title);
  } finally {
    setShowMessage(true);
    setLoading(false);
  }
};

export default {
  validatePassword,
  updatePassword,
};
