import { Box, Container, Typography } from "@mui/material";
import CTA from "Components/CTA/CTA";
import React from "react";

const FinalCTA = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        my:10
      }}
    >
      <Typography
      textAlign='center'
        sx={{
          fontSize: "48px",
          fontWeight: 600,
          mt: 0,
          pt: 0,
          lineHeight: 0.9,
          mb: 3
        }}
      >
        Level up your e-commerce empire.
      </Typography>
      <Box sx={{width: {xs: '100vw, sm: 50%'}}}>
      <CTA />
      </Box>
    </Container>
  );
};

export default FinalCTA;
