import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import IntegrationCard from "../../Components/Integrations/IntegrationCard/IntegrationCard";
import facebookLogo from "assets/Facebook Brand Asset Pack 2019/f-Logos-2019-1/f_Logo_Online_04_2019/Color/PNG/f_logo_RGB-Blue_1024.png";
import shopifyLogo from "assets/ShopifyBag/png/shopify_glyph.png";
import googleAnalyticsLogo from "assets/googleAnalyticsImg.svg";
import amazonLogo from "assets/amazon-icon.svg";
import FacebookAction from "./components/facebook/Action";
import squarespaceLogo from "assets/SquareSpace.png";
import ShopifyAction from "./components/shopify/Action";
import AmazonAction from "./components/amazon/AmazonAction";
import GoogleAnalyticsAction from "./components/googleAnalytics/Action";
import SquareSpaceAction from "./components/squareSpace/Action";
import featureFlags from "views/RedirectLanding/utils/featureFlags";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { getThirdPartyAccountsByEntityId } from "store/actions/thirdPartyAcctActions.ts";
import { useSelector } from "react-redux";

const integrationsList = [
  {
    groupName: "Advertising",
    integrations: [
      {
        integration: "facebook",
        name: "Facebook Ads",
        logo: facebookLogo,
        desc: "Connect to Facebook to pull Ad data",
        action: FacebookAction,
      },
    ],
  },
  {
    groupName: "E-Commerce",
    integrations: [
      {
        integration: "shopify",
        name: "Shopify",
        logo: shopifyLogo,
        desc: "Pull e-commerce data from Shopify",
        action: ShopifyAction,
      },
      {
        integration: "amazon",
        name: "Amazon Seller Central",
        logo: amazonLogo,
        desc: "Pull E-commerce data from Seller Central",
        action: AmazonAction,
      },
      {
        integration: "SquareSpace",
        name: "SquareSpace",
        logo: squarespaceLogo,
        desc: "Pull E-commerce data from SquareSpace",
        action: SquareSpaceAction,
      },
    ],
  },
  {
    groupName: "Analytics",
    integrations: [
      {
        integration: "GA4",
        name: "Google Analytics",
        logo: googleAnalyticsLogo,
        desc: "Website insights from Google Analytics",
        action: GoogleAnalyticsAction,
      },
    ],
  },
];

const Integrations = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  const integrations = useSelector((state) => state?.thirdPartyAccounts);
  const dispatch = useDispatch();
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const populateRemainingIntegrations = () => {
    if (entity?.subscriptionStatus !== "free") {
      return <Typography variant="h6">∞ / ∞</Typography>;
    }
    return (
      <Typography variant="h6">
        {(integrations?.maxIntegrations ?? 0) -
          (integrations?.numIntegrations ?? 0)}{" "}
        / {integrations?.maxIntegrations ?? 0}
      </Typography>
    );
  };

  const filteredIntegrations = integrationsList
    .map((group) => ({
      ...group,
      integrations: group.integrations.filter((integration) =>
        integration.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((group) => group.integrations.length > 0);

  useEffect(() => {
    if (entity?.id) {
      dispatch(getThirdPartyAccountsByEntityId(entity));
    }
  }, [entity]);
  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid
        container
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid mb={3} item xs={12} md={6} display="flex" flexDirection="column">
          <Typography variant="h4">Integrations</Typography>
          <Typography variant="subtitle2">
            Connect to e-commerce platforms you use to run your business to
            streamline your operations and enhance your business capabilities.
          </Typography>{" "}
        </Grid>
        <Grid
          item
          xs={0}
          sm={4}
          md={3}
          lg={2}
          sx={{ display: { xs: "none", md: "flex" } }}
          flexDirection="column"
          textAlign="right"
          justifyContent="flex-end"
        >
          {populateRemainingIntegrations()}
          <Typography variant="subtitle2" color="white">
            Integrations Remaining
          </Typography>
        </Grid>
      </Grid>

      <TextField
        fullWidth
        placeholder="Type to search for a specific integration..."
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: searchTerm ? (
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchTerm("")}>
                <Close />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      {filteredIntegrations.map((group) => {
        return (
          <Grid
            container
            key={group.groupName}
            columns={16}
            sx={{
              backgroundColor: "lightwhiteBG",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={16}
              sx={{ mb: 2, mt: 2, pl: 2 }}
              display="flex"
              justifyContent="flex-start"
            >
              <Typography variant="h5">{group.groupName}</Typography>
            </Grid>
            <Grid
              container
              columns={16}
              display="flex"
              justifyContent="flex-start"
            >
              {group.integrations?.map((integration) => {
                return (
                  !integration.hidden && (
                    <Grid
                      item
                      xs={16}
                      sm={7}
                      lg={4.5}
                      sx={{ m: 2 }}
                      key={integration.integration}
                    >
                      <IntegrationCard
                        integration={integration.integration}
                        logo={integration.logo}
                        desc={integration.desc}
                        Action={integration.action}
                        name={integration.name}
                      />
                    </Grid>
                  )
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Integrations;
