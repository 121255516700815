import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Slide,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import sessionStorage from "Utils/sessionStorage";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEntity } from "store/actions/entityActions.ts";
import { useEffect, useState } from "react";
import {
  AutoGraph,
  AutoGraphOutlined,
  Campaign,
  CampaignOutlined,
  ChevronRightRounded,
  DashboardCustomize,
  DashboardCustomizeOutlined,
  Extension,
  ExtensionOutlined,
  Home,
  Settings,
  SettingsOutlined,
  Support,
  SupportOutlined,
  ViewModule,
  ViewModuleOutlined,
} from "@mui/icons-material";
import ShortText from "Components/ShortText/ShortText";
import { setCurrentTab } from "store/actions/navigationActions.ts";
import { useTheme } from "@emotion/react";

const EntityNavigation = ({ onClose }) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);

  const theme = useTheme();
  const [currentProject, setCurrentProject] = useState(0);
  const currentEntity = useSelector((state) => state.entity?.selectedEntity);
  const entityList = useSelector((state) => state?.entity?.entities);
  const currentTab = useSelector((state) => state?.navigation?.currentTab);
  const tools = [
    {
      route: "dashboard",
      name: "Overview",
      moduleId: 0,
      unfilledIcon: <DashboardCustomizeOutlined />,
      filledIcon: <DashboardCustomize color="primary" />,
    },
    {
      route: "integrations",
      name: "Integrations",
      moduleId: 1,
      unfilledIcon: <ExtensionOutlined />,
      filledIcon: <Extension color="primary" />,
    },
    {
      route: "graph",
      name: "Metrics",
      moduleId: 2,
      unfilledIcon: <AutoGraphOutlined />,
      filledIcon: <AutoGraph color="primary" />,
    },
    {
      route: "ads-management",
      name: "Marketing",
      moduleId: 3,
      unfilledIcon: <CampaignOutlined />,
      filledIcon: <Campaign color="primary" />,
    },
    {
      route:
        "https://join.slack.com/t/vendanza-workspace/shared_invite/zt-2muqfk0nq-jMMxMrbE9NhlNfmysti9Yg",
      name: `Community & Support`,
      moduleId: 4,
      unfilledIcon: <SupportOutlined />,
      filledIcon: <Support color="primary" />,
    },
    // {
    //   route: "chat",
    //   name: "Chat",
    //   moduleId: 4,
    //   unfilledIcon: <ChatBubbleOutlineOutlined />,
    //   filledIcon: <ChatBubble color="primary" />,
    // },
    {
      route: "project-settings",
      name: "Project Settings",
      moduleId: 5,
      unfilledIcon: <SettingsOutlined />,
      filledIcon: <Settings color="primary" />,
    },
  ];

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toTool = (baseRoute, route) => {
    if (
      route ===
      "https://join.slack.com/t/vendanza-workspace/shared_invite/zt-2muqfk0nq-jMMxMrbE9NhlNfmysti9Yg"
    ) {
      window.location.href = route;
      return;
    }
    dispatch(setCurrentTab(route));
    history.push(
      `/entity/${baseRoute}${route && "/"}${route}?entityId=${
        currentEntity?.id
      }`
    );
    onClose();
  };

  const handleEntitySelect = (entity) => {
    if (entity === -1) {
      setIsVisible(false);
      setTimeout(() => {
        dispatch(setCurrentTab("dashboard"));
        history.push(`/dashboard`);
        sessionStorage.setSelectedEntity(-1);
        dispatch(setSelectedEntity(-1));
      }, 100); // adjust this delay to match the transition duration
    } else {
      dispatch(setSelectedEntity(entity));
      sessionStorage.setSelectedEntity(entity);

      history.push(`/entity/analytics/dashboard?entityId=${entity?.id}`);
      onClose();
    }
    setAnchorEl(null);
  };

  const populateCurrentTool = () => {
    const route = window.location.href;
    const splitRoute = route.split("/")[5];
    if (splitRoute) {
      const cleanedRoute = splitRoute.split("?")[0];
      dispatch(setCurrentTab(cleanedRoute));
    }
  };

  useEffect(() => {
    setCurrentProject(currentEntity);
    populateCurrentTool();
  }, [currentEntity]);

  return (
    <Slide in={isVisible} direction="right">
      <Box>
        <List
          sx={{
            overflow: "scroll",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <ListItem
            disablePadding
            sx={{ mt: 1, display: "flex", alignItems: "center" }}
          >
            <IconButton onClick={() => handleEntitySelect(-1)}>
              <Home color="primary" />
            </IconButton>
            <>
              <ChevronRightRounded />
              <Button
                fullWidth
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                onClick={handleClick}
              >
                <ShortText
                  variant="h7"
                  text={currentProject.name}
                  maxLen={10}
                />
              </Button>
            </>
          </ListItem>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleEntitySelect(-1)}>
              <em>All Projects</em>
            </MenuItem>
            {entityList.map((entity) => (
              <MenuItem
                key={entity?.id}
                value={entity}
                onClick={() => handleEntitySelect(entity)}
              >
                {entity.name}
              </MenuItem>
            ))}
          </Menu>
          <List>
            {tools?.map((tool) => {
              return (
                !tool.hidden && (
                  <ListItemButton
                    sx={{ borderRadius: "10px", mb: 1 }}
                    key={tool.moduleId}
                    selected={currentTab === tool.route}
                    onClick={() => toTool("analytics", tool.route)}
                  >
                    <ListItemIcon>
                      {currentTab === tool.route
                        ? tool.filledIcon
                        : tool.unfilledIcon}
                    </ListItemIcon>
                    <ListItemText primary={tool.name} />
                  </ListItemButton>
                )
              );
            })}
          </List>
        </List>
      </Box>
    </Slide>
  );
};

export default EntityNavigation;
