import {
  Grid,
  Typography,
  Container,
  Divider,
} from "@mui/material";
import BusinessCard from "../../Components/BusinessCard/BusinessCard";
import { useSelector } from "react-redux";
import AddEntity from "./Components/AddEntity/AddEntity";
import NoEntitiesMsg from "./Components/NoEntitiesMsg";

const Dashboard = () => {
  const entities = useSelector((state) => state.entity?.entities) ?? [];
  return (
    <Container
      disableGutters
      sx={{
        p: 1,
        height: '90vh'
      }}
    >
      {/* <Toolbar /> */}
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        mt={3}
      >
        <Grid
          item
          xs={12}
          sm={6}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "center", sm: "flex-start" }}
        >
          <Typography variant="h4">Projects Overview</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{mt:{xs:3, md:0}}}
          display="flex"
          justifyContent={{ xs: "center", sm: "flex-end" }}
        >
          {entities.length > 0 && <AddEntity showIcon />}
        </Grid>
      </Grid>
      <Divider fullWidth mb={3} />
      <Grid
        // sx={{ p: 4 }}
        display="flex"
        // justifyContent="center"
        container
        columns={12}
      >
        {entities.map((entity) => {
          return (
            <Grid key={entity.entityId} item xs={12} sm={6} md={6}>
              <BusinessCard details={entity} />
            </Grid>
          );
        })}
        {entities.length === 0 && <NoEntitiesMsg />}
      </Grid>
    </Container>
  );
};

export default Dashboard;
