import { Box, Container, Paper, Toolbar, Typography } from '@mui/material'
import React from 'react'
import underConstruction from 'assets/under-construction.png'
const NewFeature = () => {
  return (
    <Container sx={{display: 'flex', justifyContent:"center", alignItems:'center', height: '85vh'}}>
        <Toolbar/>
        <Paper  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', p:6, borderRadius: '10px'}}>
        <Box component="img" sx={{width: '100px', height: '100px'}} src={underConstruction}>

        </Box>
        <Typography variant="h4" mt={3}>
            This feature is under construction.
        </Typography>
        <Typography variant="subtitle1">
            Check back later for updates!
        </Typography>
        </Paper>
    </Container>
  )
}

export default NewFeature