const { styled, Box } = require("@mui/material");

const HoverBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover .MuiSvgIcon-root": {
      transform: "translateX(10px)", // Adjust the value to your preference
      transition: "transform 0.3s ease-in-out", // Smooth transition effect
    },
  }));
export default HoverBox;