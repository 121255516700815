import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const FatMenu = ({ items, title, readOnly }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (link) => {
    history.push(link);
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseEnter={handleClick}
        size="large"
      >
        {title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          onMouseLeave: handleClose,
        }}
        PaperProps={{
          sx: {
            width: "600px", // Adjust the width as needed
            padding: 5,
            boxShadow: 3,
          },
        }}
      >
        <Grid container spacing={2} columns={12}>
          {items?.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ p: 2 }}
              key={index}
              onClick={() => !readOnly && handleMenuItemClick(item.link)}
            >
              <Box
                sx={{ cursor: readOnly ? '' : "pointer" }}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box display="flex" alignItems="center" mb={1}>
                  <item.icon sx={{ mr: 1 }} />
                  <Typography variant="body1" fontWeight={600}>
                    {item.title}
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {item.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Menu>
    </>
  );
};

export default FatMenu;
