import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
    setGraphFilters,
  setGraphMetricName,
  setGraphMetrics,
  setGraphType,
} from "store/actions/graphActions.ts";
import { setCurrentTab } from "store/actions/navigationActions.ts";

const MetricsNavigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentEntity = useSelector((state) => state.entity?.selectedEntity);
  const metricName = useSelector((state) => state?.graph?.metricName);
  const graphType = useSelector((state) => state?.graph?.graphType);
  const metricsList = useSelector(
    (state) => state?.metrics?.baseMetrics?.primaryMetrics
  );
  const selectedMetrics = useSelector((state) => state?.graph?.selectedMetrics);
  const filters = useSelector((state) => state?.graph?.filters);
  const back = () => {
    dispatch(setGraphMetrics([]));
    dispatch(setGraphMetricName(""));
    dispatch(setGraphFilters([]));
    dispatch(setCurrentTab("dashboard"));
    history.push(`/entity/analytics/dashboard?entityId=${currentEntity?.id}`);
  };

  const handleChange = (metric) => {
    if (selectedMetrics.includes(metric)) {
      dispatch(setGraphMetrics(selectedMetrics.filter((m) => m !== metric)));
    } else {
      dispatch(setGraphMetrics([...selectedMetrics, metric]));
    }
  };

  const handleChangeGraphType = (graphType) => {
    dispatch(setGraphType(graphType));
    };

  const graphTypes = [
    "Line",
    "Bar",
  ]

  return (
    <Box mt={2} mx={1}>
      <Button sx={{ pr: 3, mb: 1 }} onClick={back} startIcon={<ChevronLeft />}>
        Back
      </Button>
      <Typography>
        Select the metrics you would like to view
      </Typography>
      <Divider fullWidth sx={{ mb: 2 }} />
      {/* <TextField
        sx={{ mb: 2 }}
        fullWidth
        size="small"
        label="Metric Name"
        onChange={(e) => dispatch(setGraphMetricName(e.target.value))}
        value={metricName}
      /> */}
            {/* <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel id="graph-type">Graph Type</InputLabel>
        <Select
          
          value={graphType}
          fullWidth
          label="Graph Type"
        >
          {graphTypes?.map((graphType) => (
            <MenuItem
              key={graphType}
              value={graphType}
              onClick={() => handleChangeGraphType(graphType)}
            >
              {graphType}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
          
          {metricsList?.map((metric) => (
            <Chip sx={{m:0.5}}
                color={selectedMetrics.includes(metric) ? "primary" : "default"}
              key={metric.id}
              value={metric}
              onClick={() => handleChange(metric)}
              label={metric?.acronym?.en ?? metric?.name?.en}
            />
          ))}
      {/* <Button
        disabled={!metricName || !selectedMetrics.length}
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Save Metric
      </Button> */}
    </Box>
  );
};

export default MetricsNavigation;
