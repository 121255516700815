import { getAuthToken } from "Utils/data/authToken";
import sessionStorage from "Utils/sessionStorage";
import axios from "axios";

const getEntitiesByUserId = async (userId: string) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/entity-user/${userId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    // Make the API call and await the response
    const response = await axios.get(uri, config);

    // Check if the response is successful
    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const createEntity = async (name: any) => {
  try {
    const accessToken = getAuthToken();
    const currentUser = sessionStorage.getUserSessionStorage();
    const userId = currentUser.id;
    const uri = `${process.env.REACT_APP_VENDANZA_API}/entity`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      userId,
      name,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const deleteEntity = async (entityId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/entity/${entityId}`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.delete(uri, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const getEntityById = async (entityId: string) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/entity/${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.get(uri, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export default {
  getEntitiesByUserId,
  createEntity,
  getEntityById,
  deleteEntity,
};
