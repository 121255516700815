import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import pricingOptions from "./Components/pricing";
import PricingOption from "./Components/PricingOption";
import { useSelector } from "react-redux";

const Pricing = () => {
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  const [currentPlanRank, setCurrentPlanRank] = useState(0);

  useEffect(() => {
    const currentPlan = pricingOptions.find(
      (option) => option.id === entity?.subscriptionStatus
    );
    setCurrentPlanRank(currentPlan?.rank);
  }, [entity])
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h2" fontWeight={700}>
          Scalable Plans. Transparent Pricing.
        </Typography>
        <Typography mt={3} variant="subtitle1">
          Whether you're a budding unicorn or large enterprise, we have a plan
          that can fit your needs.
        </Typography>
        <Grid
          container
          columns={16}
          mt={6}
          display="flex"
          justifyContent="center"
        >
          {pricingOptions.map((option) => {
            return (
              <Grid m={1} mb={3} item xs={16} sm={8} lg={5}>
                <PricingOption
                  planDetails={option}
                  key={option.planName}
                  currentPlanRank={currentPlanRank}
                  planName={option.planName}
                  planDescription={option.planDescription}
                  price={option.price}
                  inclusions={option.inclusions}
                  link={option?.link}
                />
              </Grid>
            );
          })}
        </Grid>
        <Typography variant="subtitle2" textAlign="center" >
          Vendanza partners with Stripe and Shopify Billing to handle payments and subscriptions. We do not store
          any payment information. When converting to a paid plan, depending on your project, you will be redirected to Stripe or Shopify to
          setup the subscription.
        </Typography>
      </Box>
    </>
  );
};

export default Pricing;
