import { Check } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import paymentsService from "services/paymentsService.ts";
import { setMessage } from "store/actions/messageActions.ts";
import DowngradeModal from "./DowngradeModal";
import { useState } from "react";
import subscriptionUtils from "Utils/subscriptionUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { bool } from "prop-types";

const PricingOption = ({
  planDetails,
  planName,
  planDescription,
  inclusions,
  currentPlanRank,
  frontPage,
}) => {
  const history = useHistory();
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const [openDowngrade, setOpenDowngrade] = useState(false);

  const handleCheckout = async () => {
    if (frontPage) {
      history.push("/register");
      return;
    }
    if (currentPlanRank > planDetails.rank) {
      // downgrade plan
      setOpenDowngrade(true);
      return;
    }
    if (
      currentPlanRank < planDetails.rank &&
      entity?.subscriptionStatus === "free"
    ) {
      // no current plan, redirect to provider for setting up a subscription
      try {
        const { provider } = await subscriptionUtils.determineProvider(
          entity?.id
        );
        if (provider === "stripe") {
          const result = await paymentsService.createCheckoutSession({
            entityId: entity?.id,
            planDetails,
          });
          window.location.href = result?.data?.url;
        }
        if (provider === "shopify") {
          const result = await paymentsService.createCheckoutSession({
            entityId: entity?.id,
            planDetails,
          });
          window.location.href = result?.data?.confirmation_url;
        }
      } catch (error) {
        dispatch(setMessage(error.msg));
      }
    } else {
      // upgrade plan
      subscriptionUtils.handleUpgrade({
        dispatch,
        entity,
        user,
        plan: planDetails,
      });
    }
  };

  return (
    <Card sx={{ border: planDetails?.highlight ? "1px solid gray" : null }}>
      {planDetails?.highlight ? (
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ mr: 3, mb: 0, mt: 3 }}
        >
          <Chip
            size="small"
            variant="outlined"
            color="primary"
            label={
              <Typography sx={{ px: 1 }} variant="subtitle2">
                Most Popular
              </Typography>
            }
          />
        </Box>
      ) : (
        <div style={{ height: "50px" }}></div>
      )}

      <CardHeader
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          height: "100px",
          fontWeight: 'bold'
        }}
        title={planName}
        subheader={planDescription}
      />
      <CardContent>
        <Typography
          textAlign="center"
          sx={{
            textDecoration: planDetails.discount ? "line-through" : "none",
            color: planDetails.discount ? "gray" : null,
            fontSize: planDetails.discount ? "1.5rem" : "2rem",
          }}
        >
          {" "}
          ${planDetails?.price}
        </Typography>
        {planDetails.discount && (
          <Typography textAlign="center" fontSize="2rem">
            ${planDetails.discount}
          </Typography>
        )}
        <Typography textAlign="center" variant="subtitle2" fontWeight={700} >
          billed monthly
        </Typography>
        <List>
          <ListItem>
            <ListItemText sx={{ fontWeight: 700 }}>Includes:</ListItemText>
          </ListItem>
          {inclusions?.map((item) => {
            return (
              <ListItem key={item}>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText>{item}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 3,
          mb: 3,
        }}
      >
        {frontPage && (
          <Button
            fullWidth
            variant="contained"
            disabled={entity?.subscriptionStatus === planDetails?.id}
            onClick={handleCheckout}
          >
            Get Started
          </Button>
        )}
        {!frontPage && (
          <Button
            fullWidth
            variant="contained"
            disabled={entity?.subscriptionStatus === planDetails?.id}
            onClick={handleCheckout}
          >
            {entity?.subscriptionStatus === planDetails?.id
              ? "Current Plan"
              : "Select Plan"}
          </Button>
        )}
      </CardActions>
      <DowngradeModal
        plan={planDetails}
        open={openDowngrade}
        setOpen={setOpenDowngrade}
      />
    </Card>
  );
};

PricingOption.propTypes = {
  frontPage: bool,
};

PricingOption.defaultProps = {
  frontPage: false,
};

export default PricingOption;
