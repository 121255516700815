import { Close } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import ButtonLoader from "Components/ButtonLoader";
import passwordUtils from "Utils/passwordUtils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LogoutButton from "../LogoutButton";

const Account = () => {
  const user = useSelector((state) => state?.user?.user);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatePasswordDisabled, setUpdatedPasswordDisabled] = useState({
    valid: false,
    state: "initial",
    reason: null,
  });
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    username: null,
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [changesDetected, setChangesDetected] = useState(false);

  const handleCloseSnackbar = () => {
    setShowMessage(false);
  };

  const handleCancelPasswordChange = () => {
    setShowChangePassword(false);
    setPasswordData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  useEffect(() => {
    setFormData({
      username: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      password: null,
      confirmPassword: null,
    });
  }, [user]);

  useEffect(() => {}, [formData]);

  useEffect(() => {
    setUpdatedPasswordDisabled(
      passwordUtils.validatePassword(
        passwordData.newPassword,
        passwordData.confirmPassword
      )
    );
  }, [passwordData]);

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackbar}
    >
      <Close fontSize="small" />
    </IconButton>
  );

  return (
    <Grid
      container
      columns={12}
      disableGutters
      sx={{
        p: 1,
        mt: 3,
      }}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        User Information
      </Typography>
      <Grid container display="flex" mb={3}>
        <Grid item xs={12} sm={5} mb={{ xs: 3, sm: 0 }} mr={{ xs: 0, sm: 3 }}>
          <TextField
            fullWidth
            label="First Name"
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                firstName: e.target.value,
              }));
              setChangesDetected(true);
            }}
            value={formData.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Last Name"
            placeholder="Last Name"
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                lastName: e.target.value,
              }));
              setChangesDetected(true);
            }}
            value={formData.lastName}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mb={3} sm={5} display="flex">
        <TextField
          label="Username / Email"
          fullWidth
          onChange={(e) => {
            setFormData((prev) => ({
              ...prev,
              username: e.target.value,
            }));
            setChangesDetected(true);
          }}
          value={formData.username}
        />
      </Grid>
      <Grid item mb={3}>
        <Button variant="contained" disabled={!changesDetected}>
          Save Changes
        </Button>
      </Grid>

      <Divider fullWidth sx={{ mb: 1 }} />
      <Typography variant="subtitle1">Security & Passwords</Typography>
      <Typography variant="subtitle2"  sx={{ mb: 3 }}>
        Passwords must be at least 8 characters long and contain a capital
        letter, a lowercase letter, a number, and a special character.
      </Typography>
      <Grid container>
        {!showChangePassword && (
          <Button
            onClick={() => setShowChangePassword(true)}
            variant="outlined"
          >
            Update Password
          </Button>
        )}
        {showChangePassword && (
          <>
            <Grid item xs={12} mb={3} sm={5} mr={{ xs: 0, sm: 3 }}>
              <TextField
                fullWidth
                type="password"
                label="Current Password"
                onChange={(e) => {
                  setPasswordData((prev) => ({
                    ...prev,
                    currentPassword: e.target.value,
                  }));
                }}
                value={passwordData.currentPassword}
              />
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={5}
                mb={{ xs: 3, sm: 0 }}
                mr={{ xs: 0, sm: 3 }}
              >
                <TextField
                  fullWidth
                  type="password"
                  label="New Password"
                  onChange={(e) => {
                    setPasswordData((prev) => ({
                      ...prev,
                      newPassword: e.target.value,
                    }));
                  }}
                  value={passwordData.password}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  type="password"
                  label="Confirm New Password"
                  onChange={(e) => {
                    setPasswordData((prev) => ({
                      ...prev,
                      confirmPassword: e.target.value,
                    }));
                  }}
                  value={passwordData.confirmPassword}
                />
              </Grid>
            </Grid>
            {updatePasswordDisabled.valid === false &&
              updatePasswordDisabled.reason &&
              updatePasswordDisabled.state !== "initial" && (
                <Typography color="error" mt={3}>
                  {updatePasswordDisabled.reason}
                </Typography>
              )}
            <Grid item mt={3} xs={12}>
              <Button
                variant="contained"
                disabled={!updatePasswordDisabled.valid || loading}
                onClick={() =>
                  passwordUtils.updatePassword({
                    userId: user?.id,
                    currentPassword: passwordData.currentPassword,
                    newPassword: passwordData.newPassword,
                    setMessage,
                    setShowMessage,
                    setLoading,
                    setShowChangePassword,
                  })
                }
              >
                <ButtonLoader
                  text="Update Password"
                  size={20}
                  loading={loading}
                />
              </Button>
              <Button
                sx={{ ml: 3 }}
                variant="outlined"
                onClick={handleCancelPasswordChange}
              >
                Cancel
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12} mt={3}>
          <Divider sx={{ mb: 3 }} />
          <LogoutButton />
        </Grid>
      </Grid>
      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={message}
        action={action}
      />
    </Grid>
  );
};

export default Account;
