import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import { Business, Cable, Category, Close, GroupOutlined, JoinFull, JoinFullOutlined, Login, Menu, PersonAddAlt, PrivacyTip, SupportRounded } from "@mui/icons-material";
import { IconButton, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "assets/vendanza/brand/logo-full-light.svg";

const MobileMenu = () => {
  const [state, setState] = useState(false);
  const history = useHistory();

  const toRoute = (route) => {
    history.push(`/${route}`);
    setState(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!state);
  };

  const menuItems = [
    {
      title: "Login",
      route: "login",
      icon: <Login />,
    },
    {
      title: "Sign Up",
      route: "register",
      icon: <PersonAddAlt />,
    },
    {
      title: "Features",
      route: "hello",
      icon: <Category />,
    },
    {
      title: "Integrations",
      route: "integrations",
      icon: <Cable />,
    },
    {
      title: "Get Support",
      route: "community",
      icon: <GroupOutlined />,
    },
    {
      title: "About Us",
      route: "company",
      icon: < Business/>,
    },
    {
      title: "Give Feedback",
      route:
        "https://docs.google.com/forms/d/e/1FAIpQLSeVlsOMy3STWYYiWkriHid5XV2omo5Gqw6fNO8c827giJhp6Q/viewform",
      icon: <SupportRounded />,
    },
    {
      title: "Privacy",
      route: "privacy",
      icon: <PrivacyTip />,
    },
  ];

  const list = () => (
    <List sx={{ mt: 1 }}>
      <ListItem
        onClick={toggleDrawer(false)}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <ListItemText>
          <Box
            component="img"
            sx={{
              height: {
                xs: "50px",
                display: "flex",
                alignItems: "center",
              },
            }}
            src={logo}
          />
        </ListItemText>
      </ListItem>
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => toRoute(item.route)}
          sx={{
            alignItems: "center",
            p:2,
            display: "flex",
            justifyContent: "space-between",
          }}
          InputProps={{
            startAdornment: item.icon,
          }}
        >
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6">{item.title}</Typography>
          </ListItemText>
        </MenuItem>
      ))}
    </List>
  );

  return (
    <Box display="flex" alignItems="flex-end">
      <IconButton onClick={toggleDrawer("top", true)}>
        <Menu />
      </IconButton>
      <Drawer anchor={"top"} open={state} onClose={toggleDrawer("top", false)}>
        {list()}
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
