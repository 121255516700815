import { Switch } from "react-router-dom";
import Header from "./Components/Header/Header";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Route } from "react-router-dom";
import theme from "theme";
import Register from "views/Register/Register";
import Login from "views/Login/Login";
import Dashboard from "views/Projects/Dashboard";
import ProtectedRoute from "Components/ProtectedRoute/ProtectedRoute";
import Landing from "views/FrontPages/Landing/Landing";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Integrations from "views/Integrations/Integrations";
import IntegrationsList from "views/FrontPages/Integrations/Integrations";
import AnalyticsDashboard from "views/Analytics/Dashboard/AnalyticsDashboard";
import RedirectLanding from "views/RedirectLanding/RedirectLanding";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PrivacyPolicy from "views/Legal/PrivacyPolicy";
import { useDispatch } from "react-redux";
import {
  fetchEntities,
  setSelectedEntity,
} from "store/actions/entityActions.ts";
import { setUser } from "store/actions/userActions.ts";
import { setIsLoggedIn } from "store/actions/authActions.ts";
import { isAuthTokenValid } from "Utils/data/authToken";
import sessionStorageUtils from "Utils/sessionStorage";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom";
import LandingHeader from "views/FrontPages/components/LandingHeader/LandingHeader";
import userService from "services/userService.ts";
import headerUtils from "Components/Header/components/headerUtils";
import sessionStorage from "Utils/sessionStorage";
import Disclaimers from "views/Legal/Disclaimers";
import Settings from "views/Settings/Settings";
import GraphBuilder from "views/GraphBuilder/GraphBuilder";
import { useSelector } from "react-redux";
import { setNavMenuSize } from "store/actions/navigationActions.ts";
import FAQ from "views/FAQ/FAQ";
import ProjectSettings from "views/ProjectSettings/ProjectSettings";
import UpgradeModal from "Components/Marketing/UpgradeModal/UpgradeModal";
import SnackbarMessage from "Components/SnackbarMessage/SnackbarMessage";
import Chat from "views/Chat/Chat";
import Footer from "views/FrontPages/components/Footer";
import Company from "views/FrontPages/Company/Company";
import Community from "views/FrontPages/Community/Community";
import Pricing from "views/Pricing/Pricing";
import FrontPricing from "views/FrontPages/Pricing/Pricing";
import NewFeature from "views/NewFeature/NewFeature";
import AdMgt from "views/AdMgt/AdMgt";

const App = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state?.messaging?.message);
  const location = useLocation();
  const drawerWidth = useSelector((state) => state.navigation?.drawerWidth);
  const [navType, setNavType] = useState("none");
  const toggleNavigation = () => {
    const currentURL = window.location.pathname; // Use pathname instead of href
    const noNav = /^\/(redirect|hello)\/?$/.test(currentURL);
    const backNav = /^\/(privacy|disclaimer|faq)\/?$/.test(currentURL);
    const toolsNav = /graph/.test(currentURL);
    if (noNav) {
      setNavType("none");
    } else if (backNav) {
      setNavType("back");
    } else if (toolsNav) {
      setNavType("tools");
    } else {
      const result =
        /^\/(login|register|password|hello|privacy|redirect|disclaimer|company|community|pricing|integrations)\/?$/.test(
          currentURL
        );
      if (!result) {
        setNavType("authorized");
      } else {
        setNavType("unauthorized");
      }
    }
  };

  const showFooter = () => {
    const currentURL = window.location.pathname; // Use pathname instead of href
    const visible =
      /^\/(hello|faq|company|community|pricing|integrations)\/?$/.test(
        currentURL
      );
    return visible;
  };

  const loadSessionData = async () => {
    const authStorage = sessionStorageUtils.getAuthStorage();
    const sessionUser = await userService.getUserByUserId(authStorage.userId);
    if (sessionUser) {
      const userId = sessionUser.data?.dataValues?.id;
      sessionStorageUtils.setUserSessionStorage(sessionUser.data?.dataValues);
      dispatch(setUser(sessionUser.data?.dataValues)); // Replace with your actual fetchUser action

      dispatch(fetchEntities(userId)); // Replace with your actual fetchEntities action
      let selectedEntity = await headerUtils.extractEntityIdFromUrl()
        ?.dataValues;
      if (!selectedEntity) {
        selectedEntity = sessionStorage.getSelectedEntity();
      }
      selectedEntity && dispatch(setSelectedEntity(selectedEntity));
    }
  };

  useEffect(() => {
    // Check auth token validity and set isLoggedIn accordingly
    const authToken = sessionStorageUtils.getAuthStorage();
    const isValidToken = isAuthTokenValid(authToken);
    dispatch(setIsLoggedIn(isValidToken));
    // If the token is valid, fetch user and entities data
    if (isValidToken) {
      loadSessionData();
    }
  }, [dispatch]);

  const getNavType = () => {
    switch (navType) {
      case "none":
        return null;
      case "back":
        return null;
      case "authorized":
        dispatch(setNavMenuSize(240));
        return <Header drawerWidth={drawerWidth} />;
      case "tools":
        dispatch(setNavMenuSize(340));
        return <Header drawerWidth={drawerWidth} />;
      default:
        return <LandingHeader />;
    }
  };
  useEffect(() => {
    // Update showHeader whenever the URL changes
    toggleNavigation();
  }, [location]);
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <Box display="flex">
            <CssBaseline />
            {getNavType()}
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                mt: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
              }}
            >
              {/* <Toolbar /> */}
              <Switch>
                <Route exact path="/hello" component={Landing} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                <Route exact path="/disclaimer" component={Disclaimers} />
                <Route exact path="/faq" component={FAQ} />
                <Route exact path="/company" component={Company} />
                <Route exact path="/community" component={Community} />
                <Route exact path="/pricing" component={FrontPricing} />
                <Route
                  exact
                  path="/integrations"
                  component={IntegrationsList}
                />
                <Route exact path="/dashboard" component={Dashboard} />
                <ProtectedRoute
                  exact
                  path="/redirect"
                  component={RedirectLanding}
                />
                <ProtectedRoute
                  path="/entity/analytics/dashboard"
                  component={AnalyticsDashboard}
                />
                <ProtectedRoute
                  path="/entity/analytics/project-settings"
                  component={ProjectSettings}
                />
                <ProtectedRoute
                  path="/entity/analytics/integrations"
                  component={Integrations}
                />
                <ProtectedRoute
                  path="/entity/analytics/pricing"
                  component={Pricing}
                />
                <ProtectedRoute path="/settings" component={Settings} />
                <ProtectedRoute
                  path="/entity/analytics/graph"
                  component={GraphBuilder}
                />
                <ProtectedRoute
                  path="/entity/analytics/cohorts"
                  component={NewFeature}
                />
                <ProtectedRoute
                  path="/entity/analytics/ads-management"
                  component={AdMgt}
                />
                {/* <ProtectedRoute
                  path="/entity/analytics/reporting"
                  component={NewFeature}
                /> */}
                {/* <ProtectedRoute
                  path="/entity/analytics/chat"
                  component={Chat}
                /> */}
                <Redirect from="/" to="/hello" />
              </Switch>
            </Box>
            <UpgradeModal />
          </Box>
          {showFooter() && <Footer />}
          <SnackbarMessage message={message} />
        </ThemeProvider>
      </LocalizationProvider>
    </Box>
  );
};

export default App;
