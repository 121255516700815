import { Box, Toolbar } from "@mui/material";
import React from "react";
const PrivacyPolicy = () => {
  return (
    <Box height="100vh" sx={{backgroundColor:'white', pb:12}}>
      <Toolbar/>
    <iframe src="./privacyPolicy.html" frameborder="0" width="100%" height="100%"/>
    </Box>
  );
};

export default PrivacyPolicy;
