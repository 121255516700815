import { Box, Container, Grid, Tooltip, Typography } from "@mui/material";
import CTA from "Components/CTA/CTA";
import FacebookLogo from "assets/Facebook Brand Asset Pack 2019/f-Logos-2019-1/f_Logo_Online_04_2019/Color/PNG/f_logo_RGB-Blue_1024.png";
import ShopifyLogo from "assets/ShopifyBag/svg/shopify_glyph.svg";
import GA4Logo from "assets/google-analytics-logo.png";
import AmazonLogo from "assets/amazon-icon.svg";
import SquareSpaceLogo from "assets/SquareSpace.png";
import { useEffect } from "react";
const Integrations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const integrationList = [
    {
      name: "Facebook Ads",
      description: "Campaign Data for Facebook Ads",
      logo: FacebookLogo,
      tags: ["marketing"],
    },
    {
      name: "Shopify",
      description: "Order and Customer data from Shopify",
      logo: ShopifyLogo,
      tags: ["pos", "marketplace", "website"],
    },
    {
      name: "Google Analytics (GA4)",
      description: "Website analytics data from GA4",
      logo: GA4Logo,
      tags: ["analytics", "website"],
    },
    {
      name: "Amazon Seller Central",
      logo: AmazonLogo,
    },
    {
      name: "SquareSpace",
      logo: SquareSpaceLogo,
    },
  ];
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        my: 13,
      }}
    >
      <Typography
        fontWeight={700}
        sx={{ fontSize: { xs: "48px", sm: "56px" }, lineHeight: 1.1 }}
        textAlign={{ xs: "left" }}
      >
        Integrations.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5 }}
        fontWeight={700}
        textAlign="center"
        color="onSurface"
        mt={1}
      >
        Platforms and services that we work with, with more being added as fast
        as possible.
      </Typography>
      <Grid
        container
        display="flex"
        justifyContent={{ xs: "flex-start", sm: "center" }}
        columnSpacing={4}
        rowSpacing={3}
        sx={{ minHeight: { xs: "0vh", sm: "30vh" }, my: { xs: 1, sm: 5 } }}
      >
        {integrationList.map((item) => {
          return (
            <Grid
              item
              xs={3}
              sm={2}
              md={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: { xs: "flex-start", sm: "center" },
                alignItems: "center",
              }}
            >
              <Tooltip
                sx={{ fontSize: "20px" }}
                title={<Typography>{item.name}</Typography>}
              >
                <Box
                  component="img"
                  src={item.logo}
                  sx={{
                    width: { xs: "40px", sm: "50px" },
                    height: { xs: "40px", sm: "50px" },
                  }}
                  width="50px"
                  height="50px"
                />
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "16px", sm: "20px" }, lineHeight: 1.5 }}
        fontWeight={700}
        textAlign="center"
        color="onSurface"
        mt={1}
        mb={3}
      >
        Don't see the integration you need? Sign up and submit a request. We'll
        get it connected.{" "}
      </Typography>
      <CTA hideSubText />
    </Container>
  );
};

export default Integrations;
