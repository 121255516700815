import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getOAuthToken = async (entityId: string, shopUri: string, code: string) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/shopify/oauth`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      entityId,
      shopUri,
      code,
    };

    const response = await axios.post(uri, body, config);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getShopifyAccountsByEntityId = async (entityId: string) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/shopify/account?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.get(uri, config);
    return response;
  } catch (error) {
    console.error(error);
  }
};


export default {
  getOAuthToken,
  getShopifyAccountsByEntityId,
};
