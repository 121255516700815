import { getAuthToken } from "Utils/data/authToken";
import subscriptionUtils from "Utils/subscriptionUtils";
import axios from "axios";

const createCheckoutSession = async ({ entityId, planDetails }) => {
  try {
    const { provider, account }: any =
      await subscriptionUtils.determineProvider(entityId);
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/payments/create-checkout-session`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      entityId: entityId,
      planDetails,
      paymentProvider: provider,
      thirdPartyAccount: account,
    };

    const session = await axios.post(uri, body, config);
    return session;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const checkOrderCount = async (entityId: number) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/payments/order-count?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.get(uri, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const getSubscriptionDetails = async ({ entityId }: any) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/payments/subscription?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.get(uri, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const cancelPlan = async (entityId: number) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/payments/subscription/cancel?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.delete(uri, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteStoredSubscription = async (entityId: number) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/payments/subscription?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.delete(uri, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateEntitySubscriptionStatusById = async ({
  entityId,
  status,
}: any) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/entity/subscription`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      entityId,
      status,
    };

    const response = await axios.post(uri, body, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const changePlan = async ({ entityId, provider, newPlan }: any) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/payments/subscription/update`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      newPlan,
      entityId,
      provider,
    };

    const response = await axios.post(uri, body, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const setupShopifySubscription = async ({
  entityId,
  chargeId,
  shop,
  planId,
}) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/payments/subscription/${chargeId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      entityId,
      shop,
      planId,
    };
    const response = await axios.post(uri, body, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export default {
  createCheckoutSession,
  checkOrderCount,
  getSubscriptionDetails,
  updateEntitySubscriptionStatusById,
  cancelPlan,
  deleteStoredSubscription,
  changePlan,
  setupShopifySubscription,
};
