import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getAdsDataByTypeEntityIdOptPlatform = async (
  entityId: string,
  type: string,
  platform: string | null
) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/advertising/adItems?entityId=${entityId}&type=${type}`;
    if (platform) {
      uri.concat(`&platform=${platform}`);
    }
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    throw error;
  }
};

export default {
  getAdsDataByTypeEntityIdOptPlatform,
};
