import { PrivacyTip } from '@mui/icons-material';
import { Button, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { bool } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicyBtn = ({ disableIcon, fullWidth, listItem }) => {
  if (listItem) {
    return (
      <ListItem disablePadding disableGutters fullWidth>
        <ListItemButton component={Link} to="/privacy">
          <ListItemIcon>
            <PrivacyTip />
          </ListItemIcon>
          <ListItemText>Privacy</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  }
  return (
    <Button component={Link} to="/privacy" startIcon={disableIcon ? null : <PrivacyTip />} fullWidth={fullWidth}>
      Privacy
    </Button>
  );
};

PrivacyPolicyBtn.propTypes = {
  disableIcon: bool,
  fullWidth: bool,
  listItem: bool,
};

PrivacyPolicyBtn.defaultProps = {
  disableIcon: false,
  fullWidth: true,
  listItem: false,
};

export default PrivacyPolicyBtn;
