import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import constants from "Utils/constants";
import subscriptionUtils from "Utils/subscriptionUtils";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const CancelPlanModal = () => {
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState("");
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();

  return (
    <>
      <Button onClick={() => setOpen(true)}>Cancel Plan</Button>
      <Dialog fullWidth open={open}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Are you sure you want to cancel?</Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
              setConfirm("");
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ListItem variant="body1">
            Cancelling your plan will downgrade your project to the Starter Tier.
          </ListItem>
          <ListItem variant="body1">
            Your project will be limited to 2 integrations and{" "}
            {constants.FREE_ORDER_LIMIT} orders per month, which may not fit
            current rate of orders / month for your business.
          </ListItem>
          <ListItem variant="body1">
            Because of free project limitations, existing integrations will be
            removed and current project data will be deleted. You will need to
            re-add integrations and allow data to synchronize again.
          </ListItem>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <TextField
            onChange={(e) => setConfirm(e.target.value)}
            fullWidth
            size="small"
            placeholder="type 'cancel' to confirm"
            sx={{ mr: 2 }}
          />
          <Button
            disabled={confirm !== "cancel"}
            variant="outlined"
            onClick={() => subscriptionUtils.handleDowngrade({
              dispatch,
              entity,
              user,
              setOpen,
              plan: { id: "free" },
            })}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelPlanModal;
