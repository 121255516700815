import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getOauthToken = async (entityId: string, code: string, tokenId: number | null) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/facebook/oauth`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const body = {
      entityId,
      code,
      tokenId,
    };
    
    // Make the API call and await the response
    const response = await axios.post(uri, body, config);

    // Check if the response is successful
    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

export default {
    getOauthToken
}
