import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Card } from "@mui/material";
import { useSelector } from "react-redux";
import { Line, LineChart, LineSeries } from "reaviz";
import MetricCard from "Components/Board/components/MetricCard/MetricCard";
import Chart from "react-apexcharts";
import DateRangeSelector from "views/Analytics/Dashboard/Components/Displays/Widgets/DateRangeSelector";

const GraphBuilder = () => {
  const selectedMetrics = useSelector((state) => state?.graph?.selectedMetrics);
  const [type, setType] = useState('area');
  const startDate = useSelector(state=> state.metrics.primaryStartDate);
  const endDate = useSelector(state => state.metrics.primaryEndDate);
  const options = {
    series: selectedMetrics?.map((data) => {
      return {
        name: data?.name?.en,
        data: data?.byDate?.map((entry) => ({
          x: new Date(entry?.key).getTime(),
          y: entry?.data ?? 0,
        })),
      };
    }),
    chart: {
      type: type ?? "area",
      stacked: false,
      width: 400,
      height: 400,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false, // Hide the menu options
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val >= 1000000) {
            return (val / 1000000).toFixed(1) + "M";
          } else if (val >= 1000) {
            return (val / 1000).toFixed(1) + "K";
          }
          return val.toFixed(1);
        },
      },
    },
    xaxis: {
      type: "datetime",
      min: new Date(startDate).getTime(), // Ensure startDate and endDate are correctly utilized
      max: new Date(endDate).getTime(),
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      theme: "dark", // Use 'dark' or 'light' theme or provide custom colors
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
  };


  return (
    <Container>
      <DateRangeSelector/>
      <Box display="flex" alignItems="center"></Box>
      <Grid container>
        <Grid item xs={12}>
          <Chart options={options} series={options.series} type={type} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default GraphBuilder;
