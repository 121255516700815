import { Box, Container, Typography } from "@mui/material";
import CTA from "Components/CTA/CTA";
import React, { useEffect } from "react";
import MissionStatement from "views/FrontPages/Landing/components/MissionStatement";

const Company = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        my: 10,
      }}
    >
      <Typography
        fontWeight={700}
        sx={{ fontSize: { xs: "48px", sm: "56px" }, lineHeight: 1.1 }}
        textAlign={{ xs: "left" }}
      >
        Who we are.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5 }}
        fontWeight={700}
        textAlign="center"
        color="white"
        mt={1}
        mb={3}
      >
        We are a company birthed in the early years of mass AI adoption. We're
        wild about entrepreneurship, creating real value for customers, and the
        people willing to take big risks to improve their lives and the lives of
        others.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5 }}
        fontWeight={700}
        textAlign="center"
        color="white"
        mt={1}
        mb={3}
      >
        And we want to help.
      </Typography>
      <MissionStatement />
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5, mt: 6 }}
        fontWeight={700}
        textAlign="center"
        color="white"
        mt={1}
        mb={3}
      >
        We've coined a term for this kind of futuristic business model:
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5, mt: 3 }}
        fontWeight={700}
        textAlign="center"
        mt={1}
        mb={3}
      >
        "Sentient Commerce"
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5, mt: 3 }}
        fontWeight={700}
        textAlign="center"
        color="white"
        mt={1}
        mb={3}
      >
        The technological successor to E-Commerce.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5, mt: 3 }}
        fontWeight={700}
        textAlign="center"
        color="white"
        mt={1}
        mb={3}
      >
        Sounds like a moonshot, right?
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5, mt: 3 }}
        fontWeight={700}
        textAlign="center"
        color="white"
        mt={1}
        mb={3}
      >
        ...maybe, but we're doing it anyway.
      </Typography>
      <Typography
        textAlign="center"
        color="white"
        sx={{
          fontSize: "32px",
          fontWeight: 600,
          mt: 0,
          pt: 0,
          lineHeight: 0.9,
          mb: 6,
          mt:3
        }}
      >
        Join us in building the future of e-commerce.
      </Typography>
      <Box sx={{ width: { xs: "100vw, sm: 50%" } }}>
        <CTA />
      </Box>
    </Container>
  );
};

export default Company;
