import { Close } from "@mui/icons-material";
import { IconButton, Snackbar } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";

const SnackbarMessage = () => {
  const message = useSelector((state) => state.messaging?.message);
  const dispatch = useDispatch();
  const handleCloseSnackbar = () => {
    dispatch(setMessage(""));
  };
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackbar}
    >
      <Close fontSize="small" />
    </IconButton>
  );
  return (
    <Snackbar
      open={!!message}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={message}
      action={action}
    />
  );
};

export default SnackbarMessage;
