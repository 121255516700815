import { Divider, Grid } from "@mui/material";

const Billing = () => {
  return (
    <Grid
      container
      columns={12}
      disableGutters
      sx={{
        p: 1,
        mt: 3,
      }}
      display="flex"
      flexDirection="column"
    >
      <Divider fullWidth sx={{ mt: 2 }} />
    </Grid>
  );
};

export default Billing;
