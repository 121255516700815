import { Button, Container, Grid, Typography } from "@mui/material";
import CTA from "Components/CTA/CTA";
import React, { useEffect } from "react";

const Community = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        my: 16,
      }}
    >
      <Typography
        fontWeight={700}
        sx={{ fontSize: { xs: "48px", sm: "56px" }, lineHeight: 1.1 }}
        textAlign={{ xs: "left" }}
      >
        Support.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5 }}
        fontWeight={700}
        textAlign="center"
        
        mt={6}
        mb={3}
      >
        We support an open community that fosters the ability to get help, form
        partnerships, make friends, and collaborate with both support agents and
        business peers on your projects. With this in mind, we use Slack to
        facilitate all communication.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: { xs: "20px", sm: "24px" }, lineHeight: 1.5 }}
        fontWeight={700}
        textAlign="center"
        
        mb={3}
      >
        {" "}
        We don't require a Vendanza account to join the community (although we
        do encourage it).
      </Typography>
      <Grid container display="flex" justifyContent="center">
        <Grid item sx={{ p: 3 }}>
          <Button
            variant="contained"
            size="large"
            onClick={() =>
              (window.location.href =
                "https://join.slack.com/t/vendanza-workspace/shared_invite/zt-2muqfk0nq-jMMxMrbE9NhlNfmysti9Yg")
            }
          >
            Join the Community
          </Button>
        </Grid>
        <Grid item sx={{ p: 3 }}>
          <CTA hideSubText />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Community;
