import { Container, Link, Typography } from "@mui/material";
import React from "react";

const Disclaimers = () => {
  return (
    <Container sx={{height:"90vh",  mt:6}}>
      <Typography variant="h5">Disclaimers</Typography>

        <Typography  sx={{ mt: 3 }} variant="h6">
            Google API
        </Typography>
      <Typography
        color="lightgray"
      >
        Vendanza use and transfer to any other app of information received from
        Google APIs will adhere to{" "}
        <Link href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
          Google API Services User Data Policy,
        </Link>{" "}
        including the Limited Use requirements.
      </Typography>
    </Container>
  );
};

export default Disclaimers;
