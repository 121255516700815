import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ga4Service from "services/ga4Service.ts";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";
import oAuth from "./utils/oAuthUtils";
import IntegrationTitle from "Components/Integrations/IntegrationAccount/IntegrationTitle";
import googleAnalyticsLogo from "assets/googleAnalyticsImg.svg";
import GA4AccountGroup from "./components/GA4AccountGroup";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const GAConfig = ({ onUpdate }) => {
  const entityId = useSelector((state) => state.entity?.selectedEntity?.id);
  const [show, setShow] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const handleGetAccountFailure = (result) => {
  };

  const getStoredAccounts = async () => {
    try {
      const storedAccounts =
        await thirdPartyAccountService.getStoredAccountsByEntityIdPlatform(
          entityId,
          "GA4"
        );
      setSelectedAccounts(storedAccounts.data ?? []);
    } catch (err) {
      setStatus(err.message);
    } finally {
      await onUpdate();
    }
  };

  const getProperties = async () => {
    try {
      setLoading(true);
      const result = await ga4Service.getProperties(entityId);
      if (result) {
        setAccounts(result);
      } else {
        handleGetAccountFailure(result);
      }
    } catch (err) {
      setStatus(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show === true) {
      getProperties();
      getStoredAccounts();
    }
  }, [show]);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const platformParam = searchParams.get('platform');
    
    if (platformParam && platformParam === 'GA4') {
      // If the "platform" query parameter is present in the URL, set "open" to true
      setShow(true);
    }
  }, [location.search]);

  const handleClose = () => {};
  return (
    <>
      <Button onClick={() => setShow(true)}>Configure</Button>
      <Dialog onClose={handleClose} fullWidth open={show}>
        <IntegrationTitle
          title={"GA4 Accounts"}
          handleClose={() => setShow(false)}
          logo={googleAnalyticsLogo}
        />

        <DialogContent>
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={200}
              animation="pulse"
              sx={{ borderRadius: "10px" }}
            />
          ) : (
            accounts?.map((acct, idx) => {
              return (
                <GA4AccountGroup
                  acctDetails={acct}
                  idx={idx}
                  storedAccounts={selectedAccounts}
                  onUpdate={getStoredAccounts}
                  onDelete={getStoredAccounts}
                />
              );
            })
          )}
          <Typography sx={{mt:3, fontSize: '12px'}} color="lightgray" variant="subtitle2" >
            Vendanza use and transfer to any other app of information received
            from Google APIs will adhere to{' '}
            <Link href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
              Google API Services User Data Policy,
              </Link>{' '}
            including the Limited Use requirements.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "flex-start", p: 3 }}
        >
          <Button onClick={() => oAuth.handleLogin()} startIcon={<Add />}>
            New GA4 Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GAConfig;
