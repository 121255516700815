import constants from "./constants";

const getMonthlyOrderLimit = (subscriptionStatus) => {
  switch (subscriptionStatus) {
    case constants.FREE_PLAN:
      return 500;
    case constants.CORE_PLAN:
      return 2500;
    case constants.PRO_PLAN:
      return 15000;
    default:
      return 0;
  }
};

const mapSubscriptionStatusToName = (subscriptionStatus) => {
  switch (subscriptionStatus) {
    case "free":
      return "Starter";
    case "Core":
      return "Core";
    case "Pro":
      return "Pro";
    default:
      return "Unknown";
  }
};

export default { getMonthlyOrderLimit, mapSubscriptionStatusToName };
