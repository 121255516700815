import boardService from "Api/boards/boardService.ts";

export const setBoards = (boards: any) => ({
  type: "SET_BOARDS",
  payload: boards,
});
export const getBoards = (entityId: number, view: string) => async (dispatch: any) => {
  try { 
    const boards = await boardService.getBoardsForEntity(entityId, view);
    dispatch(setBoards(boards.data));
  } catch (error) {
    throw error;
  }
};
