import { Button } from "@mui/material";
import AmazonConfig from "./AmazonConfig";
import amazonUtils from "./amazonUtils";
import ConnectActionBtn from "Components/Integrations/ConnectActionBtn/ConnectActionBtn";

const Action = ({ accounts, onUpdate }) => {
  if (!accounts.length) {
    return (
      <ConnectActionBtn onContinue={() => amazonUtils.handleLogin()}>
        Connect
      </ConnectActionBtn>
    );
  }
  return <AmazonConfig onUpdate={onUpdate} />;
};

export default Action;
