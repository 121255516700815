// store/reducers/index.js
import { combineReducers } from "redux";
import userReducer from "./userReducer.ts";
import entityReducer from "./entityReducer.ts";
import authReducer from "./authReducer.ts";
import navigationReducer from "./navigationReducer.ts";
import metricsReducer from "./metricsReducer.ts";
import loadingReducer from "./loadingReducer.ts";
import boardReducer from "./boardReducer.ts";
import thirdPartyAccountsReducer from "./thirdPartyAcctsReducer.ts";
import errorReducer from "./errorReducer.ts";
import graphReducer from "./graphReducer.ts";
import marketingReducer from "./marketingReducer.ts";
import messageReducer from "./messageReducer.ts";
import chatReducer from "./chatReducer.ts";
import advertisingReducer from "./advertisingReducer.ts";

const appReducer = combineReducers({
  user: userReducer,
  entity: entityReducer,
  auth: authReducer,
  navigation: navigationReducer,
  metrics: metricsReducer,
  loading: loadingReducer,
  boards: boardReducer,
  thirdPartyAccounts: thirdPartyAccountsReducer,
  errors: errorReducer,
  graph: graphReducer,
  marketing: marketingReducer,
  messaging: messageReducer,
  chat: chatReducer,
  ads: advertisingReducer,
  // Add more reducers here if needed
});

// wrapper around app reducer
const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_STORE") {
    // Reset the entire Redux store to its initial state
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
