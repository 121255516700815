import constants from "Utils/constants";

const pricing = [
  {
    rank: 0,
    planName: "Starter",
    id: "free",
    planDescription: "The basics, free forever.",
    price: 0,
    orderLimit: constants.FREE_ORDER_LIMIT,
    inclusions: [
      "2 free projects",
      "up to 2 integrations for free projects",
      "Advanced Analytics",
      "Customizable dashboard",
      "Data-driven insights",
      `Up to ${constants.FREE_ORDER_LIMIT} orders / month`,
      "Historical data up to 3 years",
      "Community Support",
    ],
  },
  {
    rank: 1,
    planName: "Core",
    highlight: true,
    id: "Core",
    planDescription: "For growing businesses looking to scale.",
    price: 99,
    discount: 49,
    orderLimit: constants.CORE_ORDER_LIMIT,
    stripePriceId: process.env.REACT_APP_STRIPE_CORE_PRICE_ID,
    inclusions: [
      "Everything in Starter, Plus:",
      "Unlimited integrations for paid projects",
      "Cohort analysis",
      "Custom alerts",
      `Up to ${constants.CORE_ORDER_LIMIT} orders / month`,
      "Priority support",
    ],
  },
  {
    rank: 2,
    planName: "Pro",
    id: "Pro",
    planDescription: "For agencies and businesses with more than $1M ARR.",
    price: 199,
    discount: 99,
    orderLimit: constants.PRO_ORDER_LIMIT,
    stripePriceId: process.env.REACT_APP_STRIPE_PRO_PRICE_ID,
    inclusions: [
      `Everything in Core, Plus:`,
      `No monthly order limit`,
      `On-demand support`
    ],
  },
];

export default pricing;
