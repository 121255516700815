import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import oAuthService from "services/oAuthService.ts";
import { setMessage } from "store/actions/messageActions.ts";

const AccountGroupName = ({ integration, idx, disabled }) => {
  const dispatch = useDispatch();
  const [editName, setEditName] = useState(false);
  const [name, setName] = useState(integration?.name ?? null);
  const [status, setStatus] = useState("");
  const textFieldRef = useRef(null); // Create a ref for the TextField

  // Account group name functions

  const handleClickShowField = () => {
    setEditName(true);
    // Focus the TextField when 'Show Field' is clicked
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, 0);
  };

  const updateIntegrationName = async () => {
    try {
      dispatch(setMessage('Updating Facebook Account Nickname...'))
      const result = await oAuthService.updateIntegrationName(
        name,
        integration?.tokenId
      );
      setName(result.data?.dataValues?.name);
      setTimeout(
        () => dispatch(setMessage('Facebook Account Nickname Updated!')),
        1000
      )
    } catch (error) {
      dispatch(setMessage(`Nickname update failed: ${error.message}`));
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      updateIntegrationName(name);
      setEditName(false);
    }
  };
  
  const handleBlur = () => {
    setEditName(false);
    setName(integration?.name ?? `Account ${idx + 1}`);
  };

  return (
          <Box display="flex" alignItems="center">
            {editName ? (
              <TextField
                size="small"
                label="Nickname"
                onChange={(e) => setName(e.target.value)}
                onKeyDown={handleEnterKeyPress}
                onBlur={handleBlur}
                inputRef={textFieldRef}
                disabled={disabled}
              />
            ) : (
              <>
                <Typography variant="h6">
                  {name ?? `Account ${idx + 1}`}
                </Typography>

                <IconButton
                  onClick={handleClickShowField}
                  size="small"
                  sx={{ ml: 1 }}
                  disabled={disabled}
                >
                  <Edit sx={{ fontSize: "16px" }} />
                </IconButton>
              </>
            )}
          </Box>
  );
};

export default AccountGroupName;
