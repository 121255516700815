const initialState = {
  loadingData: true,
  loadingDates: true,
};

const loadingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_LOADING_DATA":
      return {
        ...state,
        loadingData: action.payload,
      };
    default:
      return state;
  }
};

export default loadingReducer;
