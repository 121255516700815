import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import facebook from "Api/integration/facebook.ts";
import React, { useEffect, useState } from "react";
import facebookUtils from "./facebookUtils";
import { useSelector } from "react-redux";
import AccountGroup from "../../../../Components/Integrations/GroupedAccount/AccountGroup";
import facebookLogo from "assets/Facebook Brand Asset Pack 2019/f-Logos-2019-1/f_Logo_Online_04_2019/Color/PNG/f_logo_RGB-Blue_1024.png";
import IntegrationTitle from "Components/Integrations/IntegrationAccount/IntegrationTitle";
import { useDispatch } from "react-redux";

const FacebookConfig = ({ onUpdate }) => {
  const entityId = useSelector((state) => state.entity?.selectedEntity?.id);
  const [show, setShow] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [creds, setCreds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [statusIcon, setStatusIcon] = useState(null);
  const getStoredCredentials = async () => {
    try {
      setLoading(true);
      const result = await facebook.getStoredCredentials(entityId);
      setLoading(false);
      setCreds(result ?? []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNewAccount = async () => {
    facebookUtils.handleLogin();
  };

  const handleUpdate = async () => {
    await onUpdate();
  };

  useEffect(() => {
    if (show) {
      getStoredCredentials();
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    setStatus("");
    setStatusIcon(null);
  };

  return (
    <>
      <Button onClick={() => setShow(true)}>Configure</Button>
      <Dialog onClose={handleClose} fullWidth open={show}>
        <IntegrationTitle
          title={"Facebook Ad Accounts"}
          handleClose={() => setShow(false)}
          logo={facebookLogo}
        />

        <DialogContent>
          {creds?.map((integration, idx) => {
            return (
              <AccountGroup
                key={integration?.tokenId}
                integration={integration}
                idx={idx}
                onUpdate={handleUpdate}
              />
            );
          })}
          {creds.length === 0 && (
            <Box display="flex" alignItems="center">
              <Typography>
                You don't have any connected Facebook accounts.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{ p: 3, display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={handleAddNewAccount} startIcon={<Add />}>
            New Facebook Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FacebookConfig;
