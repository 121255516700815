import {
  Box,
  Divider,
  IconButton,
  List,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getChatHistoryByEntityId,
  setCurrentChat,
  setMessages,
} from "store/actions/chatActions.ts";
import ChatHistoryItem from "./ChatHistoryItem";
import {
  BookmarksOutlined,
  BookmarksRounded,
  ChatOutlined,
  ChatRounded,
  DriveFileRenameOutline,
} from "@mui/icons-material";
import chatService from "services/chatService.ts";
import { setMessage } from "store/actions/messageActions.ts";

const ChatHistory = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const chatsHistory = useSelector((state) => state?.chat?.chatsHistory);
  useEffect(() => {
    dispatch(getChatHistoryByEntityId(entityId));
  }, [entityId]);

  const newChat = async () => {
    try {
      // Create a new chat
      // Reload the chat history
      const newChat = await chatService.createChat(entityId);
      setTab(0);
      dispatch(setCurrentChat(newChat.data?.dataValues));
      dispatch(setMessages([]));
      dispatch(getChatHistoryByEntityId(entityId));
    } catch (error) {
      dispatch(setMessage(`Failed to create chat: ${error.message}`));
    }
  };

  return (
    <>
        <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        p={2}
        >
      <Tooltip arrow placement="top-end" title="Chats">
        <IconButton
          variant={tab === 0 ? "contained" : "outlined"}
          onClick={() => setTab(0)}
          color={tab === 0 ? "primary" : "default"}
        >
          {tab === 0 ? <ChatRounded /> : <ChatOutlined/>}
        </IconButton>
      </Tooltip>
      {/* <Tooltip arrow placement="top-end" title="Saved Functions">
        <IconButton
          variant={tab === 1 ? "contained" : "outlined"}
          onClick={() => setTab(1)}
          color={tab === 1 ? "primary" : "default"}

        >
          {tab === 1 ? <BookmarksRounded /> : <BookmarksOutlined/>}
        </IconButton>
      </Tooltip> */}
            <Tooltip arrow placement="top" title="New Chat">
              <IconButton onClick={newChat}>
                <DriveFileRenameOutline />
              </IconButton>
            </Tooltip>
          </Box>
            {tab === 0 && (
          <List>
            {chatsHistory?.map((chat) => {
              return <ChatHistoryItem details={chat.dataValues} />;
            })}
          </List>
      )}
      {tab === 1 && <List></List>}
    </>
  );
};

export default ChatHistory;
