import { legacy_createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk'; // For handling asynchronous actions
import rootReducer from './reducers/index.ts'; // Import your root reducer

// Declare the Redux DevTools extension enhancer property
declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
  }
  
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware];

const store = legacy_createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;

// default configuration file required to use redux dev tools in the browser