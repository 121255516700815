import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import DateRangeSelector from "./Widgets/DateRangeSelector";
import CreateBoard from "Components/CreateBoard/CreateBoard";
import { useSelector } from "react-redux";
import BoardMenu from "Components/Board/components/BoardMenu/BoardMenu";

const AnalyticsHeader = () => {
  const mostRecentPullDate = useSelector(
    (state) => state?.metrics?.mostRecentPullDate
  );
  return (
    <Box>
      <Grid
        container
        display="flex"
        columns={16}
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems="center"
      >
        <Grid
          xs={0}
          sm={2}
          md={3}
          lg={2}
          item
          display={{ xs: "none", lg: "flex" }}
        >
          <BoardMenu variant="contained" create />
        </Grid>
        <Grid item xs={16} lg={13.5} display="flex" justifyContent="flex-end">
          <DateRangeSelector />
        </Grid>
      </Grid>
      <Box display={{ xs: "none", md: "flex" }} justifyContent="flex-end">
        <Typography
          variant="subtitle2"
          color="white"
          fontSize={12}
          sx={{ mt: 0.5 }}
        >
          {mostRecentPullDate && `Latest refresh at ${mostRecentPullDate}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default AnalyticsHeader;
