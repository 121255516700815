import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setShowUpgradeModal,
  setUpgradeModalSubtitle,
  setUpgradeModalTitle,
} from "store/actions/marketingActions.ts";

const ConnectActionBtn = ({ onContinue, text, accounts }) => {
  const integrations = useSelector((state) => state?.thirdPartyAccounts);
  const entity = useSelector((state) => state?.entity?.selectedEntity);
  const dispatch = useDispatch();
  const handleClick = () => {
    // first check if the entity has more available integrations
    if (entity?.subscriptionStatus === "free") {
      if (integrations?.numIntegrations < 2 || accounts?.length) {
        return onContinue();
      } else {
        dispatch(setUpgradeModalTitle(`Uh oh...`));
        dispatch(setShowUpgradeModal(true));
        dispatch(setUpgradeModalSubtitle('You have reached the limit of 2 integrations for the free plan.'));
      }
    } else {
      onContinue();
    }
  };
  return <Button onClick={handleClick}>{text ?? "Connect"}</Button>;
};

export default ConnectActionBtn;
