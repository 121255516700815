import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import graph_1 from "assets/vendanza/walkthrough/graph_1.svg";
import graph_2 from "assets/vendanza/walkthrough/graph_2.svg";
import graph_3 from "assets/vendanza/walkthrough/graph_3.svg";

const StepTwo = () => {


  const ImageContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(10), // Default margin
    [theme.breakpoints.up("lg")]: {
      marginTop: 0, // Margin for large screens and up
    },
  }));

  const ImageBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    transition: "transform 0.3s ease-in-out", // Optional: adds a smooth transition effect
    "&:nth-of-type(1)": {
      left: -275,
      top: -175,
    },
    "&:nth-of-type(2)": {
      left: -175, // Adjust for desired overlap
      top: -125, // Adjust for desired staggering
    },
    "&:nth-of-type(3)": {
      left: -75, // Adjust for desired overlap
      top: -50, // Adjust for desired staggering
    },
  }));

  return (
    <Grid container display="flex" justifyContent="center" sx={{ mt: 20 }}>
      <Grid
        item
        xs={12}
        md={6}
        justifyContent="center"
        alignItems="center"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <ImageContainer>
          <ImageBox
            component="img"
            src={graph_1}
            sx={{ width: "200px" }}
          />
          <ImageBox
            component="img"
            src={graph_2}
            sx={{ width: "200px" }}
          />
          <ImageBox
            component="img"
            src={graph_3}
            sx={{ width: { xs: "140px", sm: "200px" } }}
          />
        </ImageContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              textAlign="right"
              variant="subtitle2"
              fontWeight={600}
              
              mb={0}
              p={0}
            >
              Step Two:
            </Typography>
            <Typography
              textAlign="right"
              sx={{
                fontSize: "48px",
                fontWeight: 600,
                mt: 0,
                pt: 0,
                lineHeight: 0.9,
              }}
            >
              Analyze.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              textAlign="right"
              sx={{ mb: 2, fontSize: "20px", fontWeight: 600 }}
              
            >
              Skip the math part.
            </Typography>
            <Typography
              textAlign="right"
              sx={{ mb: 2, fontSize: "20px", fontWeight: 600 }}
              
            >
              Metrics are automatically calculated to get critical indicators
              like CAC, Revenue, LTV, and dozens more to inform your
              decision-making. All laid out in a 100% customizable dashboard.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepTwo;
