import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import boardService from "Api/boards/boardService.ts";
import ButtonLoader from "Components/ButtonLoader";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBoards } from "store/actions/boardActions.ts";

const CreateBoard = ({variant}) => {
  const dispatch = useDispatch();
  const entityId = useSelector(
    (state) => state?.entity?.selectedEntity?.id
  );
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [statusMsg, setStatusMsg] = useState(null);
  const [boardType, setBoardType] = useState(null);
  const handleClose = () => {
    setShow(false);
    setName("");
    setDescription("");
    setBoardType(null)
  };

  const createBoard = async () => {
    try {
      setLoading(true);
      const newBoard = await boardService.createBoard(
        entityId,
        name,
        description,
        boardType
      );
      if (newBoard) {
        setShow(false);
        setName("");
        setDescription("");
      } else {
        throw new Error("Failed to create new board");
      }
    } catch (error) {
    } finally {
      dispatch(getBoards(entityId));
      setLoading(false);
    }
  };
  return (
    <>
      <Button fullWidth variant={variant} onClick={() => setShow(true)}>
        Add Board
      </Button>
      <Dialog fullWidth open={show}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography>New Board</Typography>
            <Typography variant="subtitle2">
              Customize what metrics you see and how you see them
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Board Name"
            label="Board Name"
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2, mt: 1 }}
            fullWidth
          />
          <TextField
            placeholder="Description (optional)"
            label="Description (optional)"
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
          {/* <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel id="board-type">Board Type</InputLabel>
            <Select
              id="board-type"
              label="Board Type"
              value={boardType}
              fullWidth
            >
              <MenuItem onClick={() => setBoardType("grid")} value="grid">
                Data Grid
              </MenuItem>
              <MenuItem onClick={() => setBoardType("chat")} value="chat">
                Chat
              </MenuItem>
            </Select>
          </FormControl> */}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading || !name} onClick={createBoard}>
            <ButtonLoader text="Create" size={20} loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateBoard;
