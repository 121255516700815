import chatService from "services/chatService.ts";

export const setCurrentChat = (chat: any) => ({
  type: "SET_CURRENT_CHAT",
  payload: chat,
});

export const setMessages = (messages: any) => ({
  type: "SET_MESSAGES",
  payload: messages,
});

export const setChatHistory = (chats: any) => ({
  type: "SET_CHAT_HISTORY",
  payload: chats,
});

export const setLoadingHistory = (loading: boolean) => ({
  type: "SET_LOADING_HISTORY",
  payload: loading,
});

export const pushToMessages = (message: any) => ({
  type: "PUSH_TO_MESSAGES",
  payload: message,
});

export const setLoadingSendMessage = (loading: boolean) => ({
  type: "SET_LOADING_SEND_MESSAGE",
  payload: loading,
});

export const getChatHistoryByEntityId =
  (entityId: number) => async (dispatch: any) => {
    dispatch(setLoadingHistory(true));
    if (!entityId) {
      return;
    }
    try {
      const response = await chatService.getChatsByEntityId(entityId);
      dispatch(setChatHistory(response.data));
    } catch (err) {
    } finally {
      dispatch(setLoadingHistory(false));
    }
  };
