import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Box, Card, Grid, IconButton, Tooltip } from "@mui/material";
import MetricCard from "../MetricCard/MetricCard";
import { Add, Remove, SchoolOutlined } from "@mui/icons-material";
import MetricCardMenu from "../MetricCard/components/MetricCardMenu";
import ShortText from "Components/ShortText/ShortText";
import CreateGoal from "../MetricCard/components/GoalEditor";
import GoalDisplay from "../MetricCard/components/GoalDisplay";

const MetricGridItem = ({ metric, fetchMetrics, comparisonData, boardId }) => {
  const theme = useTheme();
  const [chartView, setChartView] = useState(metric?.chartView);
  const [chartType, setChartType] = useState(metric?.chartType);

  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "lg"));
  const [columnSize, setColumnSize] = useState(isSmallScreen ? 6 : 4);

  useEffect(() => {
    setColumnSize(isSmallScreen ? 6 : 4);
  }, [isSmallScreen]);

  const handleUpsize = () => {
    setColumnSize((prevSize) => prevSize + (isSmallScreen ? 6 : 4));
  };

  const handleDownsize = () => {
    setColumnSize((prevSize) =>
      Math.max(prevSize - (isSmallScreen ? 6 : 4), isSmallScreen ? 6 : 4)
    );
  };
  return (
    <Grid
      item
      sx={{ p: 1, transition: "all 0.3s ease" }}
      xs={12}
      sm={columnSize}
      md={columnSize}
    >
      <Card elevation={0} sx={{ p: 3, transition: "all 0.3s ease" }}>
        <Box
          display="flex"
          textAlign="center"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Box display="flex" alignItems="center">
            <ShortText
              maxLen={10}
              variant="h6"
              text={metric.acronym?.en ?? metric.name?.en}
            />
            {metric?.description && (
              <Tooltip title={`${metric.description?.en}`}>
                <IconButton size="small">
                  <SchoolOutlined fontSize="12px" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box>
            {!isSmallScreen && (
              <>
                <Tooltip title="Reduce">
                  <IconButton
                    size="small"
                    disabled={
                      isSmallScreen ? columnSize === 6 : columnSize === 4
                    }
                    onClick={handleDownsize}
                  >
                    <Remove />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Expand">
                  <IconButton
                    size="small"
                    onClick={handleUpsize}
                    disabled={columnSize === 12}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <MetricCardMenu
              metricId={metric?.id}
              boardId={boardId}
              metric={metric}
              chartType={chartType}
              setChartType={setChartType}
              chartView={chartView}
              setChartView={setChartView}
            />
          </Box>
        </Box>
        {chartView && (
          <MetricCard
            metric={metric}
            comparisonData={comparisonData}
            chartType={chartType}
          />
        )}
        {!chartView && (
          <GoalDisplay metric={metric} comparisonData={comparisonData} />
        )}
      </Card>
    </Grid>
  );
};

export default MetricGridItem;
