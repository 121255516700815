import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

const ChartDisplay = ({
  type,
  data,
  comparisonData,
  setPrimaryDataPt,
  setSecondaryDataPt,
  defaultValue,
  defaultComparisonValue,
}) => {
  const startDate = useSelector((state) => state?.metrics?.primaryStartDate);
  const endDate = useSelector((state) => state?.metrics?.primaryEndDate);
  const mostRecentPullDate = useSelector(state => state.metrics.mostRecentPullDate);

  const options = {
    series: [
      {
        name: "Period",
        data: data.map((entry) => ({
          x: new Date(entry?.key).getTime(),
          y: entry?.data ?? 0,
        })),
      },
      {
        name: "Vs. Period",
        data: comparisonData.map((entry) => ({
          x: new Date(entry?.key).getTime(),
          y: entry?.data ?? 0,
        })),
      },
    ],
    chart: {
      type: type ?? "area",
      stacked: false,
      width: 400,
      height: 400,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false, // Hide the menu options
      },
      events: {
        mouseMove: (event, chartContext, config) => {
            if (config?.config?.series[0]?.data[config.dataPointIndex]?.y) {
                const periodDataPoint =
                config?.config?.series[0]?.data[config.dataPointIndex]?.y;
                const vsPeriodDataPoint =
                config?.config?.series[1]?.data[config.dataPointIndex]?.y;
                setPrimaryDataPt(periodDataPoint);
                setSecondaryDataPt(vsPeriodDataPoint);
            }
        },
        mouseLeave: () => {
          setPrimaryDataPt(defaultValue ?? 0);
          setSecondaryDataPt(defaultComparisonValue ?? 1);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: type === 'area' ? "gradient" : '',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val >= 1000000) {
            return (val / 1000000).toFixed(1) + "M";
          } else if (val >= 1000) {
            return (val / 1000).toFixed(1) + "K";
          }
          return val.toFixed(1);
        },
      },
    },
    xaxis: {
      type: "datetime",
      min: new Date(startDate).getTime(), // Ensure startDate and endDate are correctly utilized
      max: new Date(endDate).getTime(),
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      theme: "dark", // Use 'dark' or 'light' theme or provide custom colors
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
  };
  useEffect(() => {
    setPrimaryDataPt(defaultValue);
    setSecondaryDataPt(defaultComparisonValue)
  }, [mostRecentPullDate, data]);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Chart options={options} series={options.series} type={type} />
    </div>
  );
};

export default ChartDisplay;
