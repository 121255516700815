export const setCurrentTab = (tab: any) => ({
    type: "SET_CURRENT_TAB",
    payload: tab
});

export const setAccountNavigationVisiblity = (isVisible: boolean) => ({
    type: 'SET_ACCOUNT_NAVIGATION',
    payload: isVisible
});

export const setNavMenuSize = (size: number) => ({
    type: 'SET_NAV_MENU_SIZE',
    payload: size
});