import { Box, Grid, ListItemButton, Typography } from "@mui/material";
import logoFull from "assets/vendanza/brand/logo-full-light.svg";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { styled } from "@mui/system";

const Footer = () => {
  const HoverListItemButton = styled(ListItemButton)(({ theme }) => ({
    "& .MuiTypography-root": {
      transition: "color 0.3s ease-in-out", // Add transition for smooth color change
    },
    "&:hover .MuiTypography-root": {
      color: theme.palette.secondary.contrastText, // Change to the desired hover color
    },
  }));

  const history = useHistory();
  const sections = [
    {
      title: "Features",
      links: [
        {
          title: "1-Click Integrations",
          link: "/integrations",
        },
        {
          title: "Analytics",
          link: "/features",
        },
        {
          title: "Automation",
          link: "/automate",
        },
        {
          title: "Alerts",
          link: "/features",
        },
      ],
    },
    {
      title: "Company",
      links: [
        {
          title: "About Us",
          link: "/company",
        },
        {
          title: "Privacy",
          link: "/privacy",
        },
      ],
    },
    {
      title: "Links",
      links: [
        {
          title: "Blog",
          link: "/blog",
        },
        {
          title: "Pricing",
          link: "/pricing",
        },
      ],
    },
    {
      title: "Support",
      links: [
        {
          title: "Slack",
          link: "/community",
        },
        {
          title: "FAQs",
          link: "/faq",
        },
      ],
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "black",
        pt: { xs: 5, sm: 10 },
        pb: { xs: 5, sm: 10 },
      }}
    >
      <Grid
        container
        display="flex"
        justifyContent={{ xs: "flex-start", sm: "center" }}
      >
        <Grid item sm={12} md={3} mb={2}>
          <Box
            onClick={() => history.push("/hello")}
            component="img"
            src={logoFull}
            sx={{ height: { xs: "50px", sm: "75px" }, cursor: "pointer" }}
          />
        </Grid>
        {sections.map((section) => {
          return (
            <Grid
              xs={12}
              sm={2}
              item
              key={section.title}
              mb={2}
              ml={{ xs: 2, sm: 0 }}
            >
              <Typography fontWeight={600} color="white">
                {section.title}
              </Typography>
              {section.links.map((link) => {
                return (
                  <HoverListItemButton
                    onClick={() => history.push(link.link)}
                    disablePadding
                    disableGutters
                  >
                    <Typography variant="text" color="white">
                      {link.title}
                    </Typography>
                  </HoverListItemButton>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Footer;
