import parseQueryParams from "Utils/parseQueryParams";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import sessionStorage from "Utils/sessionStorage";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import handleOAuthRedirect from "./utils/handleOAuthRedirect";
import redirectLandingUtils from "./utils/redirectLandingUtils";
import { useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageActions.ts";

const RedirectLanding = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const oAuthRedirect = async (params) => {
    const selectedEntity = await sessionStorage.getSelectedEntity();
    const currentUser = await sessionStorage.getAuthStorage();
    try {
      await handleOAuthRedirect({ params, dispatch, setMessage });
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      if (selectedEntity && selectedEntity !== -1) {
        history.push(
          `entity/analytics/integrations?entityId=${selectedEntity?.id}`
        );
      } else if (currentUser) {
        history.push(`dashboard`);
      } else {
        history.push(`/login`);
      }
    }
  };

  const handleExternalLogin = async (params) => {
    const origin = params.origin;
    let clientId;
    let redirectUri;
    let loginUrl;
    switch (origin) {
      case "SHOPIFY":
        clientId = process.env.REACT_APP_SHOPIFY_CLIENT_ID;
        redirectUri = `${process.env.REACT_APP_BASE_URL}/redirect?integrationId=SHOPIFY&func=oAuthRedirect`;
        const scopes = "read_all_orders, read_orders, write_orders";
        const shop = params.shop;
        loginUrl = `https://${shop}/admin/oauth/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${encodeURIComponent(
          redirectUri
        )}`;
        window.location.href = loginUrl;
        break;
      case "AMAZON":
        redirectUri = `${process.env.REACT_APP_BASE_URL}/redirect?integrationId=AMAZON&func=oAuthRedirect`;
        loginUrl = `${params.amazon_callback_uri}?amazon_state=${
          params.amazon_state
        }&state=AMAZON_PENDING_LOGIN&redirect_uri=${encodeURIComponent(
          redirectUri
        )}&version=beta`;
        window.location.href = loginUrl;
        break;
      default:
        setError({ status: 500, detail: "An unknown error occurred" });
    }
  };

  const checkRedirectParams = async () => {
    const params = parseQueryParams(window.location.href);
    const func = params.func;
    switch (func) {
      case "oAuthRedirect":
        await oAuthRedirect(params);
        break;
      case "login":
        await handleExternalLogin(params);
        break;
      case "oAuthRefresh":
        await redirectLandingUtils.handleOAuthRefresh({
          setError,
          params,
          history,
        });
      case "shopify_sub":
        await redirectLandingUtils.handleShopifySubscription({
          setError,
          params,
          history,
        });
        break;
      default:
        setError({ status: 500, detail: "An unknown error occurred" });
    }
  };
  useEffect(() => {
    checkRedirectParams();
  }, []);

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "85vh",
      }}
    >
      {error?.status && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h5" fontWeight={700}>
            Looks like you hit a '{error?.status}', that's an error
          </Typography>
          <Typography variant="subtitle2">
            {error.detail ?? error.message}
          </Typography>
          <Button
            onClick={() =>
              redirectLandingUtils.redirectToIntegration({ history })
            }
          >
            Go back
          </Button>
        </Box>
      )}
      {!error && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
        >
          <CircularProgress size={30} />
          <Typography>Redirecting...</Typography>
        </Box>
      )}
    </Container>
  );
};
export default RedirectLanding;
