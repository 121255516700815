import {
  DeleteOutlineRounded,
  EditOutlined,
  MoreHoriz,
} from "@mui/icons-material";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DeleteChatDialog from "./components/DeleteChatDialog";
import chatService from "services/chatService.ts";
import { setMessage } from "store/actions/messageActions.ts";
import { useDispatch } from "react-redux";
import { getChatHistoryByEntityId, setCurrentChat, setMessages } from "store/actions/chatActions.ts";
import { useSelector } from "react-redux";

const ChatHistoryItemMenu = ({ details, onEdit }) => {
  const dispatch = useDispatch();
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      // Delete the chat
      const result = await chatService.deleteChatById(details.conversationId);
      if (result.status === 200) {
        // Close the dialog
        setShowDelete(false);
        // Reload the chat history
        dispatch(setMessages([]));
        dispatch(setCurrentChat({}));
        dispatch(getChatHistoryByEntityId(entityId));
        setAnchorEl(null);
        return;
      }
      throw new Error("Failed to delete chat");
    } catch (error) {
      dispatch(setMessage(`Failed to delete chat: ${error.message}`));
    }
  };
  return (
    <>
      <Tooltip arrow placement="top" title="More Options">
        <ListItemIcon onClick={handleClick}>
          <MoreHoriz />
        </ListItemIcon>
      </Tooltip>
      <Menu
        padding={0}
        sx={{ borderRadius: 5 }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClose}
      >
        <MenuItem sx={{ m: 1 }} onClick={onEdit}>
          <ListItemIcon>
            <EditOutlined sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem sx={{ m: 1 }} onClick={() => setShowDelete(true)}>
          <ListItemIcon>
            <DeleteOutlineRounded color="error" sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          <Typography color="error">Delete</Typography>
        </MenuItem>
        <DeleteChatDialog
          details={details}
          open={showDelete}
          setOpen={setShowDelete}
          onDelete={handleDelete}
        />
      </Menu>
    </>
  );
};

export default ChatHistoryItemMenu;
