import integrationService from "Api/integration/integration.ts";
import sessionStorage from "Utils/sessionStorage";
import subscriptionUtils from "Utils/subscriptionUtils";
import facebookService from "services/facebookService.ts";
import paymentsService from "services/paymentsService.ts";
import shopifyService from "services/shopifyService.ts";

const handleOAuthRedirect = async ({ params, setMessage, dispatch }) => {
  const selectedEntity = await sessionStorage.getSelectedEntity();
  const integration = params.integrationId;
  let code;
  let result;

  try {
    switch (integration) {
      case "SHOPIFY":
        code = params.code;
        const shopUri = params.shop;
        result = await shopifyService.getOAuthToken(
          selectedEntity?.id,
          shopUri,
          code
        );
        if (
          result?.data?.success &&
          selectedEntity?.subscriptionStatus !== "free"
        ) {
          const { provider } = await subscriptionUtils.determineProvider(
            selectedEntity?.id
          );
          // cancel current subscription and prompt to set up through shopify
          if (provider === "stripe") {
            dispatch(setMessage("Cancelling current subscription..."));
            try {
              await paymentsService.cancelPlan(selectedEntity?.id);
              dispatch(
                setMessage("Current subscription successfully cancelled.")
              );
            } catch (err) {
              dispatch(
                setMessage(
                  "There was a problem automatically cancelling the current subscription. You may need to cancel it manually before setting up a new subscription through Shopify."
                )
              );
            }
          }
        }
        break;
      case "FACEBOOK":
        code = params.code;
        const tokenId = sessionStorage.getOAuthRefreshData()?.tokenId ?? null;
        if (tokenId) {
          sessionStorage.clearOAuthRefreshData();
        }
        result = await facebookService.getOauthToken(selectedEntity.id, code, tokenId);
        break;
      case "GA4":
        code = params.code;
        result = await integrationService.getOAuthToken({
          entityId: selectedEntity.id,
          platform: "GA4",
          code,
        });
        break;
      case "AMAZON":
        code = params.spapi_oauth_code;
        result = await integrationService.getOAuthToken({
          entityId: selectedEntity.id,
          platform: "amazon",
          extraPayload: {
            sellingPartnerId: params.selling_partner_id,
            redirectUri: `${process.env.REACT_APP_BASE_URL}/redirect?integrationId=AMAZON&func=oAuthRedirect`,
          },
          code,
        });

        break;
      default:
        console.error("error, could not find integration");
        break;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default handleOAuthRedirect;
