import axios from "axios";
import { getAuthToken } from "Utils/data/authToken";
import dataConstants from "Utils/dataConstants";
import shopify from "./shopify/shopify.ts";

const getDataAccounts = async (entityId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/integration/sources?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const handleOAuthRefresh = async (params: any) => {
  try {
    const { code, tokenId, platform } = params;
    if (!code || !platform || !tokenId) {
      throw new Error(
        "refresh oAuth token requires a minimum of an tokenId, code, and platform"
      );
    }
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/${platform}/oauth/${tokenId}`;
    const body = {
      code,
    };
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const getOAuthToken = async ({ entityId, code, platform, extraPayload }) => {
  try {
    if (!entityId || !code) {
      throw new Error(
        "getOAuth token requires a minimum of an entityId, code, and platform"
      );
    }
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/${platform}/oauth`;
    const body = {
      code,
      entityId,
      extraPayload,
    };
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const checkAuthStatus = async (entityId: string, integrationId: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/integration/auth-status?entityId=${entityId}&integrationId=${integrationId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const sourceData = async (
  entityId: string,
  sources: any,
  startDate: string,
  endDate: string
) => {
  let data = {
    genericData: [],
  };

  for (const source of sources) {
    const integrationId = source.integrationId;

    switch (integrationId) {
      case dataConstants.integrationIds.shopify:
        try {
          const result = await shopify.getShopData(
            entityId,
            source,
            startDate,
            endDate
          );
          data.genericData = [...data.genericData, ...result.data.genericData];
          data["shopifyOrders"] = result.data.data;
        } catch (error) {
          console.error("Error fetching Shopify data:", error);
        }
        break;

      // Add cases for other integrations here, if needed

      default:
        console.warn(`Integration with ID '${integrationId}' is not handled.`);
        break;
    }
  }

  return data;
};

export default {
  getDataAccounts,
  getOAuthToken,
  checkAuthStatus,
  sourceData,
  handleOAuthRefresh,
};
