const initialState = {
  baseMetrics: {},
  customMetrics: {},
  mostRecentPullDate: null,
  primaryStartDate: new Date(),
  primaryEndDate: new Date(),
  secondaryStartDate: new Date(),
  secondaryEndDate: new Date(),
  showComparison: true,
  metricGoals: [],
};

const metricsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_BASE_METRICS":
      return {
        ...state,
        baseMetrics: action.payload,
      };
    case "SET_PRIMARY_DATES":
      return {
        ...state,
        primaryStartDate: action.payload.startDate,
        primaryEndDate: action.payload.endDate,
      };
    case "SET_SECONDARY_DATES":
      return {
        ...state,
        secondaryStartDate: action.payload.startDate,
        secondaryEndDate: action.payload.endDate,
      };
    case "SET_SHOW_COMPARISON":
      return {
        ...state,
        showComparison: action.payload,
      };
    case "SET_MOST_RECENT_PULL_DATE":
      return {
        ...state,
        mostRecentPullDate: action.payload
      }
    case "SET_METRIC_GOALS":
      return {
        ...state,
        metricGoals: action.payload
      }
    default:
      return state;
  }
};

export default metricsReducer;
