import { AutoGraph, EmojiEventsRounded, MoreHoriz } from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import boardService from "services/boardService.ts";
import { setMessage } from "store/actions/messageActions.ts";

const MetricCardMenu = ({
  boardId,
  metric,
  chartType,
  setChartType,
  chartView,
  setChartView,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateChartType = async (type) => {
    try {
      const result = await boardService.setBoardMetricChartType({
        metricId: metric.id,
        boardId,
        chartType: type,
      });
      if (result) {
        setChartType(type);
      }
      // Add your logic to update the chart type in the database or state here.
    } catch (err) {
      dispatch(setMessage("Something went wrong, please try again"));
    }
  };

  const updateChartView = async () => {
    try {
      setChartView(!chartView);
      const result = await boardService.setBoardMetricChartView({
        metricId: metric.id,
        boardId,
        chartView: !chartView,
      });
      if (result) {
        handleClose();
      }
      // Add your logic to update the chart type in the database or state here.
    } catch (err) {
      dispatch(setMessage("Something went wrong, please try again"));
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        padding={0}
        sx={{ borderRadius: 5 }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <ListItem sx={{ m: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="chart-type-label">Chart Type</InputLabel>
            <Select
              size="small"
              label="Chart Type"
              labelId="chart-type-label"
              value={chartType}
              onChange={(e) => updateChartType(e.target.value)}
            >
              <MenuItem value="area">Area</MenuItem>
              <MenuItem value="bar">Bar</MenuItem>
              <MenuItem value="line">Line</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <MenuItem sx={{ m: 1 }} onClick={updateChartView}>
          <ListItemIcon>
            {chartView ? (
              <EmojiEventsRounded sx={{ fontSize: "18px" }} />
            ) : (
              <AutoGraph sx={{ fontSize: "18px" }} />
            )}
          </ListItemIcon>
          {chartView ? "View Goal" : "View Graph"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MetricCardMenu;
