import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Grid,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import sessionStorage from "Utils/sessionStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSelectedEntity } from "store/actions/entityActions.ts";
import BusinessMenu from "./components/BusinessMenu/BusinessMenu";
import { setAccountNavigationVisiblity } from "store/actions/navigationActions.ts";
import FinancialStatus from "./components/FinancialStatus";
import { capitalize } from "Utils/capitalizeString";

const BusinessCard = ({ details }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleEntitySelect = () => {
    sessionStorage.setSelectedEntity(details);
    dispatch(setAccountNavigationVisiblity(false));
    setTimeout(() => {
      dispatch(setSelectedEntity(details));
      history.push(`/entity/analytics/dashboard?entityId=${details.id}`);
    }, 100);
  };

  const getSubscriptionStatus = () => {
    return (
      <Chip
        size="small"
        label={
          <Typography variant="subtitle2">
            {capitalize(details?.subscriptionStatus)}
          </Typography>
        }
      />
    );
  };
  useEffect(() => {}, []);
  return (
    <Card
      sx={{
        p: 2,
        mb: 6,
        m: 3,
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignIitems="center">
            <Typography variant="h5" mr={1}>
              {details.name}
            </Typography>
            {getSubscriptionStatus()}
          </Box>
          <BusinessMenu details={details} />
        </Box>
        <Grid container display="flex" justifyContent="space-between"></Grid>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button fullWidth onClick={handleEntitySelect}>
          Open Project
        </Button>
      </CardActions>
    </Card>
  );
};

export default BusinessCard;
