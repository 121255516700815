import { Box, Button, Card, CardContent, Container, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const MissionStatement = ({showMore}) => {
  const history = useHistory();
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Card
        sx={{ mt: 6, width: { xs: "100%", md: "50%", padding: 20 } }}
      >
        <CardContent>
          <Typography
            textAlign="center"
            sx={{
              fontSize: "48px",
              fontWeight: 600,
              mt: 0,
              pt: 0,
              lineHeight: 0.9,
            }}
          >
            Our Mission.
          </Typography>
          <Typography
            textAlign="center"
            sx={{
              fontSize: "20px",
              mt: 3,
              lineHeight: 1.1,
            }}
            fontWeight={600}
          >
            To create and leverage new technologies that help entrepreneurs build businesses
            that can eventually run themselves without human intervention.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          {showMore && <Button sx={{pl:3, pr:3}} onClick={() => history.push('/company')}>
            <Typography
              textAlign="center"
              sx={{
                fontSize: "18px",
                lineHeight: 1.1,
              }}
              fontWeight={600}
            >
                Learn More
                
            </Typography>
          </Button>}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default MissionStatement;
