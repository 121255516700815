import { Add, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import boardService from "Api/boards/boardService.ts";
import ButtonLoader from "Components/ButtonLoader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBoards } from "store/actions/boardActions.ts";
import Metric from "./Metric";
import { bool } from "prop-types";
const AddMetricBtn = ({ details, currentMetrics, onUpdate, button }) => {
  const dispatch = useDispatch();
  const baseMetrics = useSelector(
    (state) => state.metrics.baseMetrics.primaryMetrics
  );
  const customMetrics = useSelector((state) => state.metrics.customMetrics);
  const entityId = useSelector((state) => state?.entity?.selectedEntity?.id);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusMsg, setStatusMsg] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(null);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleSelectMetric = (metric) => {
    // Check if the metric is already in the selectedMetrics array
    const foundMetric = selectedMetrics.some(
      (selectedMetric) => selectedMetric.name?.en === metric?.name?.en
    );
    if (
      selectedMetrics.some(
        (selectedMetric) => selectedMetric.name?.en === metric?.name?.en
      )
    ) {
      // If it's already selected, remove it
      const updatedMetrics = selectedMetrics.filter(
        (m) => m?.name?.en !== metric?.name?.en
      );
      setSelectedMetrics(updatedMetrics);
    } else {
      // If it's not selected, add it
      setSelectedMetrics([...selectedMetrics, metric]);
    }
  };

  const populateCheckedMetric = (metric) => {
    // Check if the metric's name is in the selectedMetrics array
    return selectedMetrics.some(
      (selectedMetric) => selectedMetric.name?.en === metric?.name?.en
    );
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackbar}
    >
      <Close fontSize="small" />
    </IconButton>
  );
  const addMetric = async () => {
    try {
      setLoading(true);
      const metricNames = selectedMetrics.map((metric) => metric.name?.en)
      await boardService.updateBoardMetrics(details.boardId, metricNames);
      setStatusMsg("Metrics updated");
      setOpenSnackbar(true);
    } catch (error) {
      setStatusMsg(error);
      setOpenSnackbar(true);
    } finally {
      dispatch(getBoards(entityId));
      setLoading(false);
      setShow(false);
      onUpdate();
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setSelectedMetrics(currentMetrics);
  }, [currentMetrics]);
  return (
    <>
    {button ? <Button startIcon={<Add/>} onClick={() => setShow(true)} variant="outlined">
      Add Metric
    </Button> : 
      <MenuItem onClick={() => setShow(true)}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        Add Metric
      </MenuItem>
      }
      <Dialog fullWidth open={show}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography>Add Metric</Typography>
            <Typography variant="subtitle2">
              Select which metric(s) to include
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {baseMetrics?.map((metric) => {
            return (
              <Metric
              key={metric}
                onClick={handleSelectMetric}
                checked={populateCheckedMetric(metric)}
                details={metric}
              />
            );
          })}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addMetric}>
            <ButtonLoader size={20} text="Update" loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={statusMsg}
        action={action}
      />
    </>
  );
};

AddMetricBtn.propTypes = {
  button: bool
}

AddMetricBtn.defaultProps = {
  button: false
}

export default AddMetricBtn;
