import { Chip } from "@mui/material";

const StatusIndicator = ({ accounts }) => {
  if (!accounts.length) {
    return <></>;
  }
  return (
    <Chip
    color="success"
      size="small"
      label={
        `${accounts.length} Connected`
      }
    ></Chip>
  );
};

export default StatusIndicator;
