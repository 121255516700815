import axios from "axios";

const registerAccount = async (
  email: string,
  password: string,
  companyName: string
) => {
  try {
    const uri = `${process.env.REACT_APP_VENDANZA_API}/auth/register`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
      },
    };

    const body = {
      email,
      password,
      companyName,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const requestEarlyAccess = async (
  firstName: string,
  lastName: string,
  email: string,
  reason: string
) => {
  try {
    const uri = `${process.env.REACT_APP_VENDANZA_API}/auth/early-access`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      email,
      firstName,
      lastName,
      reason,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const verifyEmail = async (email: string) => {
  try {
    const uri = `${process.env.REACT_APP_VENDANZA_API}/auth/verify`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
      },
    };

    const body = {
      email,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  registerAccount,
  requestEarlyAccess,
  verifyEmail,
};
