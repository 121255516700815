import { Delete } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItem,
  Switch,
  Typography,
} from "@mui/material";
import DeleteAccount from "Components/Integrations/DeleteAccount/DeleteAccount";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import thirdPartyAccountService from "services/thirdPartyAccountService.ts";

const GA4Property = ({ property, tokenId, storedAccounts, onUpdate }) => {
  const entityId = useSelector((state) => state.entity?.selectedEntity?.id);

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [warnDelete, setWarnDelete] = useState(false);
  const [checked, setChecked] = useState(false);
  const storeProperty = async () => {
    setLoading(true);
    await thirdPartyAccountService.addThirdPartyAccount({
      tokenId,
      entityId,
      platform: "GA4",
      accountId: property.id,
    });
    try {
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
      onUpdate();
    }
  };
  const deleteProperty = async () => {
    try {
      setDeleteLoading(true);
      setLoading(true);
      await thirdPartyAccountService.deleteAccountAndData({
        accountId: property.id,
        entityId,
        platform: "GA4",
        tokenId,
      });
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
      setDeleteLoading(false);
      onUpdate();
    }
  };
  const handlePropertyChange = async (e) => {
    try {
      if (e.target.checked) {
        await storeProperty();
      } else {
        await deleteProperty();
      }
    } catch (err) {
      setMessage(err.message);
      setShowMessage(true);
    } finally {
    }
  };

  const checkStoredAccts = () => {
    if (storedAccounts.find((acct) => acct.accountId === property.id)) {
      return true;
    }
    return false;
  };

  const updateAccountActive = async (e) => {
    setLoading(true);
    const active = e.target.checked;
    try {
      const tokenId = storedAccounts.find(
        (storedAcct) => storedAcct.id === property.id
      );
      const result = await thirdPartyAccountService.updateAccountActive({
        accountId: property.id,
        tokenId,
        entityId,
        platform: "GA4",
        active,
      });
      if (result.data.success) {
        setActive(
          result.data?.updates?.activeStatusUpdate?.data?.dataValues?.active
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

    const checkActive = (accts, accountToCheck) => {
    const acct = accts?.find((item) => item.accountId === accountToCheck.id);
    return acct?.active;
  };

  useEffect(() => {
    setChecked(checkStoredAccts());
    setActive(checkActive(storedAccounts, property))
  }, [storedAccounts]);
  return (
    <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box  display="flex" alignItems="center">
        <Checkbox
          checked={checked}
          disabled={loading || checked}
          onChange={(e) => handlePropertyChange(e)}
        />
        <Box>
          <Typography variant="subtitle1">{property.name}</Typography>
          <Typography variant="subtitle2" >
            {property.id}
          </Typography>
        </Box>
      </Box>
      {checked && (
        <>
          <Box display="flex" alignItems="center">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch disabled={loading} size="small" checked={active} />
                }
                label={active ? "Connected" : "Disabled"}
                onChange={updateAccountActive}
              />
            </FormGroup>
            <IconButton size="small" onClick={() => setWarnDelete(true)}>
              <Delete fontSize="12px" />
            </IconButton>
          </Box>

          <DeleteAccount
            setWarnDelete={setWarnDelete}
            warnDelete={warnDelete}
            loading={deleteLoading}
            onDelete={deleteProperty}
          />
        </>
      )}
    </ListItem>
  );
};

export default GA4Property;
