import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const checkOAuthTokenStatus = async (entityId: string, platform: string) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/oauth?entityId=${entityId}&platform=${platform}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    // Make the API call and await the response
    const response = await axios.get(uri, config);

    // Check if the response is successful
    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const updateIntegrationName = async (name: string, tokenId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/oauth/update`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",

        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      tokenId,
      name,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const storeThirdPartyAccount = async ({
  thirdPartyAccountId,
  entityId,
  tokenId,
  platform,
  additionalPayload,
}) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/oauth/update`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",

        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      thirdPartyAccountId,
      entityId,
      tokenId,
      platform,
      additionalPayload,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const updateThirdPartyAccount = async () => {};
const deleteThirdPartyAccount = async () => {};

const deleteMultipleAccountsAndData = async (tokenId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/oauth?tokenId=${tokenId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",

        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.delete(uri, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};
export default {
  checkOAuthTokenStatus,
  updateIntegrationName,
  deleteThirdPartyAccount,
  storeThirdPartyAccount,
  updateThirdPartyAccount,
  deleteMultipleAccountsAndData,
};
