import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getThirdPartyAccountsByEntityId = async (entityId: string) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.get(uri, config);

    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const addThirdPartyAccount = async ({
  accountId,
  tokenId,
  entityId,
  platform,
  additionalPayload,
}) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      thirdPartyAccountId: accountId,
      tokenId,
      entityId,
      platform,
      additionalPayload,
    };

    const response = await axios.post(uri, body, config);

    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const markThirdPartyAccountForPurge = async ({
  accountId,
  tokenId,
  entityId,
  platform,
}) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/mark`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      thirdPartyAccountId: accountId,
      tokenId,
      entityId,
      platform,
    };

    const response = await axios.post(uri, body, config);

    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const deleteAccountAndData = async ({
  accountId,
  entityId,
  platform,
  tokenId,
  isAPIKeyOnly,
}) => {
  try {
    const authToken = getAuthToken();
    let uri = "";
    if (isAPIKeyOnly) {
      // uri doesn't require platformzac as a param
      uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/delete?accountId=${accountId}&entityId=${entityId}&platform=${platform}`;
    } else {
      uri = `${process.env.REACT_APP_VENDANZA_API}/${platform}/account?accountId=${accountId}&entityId=${entityId}&tokenId=${tokenId}`;
    }
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.delete(uri, config);

    if (response.status === 200) {
      return response; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const checkPurgeQueueByIds = async ({
  accountId,
  tokenId,
  entityId,
  platform,
}) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/check-queue`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      thirdPartyAccountId: accountId,
      tokenId,
      entityId,
      platform,
    };

    const response = await axios.post(uri, body, config);

    if (response.status === 200) {
      return response.data; // Return the data
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const updateAccountActive = async ({
  accountId,
  tokenId,
  entityId,
  platform,
  active,
}) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/active-status`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const body = {
      accountId,
      tokenId,
      entityId,
      platform,
      active,
    };

    const response = await axios.post(uri, body, config);

    return response; // Return the data
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const getStoredAccountsByEntityIdPlatform = async (
  entityId: number,
  platform: string
) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/platform?entityId=${entityId}&platform=${platform}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

const setCustomField = async ({
  accountId,
  tokenId,
  entityId,
  platform,
  customField,
}) => {
  try {

    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/custom`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      accountId,
      tokenId,
      entityId,
      platform,
      customField,
    };

    const response = await axios.post(uri, body, config);

    return response; // Return the data
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const triggerHistoricalSync = async ({
  accountId,
  tokenId,
  entityId,
  platform,
}) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/sync`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const body = {
      accountId,
      tokenId,
      entityId,
      platform,
    };

    const response = await axios.post(uri, body, config);

    return response; // Return the data
  } catch (err) {
    throw err;
  }
};

const removeNoteFromThirdPartyAccount = async ({
  accountId,
  tokenId,
  entityId,
  platform,
  customField,
}) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/custom`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const custom = JSON.parse(customField);
    const body = {
      accountId,
      tokenId,
      entityId,
      platform,
      customField: JSON.stringify({ ...custom, note: null }),
    };

    const response = await axios.post(uri, body, config);

    return response; // Return the data
  } catch (err) {
    console.error(err.name);
    throw err;
  }
};

const checkForStoredCredentials = async ({
  entityId,
  platform}) => {
  try {
    const authToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/accounts/credentials?entityId=${entityId}&platform=${platform}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios.get(uri, config);

    return response.data; // Return the data
  } catch (err) {
    console.error(err.name);
    throw err;
  }
}

export default {
  getThirdPartyAccountsByEntityId,
  getStoredAccountsByEntityIdPlatform,
  addThirdPartyAccount,
  markThirdPartyAccountForPurge,
  deleteAccountAndData,
  checkPurgeQueueByIds,
  updateAccountActive,
  setCustomField,
  triggerHistoricalSync,
  removeNoteFromThirdPartyAccount,
  checkForStoredCredentials,
};
