import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getBaseMetrics = async (entityId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/metrics?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateOrCreateMetricGoal = async ({
  metricSlug,
  title,
  description,
  direction,
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
  threshold,
  type,
  entityId,
}) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/metrics/goal`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      metricSlug,
      title,
      description,
      direction,
      startDate,
      endDate,
      compareStartDate,
      compareEndDate,
      threshold,
      type,
      entityId,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const getMetricGoalBySlug = async (metricSlug: string) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/metrics/goal?metricSlug=${metricSlug}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getMetricGoalsByEntityId = async (entityId: number) => {
  try {
    const accessToken = getAuthToken();

    const uri = `${process.env.REACT_APP_VENDANZA_API}/metrics/goals?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getMetricGoalProgressByEntityIdMetricSlug = async ({
  entityId,
  metricSlug,
}) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/metrics/${metricSlug}/goal?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result;
  } catch (er) {
    console.error(er);
    throw er;
  }
};

const deleteMetricGoal = async ({metricSlug, entityId}: any) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/metrics/${metricSlug}/goal?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.delete(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export default {
  getBaseMetrics,
  updateOrCreateMetricGoal,
  getMetricGoalBySlug,
  getMetricGoalsByEntityId,
  getMetricGoalProgressByEntityIdMetricSlug,
  deleteMetricGoal,
  
};
