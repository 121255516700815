import { Box, Link } from "@mui/material";
import SettingsMenu from "Components/Header/components/SettingsMenu/SettingsMenu";
import React from "react";

const NavFooter = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ pr: 1 }}
    >
      <Link
        sx={{ textDecoration: "none", mr:2 }}
        color="secondary"
        href="/disclaimer"
      >
        disclaimers
      </Link>
      <Link sx={{ textDecoration: "none" }} color="secondary" href="/privacy">
        privacy
      </Link>
    </Box>
  );
};

export default NavFooter;
