import { Close, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ButtonLoader from "Components/ButtonLoader";
import sessionStorage from "Utils/sessionStorage";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import entityService from "services/entityService.ts";
import paymentsService from "services/paymentsService.ts";
import { fetchEntities } from "store/actions/entityActions.ts";
import { setMessage } from "store/actions/messageActions.ts";

const DeleteBusiness = ({ details, btn }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState("");
  const deleteEntity = async () => {
    try {
      setLoading(true);
      if (details.subscriptionStatus !== "free") {
        dispatch(setMessage("Cancelling subscription..."));
        await paymentsService.cancelPlan(details.id);
        setTimeout(() => {
          dispatch(setMessage("Subscription successfully cancelled."));
        }, 2000);
      }
      setTimeout( async () => {
        dispatch(setMessage("Deleting project..."));
        const result = await entityService.deleteEntity(details.id);
      }, 2000);
      setTimeout( () => {
        dispatch(setMessage("Project successfully deleted."));
        setShow(false);
      }, 2000);
    } catch (err) {
      setLoading(false);
      dispatch(setMessage(err.message));
    } finally {
      const sessionUser = sessionStorage.getUserSessionStorage();
      dispatch(fetchEntities(sessionUser.id)); // Replace with your actual fetchEntities action
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      {btn && (
        <Button onClick={() => setShow(true)} variant="outlined">
          Delete Project
        </Button>
      )}
      {!btn && (
        <MenuItem onClick={() => setShow(true)} variant="outlined">
          <Typography>Delete Project</Typography>
        </MenuItem>
      )}
      <Dialog fullWidth open={show}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h5">Are you sure?</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ListItem>
            If this is a paid project, the subscription will be cancelled.
          </ListItem>
          <ListItem>
            Any custom metrics for this project will be deleted.
          </ListItem>
          <ListItem>
            Project integrations will be disconnected and project data will be
            deleted. No archived project will be available.
          </ListItem>
          <ListItem>This action cannot be undone.</ListItem>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <TextField
            onChange={(e) => setConfirm(e.target.value)}
            fullWidth
            size="small"
            placeholder="type 'confirm' to confirm deletion"
            sx={{ mr: 2 }}
          />
          <Button
            disabled={loading || confirm !== "confirm"}
            variant="outlined"
            onClick={deleteEntity}
          >
            <ButtonLoader size={20} text="Delete" loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteBusiness;
