import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const setBoardMetricChartType = async ({
  metricId,
  boardId,
  chartType,
}: any) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board/chart-type?metricId=${metricId}&boardId=${boardId}&chartType=${chartType}`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.post(uri, {}, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const setBoardMetricChartView = async ({
  metricId,
  boardId,
  chartView,
}: any) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board/chart-view?metricId=${metricId}&boardId=${boardId}&chartView=${chartView}`;
    let config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.post(uri, {}, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const createBoard = async (
  {entityId,
  name,
  description,
  view,
  boardType} : any
) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    console.log(name, description, boardType, view);

    const body = {
      entityId,
      name,
      description,
      boardType,
      view
    };
    console.log(body);
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getBoardsForEntity = async (entityId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board?entityId=${entityId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteBoard = async (boardId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board?boardId=${boardId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.delete(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateBoardMetrics = async (boardId: number, metrics: any[]) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board/metrics`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      boardId,
      metrics,
    };

    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getMetricsByBoardId = async (boardId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board/metrics?boardId=${boardId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateBoard = async (
  boardId: number,
  name: string,
  description: string
) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/board?boardId=${boardId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      name,
      description,
    };
    const result = await axios.patch(uri, body, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  setBoardMetricChartType,
  createBoard,
  setBoardMetricChartView,
  getBoardsForEntity,
  deleteBoard,
  updateBoardMetrics,
  getMetricsByBoardId,
  updateBoard,
};
