import { getAuthToken } from "Utils/data/authToken";
import axios from "axios";

const getUserByUserId = async (userId: number) => {
  try {
    const accessToken = getAuthToken();
    const uri = `${process.env.REACT_APP_VENDANZA_API}/user/${userId}`;
    const config = {
      headers: {
        role: "agent",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.get(uri, config);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export default {
  getUserByUserId,
};
