const initialState = {
  loadingHistory: false,
  loadingSendMessage: false,
  chatsHistory: [],
  messages: [],
  currentChat: {},
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MESSAGES":
      return {
        ...state,
        messages: action.payload,
      };
    case "SET_CURRENT_CHAT":
      return {
        ...state,
        currentChat: action.payload,
      };
    case "SET_CHAT_HISTORY":
      return {
        ...state,
        chatsHistory: action.payload,
      };
    case "SET_LOADING_HISTORY":
      return {
        ...state,
        loadingHistory: action.payload,
      };
    case "PUSH_TO_MESSAGES":
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case "SET_LOADING_SEND_MESSAGE":
      return {
        ...state,
        loadingSendMessage: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
